import { axiosInstance } from "../../utils/axiosInstance";

import { IFolder } from '../../interfaces/documents/IFolders';

const getRootFoldersService = () =>{
    return axiosInstance.get<IFolder[]>(`${process.env.REACT_APP_URL_CONTENT}folders/0/children`)
}
const getChildrenFolderService = (idFolder:number) =>{
    return axiosInstance.get<IFolder[]>(`${process.env.REACT_APP_URL_CONTENT}folders/${idFolder}/children`)
}
const addFolderService = (folder:IFolder) =>{
    return axiosInstance.post<IFolder>(`${process.env.REACT_APP_URL_CONTENT}folders`,folder)
}


export {
    getRootFoldersService,
    getChildrenFolderService,
    addFolderService
}