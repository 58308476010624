import moment from "moment";

export const getDayLimits = (required_date: string | undefined) => {

    const todaydate = moment(new Date());
    const dateRequired = moment(`${required_date}`);
    const dayLimits = dateRequired.diff(todaydate, 'days');

    return dayLimits
    
}