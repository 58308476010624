export enum ActionTypeUi {
    START_LOADING = "[UI] Start loading",
    FINISH_LOADING = "[UI] Finish loading",
    START_LOADING_DOCUMENT = "[UI] Start loading document",
    FINISH_LOADING_DOCUMENT = "[UI] Finish loading document",
    START_LOADING_LIST = "[UI] Start loading lists",
    FINISH_LOADING_LIST = "[UI] Finish loading lists",
    START_LOADING_METADATA = "[UI] Start loading metadata",
    FINISH_LOADING_METADATA = "[UI] Finish loading metadata",
    START_LOADING_GENERAL = "[UI] Start loading general",
    FINISH_LOADING_GENERAL = "[UI] Finish loading general",

}