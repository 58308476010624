import { Button, MenuItem } from '@mui/material';
import { cleanDocumentType } from '../../../../../state/actions-creators';
import { Form, Formik, FormikProps } from 'formik';
import { IMetadataField } from '../../../../../interfaces/metadata/IMetadataField';
import { MySelectField } from '../../../../../components/fields/MySelectField';
import { RootState } from '../../../../../state/reducers/index';
import { startLoadingActiveDocumentTypes } from '../../../../../state/actions-creators/search';
import { startUpdateDocumentTypeMetadata, loadFillMetadataFields, cleanMetadatas } from '../../../../../state/actions-creators/document';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useRef } from 'react';
import { useGenerateFields } from '../../../../../hooks/useGenerateFields';
import { GenerateFields } from '../../../../../components/metadata/GenerateFields';
import { IRequestDocumentTypeUpdate } from '../../../../../interfaces/documents/IDocument';

interface FormType{
    type_document:string;
}
const initialFormType ={
    type_document:''
}
interface Props{
    uuidDocument:string | undefined;
}
export const FormSelectDocumentType = ({uuidDocument}:Props) => {
    const dispatch = useDispatch()
    const {administration:{typesDocuments:{list}},
            document:{currentDocument:{fillMetadataFields}}} = useSelector((state:RootState) => state)
    useEffect(() => {
        dispatch(startLoadingActiveDocumentTypes())
        return () =>{
            dispatch(cleanDocumentType())
        }
    }, [dispatch])
    const {initialValues} = useGenerateFields({
        metadataFields:fillMetadataFields
    })
    const refFormType = useRef<FormikProps<FormType>>(null)
    return (
            <>
            
                <Formik
                    innerRef={refFormType}
                    initialValues={{
                        ...initialFormType
                    }}
                    onSubmit={() =>{}}
                >
                    {
                        (formik) => (
                            <Form
                                className='my-documents__details-documents-select-type-container'
                            >
                                <MySelectField
                                    label="Tipo de documento"
                                    name="type_document"
                                    handleOnChange={(e)=>{

                                        if(list.length !==0 && e.target.value !== ''){
                                            dispatch(cleanMetadatas())
                                            const documentType = list.find(item => item.name === e.target.value)

                                            dispatch(loadFillMetadataFields(documentType?.metadata as IMetadataField[]))
                                        
                                        }else{
                                            
                                            dispatch(cleanMetadatas())
                                        }
                                    }}
                                    >
                                    
                                    <MenuItem value="">Selccionar</MenuItem>
                                    {
                                        list.map((type) => (
                                            <MenuItem key={type.document_type_id} value={type.name}>{type.display_name}</MenuItem>
                                        ))
                                    }
                                </MySelectField>           
                            </Form>
                        )
                    }
                </Formik>
            {    
            fillMetadataFields.length !== 0 && Boolean(refFormType.current)  && uuidDocument !== undefined  &&
            <Formik
                enableReinitialize
                initialValues={{
                    ...initialFormType,
                    ...initialValues,
                }}
                onSubmit={(values) =>{
                    values.type_document = refFormType.current?.values.type_document  as string
                    const {type_document,...data}  = values
                    const bodyRequest:IRequestDocumentTypeUpdate = {
                        type_document:values.type_document,
                        metadata:data as { [x:string]:any}
                    }
                    dispatch(startUpdateDocumentTypeMetadata(uuidDocument,bodyRequest))
                }}
                >
                    {
                        (formik) => (
                            <Form className='my-documents__details-documents-metadata-container'>
                                <GenerateFields smallForm  metadataFields={fillMetadataFields} />
                                <Button type='submit' variant="contained">Guardar</Button>
                            </Form>
                        )
                    }
            </Formik>
            }
            </>
    )
}
