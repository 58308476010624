import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileSignature } from '@fortawesome/free-solid-svg-icons';

import { ISignature } from '../../../interfaces/documents/ISignature';
import { Tooltip } from '@mui/material';


interface Props{
    signatures:ISignature[]
}
export const TabsSignatureHistory = ({signatures}:Props) => {
    return (
        <div className="commons__history-container">
            <div className="commons__history">

                <Timeline position="alternate">
                    {
                        signatures.map((signature, index) => (
                        <div key={index}>
                        <TimelineItem>
                            <TimelineOppositeContent
                            sx={{ m: 'auto 0' }}
                            align="right"
                            variant="body2"
                            color="text.secondary"
                            >
                                <Tooltip title="Fecha de solicitud" arrow placement="left-start">
                                    <span> {moment(new Date(signature.create_date+"+00:00")).tz("America/Santiago").format("DD/MM/YYYY HH:mm") }</span>   
                                </Tooltip>
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                            <TimelineDot color={signature.signed ? 'success' : 'warning'}  variant="filled">
                                <FontAwesomeIcon icon={faFileSignature} />
                            </TimelineDot>
                                { signatures.length -1 !== index &&   <TimelineConnector />}
                            </TimelineSeparator>
                            <TimelineContent>
                            <div className="commons__history-content">
                                <h3 >
                                    {signature.user_name}
                                </h3>
                                <h3>Firmado: </h3>
                                <h4> {Boolean(signature.sign_date) ? moment(new Date(signature.sign_date+"+00:00")).tz("America/Santiago").format("DD/MM/YYYY HH:mm") :'Sin firmar'}</h4>
                            </div>
                            </TimelineContent>
                        </TimelineItem>
                    
                            </div>
                        ))
                    }
                </Timeline>
            </div>
        </div>
    )
}
