import { FileStatus } from "../constants/fileStatus";

export const formatStatusFile = (status:number | string):string => {
    switch (status) {
        case FileStatus.ERROR:
            return "Error";
        case FileStatus.PENDIENTE:
            return 'Pendiente';
        case FileStatus.BORRADOR:
            return "Borrador"
        case FileStatus.CLASIFICADO:
            return "Clasificado"
        case FileStatus.NO_CLASIFICADO:
            return "No clasificado"
        case FileStatus.PROCESANDO:
            return "Procesando"
        case FileStatus.PROCESADO:
            return "Procesado"
        case FileStatus.INDEXADO:
            return "Indexado"
        case FileStatus.APROBADO:
            return "Aprobado"
        default:
            return ""
    }
}