import { axiosInstance } from "../../utils/axiosInstance";
import { ICompany } from '../../interfaces/maintainers/IComapny';

const getAllComapniesService = () =>{
    return axiosInstance.get(`${process.env.REACT_APP_URL_COMPANY}companies`)
}
const addCompanyService = (data:ICompany) =>{
    return axiosInstance.post(`${process.env.REACT_APP_URL_COMPANY}companies`,data)
}
const updateCompanyService = (idCompany:number,data:ICompany) => {
    return axiosInstance.put(`${process.env.REACT_APP_URL_COMPANY}companies/${idCompany}`,data)
}
const deleteCompanyService = (idCompany:number) => {
    return axiosInstance.delete(`${process.env.REACT_APP_URL_COMPANY}companies/${idCompany}`)
}
export {
    getAllComapniesService,
    addCompanyService,
    updateCompanyService,
    deleteCompanyService
}