
import { routesAdmin } from "../../routers/routes"
import { RoutesView } from "../../routers/RoutesView"
import { SideBar } from "./components/SideBar"
import { useSelector } from 'react-redux';
import { IAuthState } from "../../state/reducers/authReducer";
import { RootState } from '../../state/reducers/index';

export const AdminLayout = () => {

    const {tokenInfo}:IAuthState = useSelector((state:RootState) => state.auth)
    return (
      <>
        { tokenInfo &&  <SideBar user={tokenInfo?.user}  />}
      <div className="wrapper">
        { tokenInfo &&  <RoutesView user={tokenInfo?.user} routes={routesAdmin} />}
       
      </div>
      </>
    )
}
