import  { AxiosResponse } from "axios"
import { IList, IDetailList } from '../../interfaces/maintainers/ILists';
import { axiosInstance } from "../../utils/axiosInstance";

// TODO: Agregar token cuando exista auth
const getAllListsService = () =>{
    return axiosInstance.get(`${process.env.REACT_APP_URL_LISTS}lists`)
}
const getDetailListService = (idList:number) =>{
    return axiosInstance.get(`${process.env.REACT_APP_URL_LISTS}lists/${idList}/details`)
}

const getDetailListByKey = (key:string) =>{
    return axiosInstance.get<IDetailList[]>(`${process.env.REACT_APP_URL_LISTS}lists/company/apiux/${key}/details`)
}
const addDetailListService = (data:IDetailList) =>{
    return axiosInstance.post(`${process.env.REACT_APP_URL_LISTS}details`,data)
}
const updateDetailListService = (data:IDetailList) =>{
    return axiosInstance.put(`${process.env.REACT_APP_URL_LISTS}details/${data.id}`,data)
}
const deleteDetailListService = (data:IDetailList) =>{
    return axiosInstance.delete(`${process.env.REACT_APP_URL_LISTS}details/${data.id}`)
}
const addListService = (data:IList) =>{
    
    return axiosInstance.post(`${process.env.REACT_APP_URL_LISTS}lists/${data.namespace}/${data.primary_key}`,data)
}
const updateListService = (idList:number,data:IList) => {
    return axiosInstance.put<{}, AxiosResponse<IList>>(`${process.env.REACT_APP_URL_LISTS}lists/${data.namespace}/${data.key}`,{primary_key:data.primary_key})
}
const deleteListService = (list:IList) => {
    return axiosInstance.delete(`${process.env.REACT_APP_URL_LISTS}lists/${list.namespace}/${list.primary_key}/${list.key}`)
}
export {
    getAllListsService,
    addListService,
    updateListService,
    deleteListService,
    getDetailListService,
    addDetailListService,
    updateDetailListService,
    deleteDetailListService,
    getDetailListByKey
}