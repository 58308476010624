import {  useField } from 'formik'
import { Autocomplete,TextField } from '@mui/material';

import { useState, useEffect } from 'react';
import { getUsers } from '../../../services/users/userServices';
import { IUser } from '../../../interfaces/auth/IUsers';

interface Props  {
    label: string;
    name: string;
    [x: string]: any;

}
export const MyAutoCompleteUsersField = ({ label, ...props }: Props ) => {
    const [ fields,metaProps,helpers] = useField(props)
    const [myValue, setMyValue] = useState<IUser | null>(null)
    const [users, setUsers] = useState<readonly IUser[]>([]);
    const [open, setOpen] = useState(false);
    const loading = open && users.length === 0;

    const { error,touched} = metaProps;
    const {onChange} = {...fields}
    const { setValue} = helpers
    const {name,...prop} = {...props}
    useEffect(() => {
        let active = true;

        if (!loading) {
        return undefined;
        }
        const getAllUsers = async () =>{
            try {
                const resp = await  getUsers();
                if (active) {
                    setUsers([...resp.data.users])
                }
            } catch (error) {
                console.log(error)
            }
        }
        getAllUsers()
        return () => {
            active = false;
        }
    }, [loading])

    useEffect(() => {
        if (!open) {
            setUsers([]);
        }
        }, [open]);
    
    return (
        
        <Autocomplete
            fullWidth
            options={users}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            loading={loading}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            value={myValue}
        
            {...prop}
            onChange={(e:React.ChangeEvent<any>,newValue:IUser | null ) => {
                setMyValue(newValue)
                if(newValue){
                    onChange(e)
                    setValue(newValue.id)
                }
            }}
            getOptionLabel={(option ) =>  {
                if(typeof option === "string"){
                    return option
                }else{
                    return `${option?.firstName} ${option?.lastName}`
                }
            }}
            
            renderInput={(params) => (
            <TextField
                error={Boolean(error) && touched}
                helperText={Boolean(error) && touched && error}
                {...params}
                name={name}
                variant="outlined"
                label={label}
            />
            )}
        />

    )
}
