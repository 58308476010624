import { ActionsTasks } from '../actions-interfaces/IActionsTasks';
import { ITask } from '../../interfaces/tasks/ITask';
import { ActionTypesTasks } from '../action-types/taskTypes';

export interface ITasksState{
    tasks:ITask[],
    from:number,
    to:number,
    count:number,
    page:number,
    currentTask:{
        task:ITask | null,
        historyTask:ITask[]
    }
}
const initialState:ITasksState={
    tasks:[],
    from:0,
    to:0,
    count:0,
    page:1,
    currentTask:{
        task:null,
        historyTask:[]
    }
}
const tasksReducer = (state:ITasksState=initialState,action:ActionsTasks) => {
    switch (action.type) {
        case ActionTypesTasks.LOAD_TASKS:
            return {
                ...state,
                tasks:[...action.payload.tasks],
                from:action.payload.from,
                to:action.payload.to,
                count:action.payload.count,
                page:action.payload.page
            }
        case ActionTypesTasks.LOAD_HISTORY_TASKS:
            return {
                ...state,
                currentTask:{
                    ...state.currentTask,
                    historyTask:[...action.payload]
                }
            }
        case ActionTypesTasks.ADD_TASK:
            return {
                ...state,
                tasks:[...state.tasks,action.payload]
            }
        case ActionTypesTasks.UPDATE_TASK:
            return {
                ...state,
                tasks:state.tasks.map(task => 
                    task.id === action.payload.id
                    ?
                    action.payload
                    :
                    task
                    )
            }
        case ActionTypesTasks.SET_CURRENT_TASK:
            return {
                ...state,
                currentTask:{
                    ...state.currentTask,
                    task:{...action.payload}
                }   
            }
        case ActionTypesTasks.CLEAN_CURRENT_TASK:
            return {
                ...state,
                tasks:[],
                currentTask:{
                    ...state.currentTask,
                    task:null
                }
            }
        case ActionTypesTasks.DELETE_TASK:
            return {
                ...state,
                tasks:state.tasks.filter( task => (task.id !== action.payload))
            }
        default:
            return state;
    }
}
export default tasksReducer;