import { axiosInstance } from "../../utils/axiosInstance";
import { IParameter } from '../../interfaces/parameters/IParameter';


const getSLATaskService = () =>{
    return axiosInstance.get<IParameter>(`${process.env.REACT_APP_URL_PARAMETERS}/parameters/company/1/sla_task`)
}
const getDetailParameterService = (namespace:string,primary_key:string,key:string) =>{
    return axiosInstance.get<IParameter>(`${process.env.REACT_APP_URL_PARAMETERS}/parameters/${namespace}/${primary_key}/${key}`)
}
const getParametesrService = (namespace:string,primary_key:string) =>{
    return axiosInstance.get<IParameter[]>(`${process.env.REACT_APP_URL_PARAMETERS}/parameters/${namespace}/${primary_key}`)
}
export {
    getSLATaskService,
    getDetailParameterService,
    getParametesrService
}