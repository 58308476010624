import React from 'react'
import { useState, MouseEventHandler, useEffect } from 'react';
import { getChildrenFolderService } from '../../../../../services/documents/foldersServices';
import { IFolder } from '../../../../../interfaces/documents/IFolders';
import { StyledTreeItem } from './StyledTreeItem';
import { MyCustomSubTreeItem } from './MyCustomSubTreeITem';

interface PropsMyCustomTreeItem{
    folder:IFolder,
    onClick?:MouseEventHandler<HTMLLIElement>,
    disabled?:boolean,

}
export const MyCustomTreeItem = ({folder,disabled=false}:PropsMyCustomTreeItem) =>{

    const [folders, setFolders] = useState<IFolder[]>([])
    
    const getFolders = async () =>{
        if(folders.length === 0 && folder.sub_directories !== 0){

            const resp = await getChildrenFolderService(folder.id);
            if(resp.status===200){
                setFolders(resp.data)
            }
        }
    }
    useEffect(() => {

        const getFoldersOnLoad = async () =>{
            const resp = await getChildrenFolderService(folder.id);
            if(resp.status===200){
                setFolders(resp.data)
            }
        }
        if(folder.sub_directories !== 0){
            getFoldersOnLoad()
        }
    }, [folder])

    
    return (
        <StyledTreeItem disabled={disabled} onClick={getFolders} nodeId={`${folder.id}:${folder.name}`} label={folder.name}>
            {
                folders.length !== 0 && 
                folders.map( folder => (
                    <MyCustomSubTreeItem
                        key={folder.id} 
                        folder={folder} 
                    />
                ))
            }
        </StyledTreeItem>
    )
}