import { useField, FieldHelperProps, FieldHookConfig } from 'formik';
import { useRef } from 'react';
//Este hook soluciona un bug  o posible issue de formik que provoca un bucle infinito\
//s
// al intentar utilizar setvalue dentro de un useEffect para mas detalles ver el issue de donde fue sacado el codigo y la solucion: 
// https://github.com/jaredpalmer/formik/issues/2268
export const useFieldFast = <Val>(propsOrFieldName: string | FieldHookConfig<Val>) => {
        const [field, meta, helpers] = useField(propsOrFieldName);
    
        const actualHelpersRef = useRef<FieldHelperProps<Val>>(helpers);
    
        // On every render save newest helpers to actualHelpersRef
        actualHelpersRef.current.setValue = helpers.setValue;
        actualHelpersRef.current.setTouched = helpers.setTouched;
        actualHelpersRef.current.setError = helpers.setError;
    
        const consistentHelpersRef = useRef<FieldHelperProps<Val>>({
        setValue: (...args) => actualHelpersRef.current.setValue(...args),
        setTouched: (value: boolean, shouldValidate?: boolean) =>
            actualHelpersRef.current.setTouched(value, shouldValidate),
        setError: (...args) => actualHelpersRef.current.setError(...args),
        });
    
        return [field, meta, consistentHelpersRef.current] as const;
}
