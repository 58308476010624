
import ReactDOM from 'react-dom';
import App from './App';
import KeyCloakService from './keycloak';

const render = () => {
  ReactDOM.render(
        <App />,
  document.getElementById('root')
)}
KeyCloakService.initKeycloak(render)

