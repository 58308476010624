import { faAngleDown, faAngleUp, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Formik, Form, FormikProps } from 'formik';
import { GenerateFields } from '../../../../components/metadata/GenerateFields';
import { IDocumentType } from '../../../../interfaces/maintainers/IDocumentType';
import { InputSearchGeneral } from './InputSearchGeneral';
import { MenuItem, CircularProgress, Tooltip, ToggleButton,Button} from '@mui/material';
import { MyCheckBoxSearchField } from '../../../../components/fields/custom-fields/MyCheckBoxSearchFeld';
import { MyDocumentTypeSelectSearchField } from '../../../../components/fields/custom-fields/MyDocumentTypeSelectSearchField';
import { MyTextField } from '../../../../components/fields/MyTextField';
import { RootState } from '../../../../state/reducers/index';
import { startLoadingSearchMetadataFields, startLoadingActiveDocumentTypes, cleanSearch, startSearchMetadata } from '../../../../state/actions-creators/search';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useGenerateFields } from '../../../../hooks/useGenerateFields';
import { useRef } from 'react';
import { useSearchParams } from 'react-router-dom';


interface FormSearchAdvanced {
    type:string;
    freetext:string;
    last_signed_date:boolean;
    documentType:string;
    folder_id:string;
    folder_name:string;
}
const initialAdvancedFormValues:FormSearchAdvanced = {
    type:'',
    freetext:'',
    last_signed_date:false,
    documentType:'',
    folder_id:'',
    folder_name:'',
}
export const SearchAdvanced = () => {
    const dispatch = useDispatch()
    const [searchParams,setSearchParams] = useSearchParams()
    // Obtengo mis tipos de documentos del state
    const {typesDocuments:{list}} = useSelector((state:RootState) => state.administration)
    // Obtengo mis fields del state para generarlas dinamicamente
    const {searchFields} = useSelector((state:RootState) => state.search)
    // Obtengo el estado de la carga de los fields y listas
    const {loading} = useSelector((state:RootState) => state.ui)


    
    useEffect(() => {
        if(searchParams.get("documentType") !== null){
            dispatch(startLoadingActiveDocumentTypes(parseInt(searchParams.get("documentType")?.split(":")[0] as string),Object.fromEntries([...searchParams])))
        }else{
            dispatch(startLoadingActiveDocumentTypes())
        }
        
    }, [dispatch,searchParams])
    

    useEffect(() => {
        
        
        return () => {
            dispatch(cleanSearch())
        };
    }, [dispatch]);
    
    

    const [typeSearch, setTypeSerach] = useState(searchParams.get("typeSearch") === 'DOCUMENT' ? true : false  || true);
    const refFormAdvanced = useRef<FormikProps<FormSearchAdvanced>>(null)
    const {validationSchema,initialValues} = useGenerateFields({
        metadataFields:searchFields
    })
    return (
        <div className='my-documents__container-search'>
            <Formik
                innerRef={refFormAdvanced}
                initialValues={{
                    type:"",
                    freetext:searchParams.get("freetext") || '',
                    last_signed_date:false,
                    documentType:searchParams.get("documentType") || '',
                    folder_name:searchParams.get("folder_name") || '',
                    folder_id:searchParams.get("folder_id") || '',
                }}
                onSubmit={(values)=> {
                    console.log("FOLDER ",searchParams.get("folder_id"))
                    values.folder_id = searchParams.get("folder_id") || ''
                    values.folder_name = searchParams.get("folder_name") || ''
                    dispatch(startSearchMetadata(1,{...Object.fromEntries([...searchParams]),
                        freetext:values.freetext,
                        last_signed_date:values.last_signed_date,
                        type:values.type.split(":")[1] || '',
                        documentType:values.type,
                        folder_id:values.folder_id,
                        folder_name:values.folder_name,
                    }))
                    // Agrego a los parametros url
                    // Freetext y si esta selecciona un tipo de documento lo agrego
                    setSearchParams(
                        {...Object.fromEntries([...searchParams]),
                            ...values,
                            // type se compone de ${item.document_type_id}:${item.name}
                        ...(values.type !== '' && {documentType:values.type} )
                    } as Record<string , string | any >
                        )
                }}
            >
                {
                    (formik) =>(
                        <Form className='my-documents__container-search-form'>

                            <div className="my-documents__container-change" >             
                
                                <div className="my-documents__container-change-search">
                                    <Tooltip  title={typeSearch ? 'FullText' : 'Avanzada'}>
                
                                    <ToggleButton
                                        value="check"
                                        selected={typeSearch}
                                        onChange={() => {
                                            setTypeSerach(!typeSearch);
                                            setSearchParams({...Object.fromEntries([...searchParams]),typeSearch:typeSearch ? 'DOCUMENT' : 'METADATA'})
                                        }}
                                        >
                                        <FontAwesomeIcon  icon={ typeSearch ? faAngleDown  : faAngleUp}/>
                                    </ToggleButton>
                                    </Tooltip>
                                </div>
                                {
                                    // true == 'DOCUMENT'
                                    typeSearch === true
                                    ?  <InputSearchGeneral />
                                    :
                                    <div   className="my-documents__container-inputs-search">
                                        <MyTextField
                                                size="medium"
                                                label='Buscar'
                                                name='freetext'
                                            />
                                    </div>
                                }
                            </div>
                        {
                            typeSearch !== true && 
                            
                            <div className="my-documents__container-form-search">
                                    <div className="my-documents__title-form-search">
                                        <h4>Búsqueda  Avanzada</h4>
                                    </div>
                                
                                    <div className="my-documents__container-inputs-form-search">
                                        
                                            <div className="my-documents__input-form-search">
                                        
                                            <MyDocumentTypeSelectSearchField
                                                name="type"
                                                label='Tipo de documento'
                                                searchParams={searchParams}
                                                handleOnChange={(e) =>{
                                                    setSearchParams({})
                                                    if(list.length !==0 && e.target.value !== ''){
                                                        const documentType = list.find(item => item.document_type_id === parseInt(e.target.value.split(":")[0]))
                                                        dispatch(startLoadingSearchMetadataFields(documentType  as IDocumentType))
                                                        
                                                    }else{
                                                        dispatch(cleanSearch())
                                                    }
                                                }}
                                                IconComponent={(props:any) =>(
                                                    loading === true
                                                    ? <div  style={{display:"flex",justifyContent:"center",alignItems:"center"}}><CircularProgress  /></div> 
                                                    :<div  style={{display:"flex",justifyContent:"center",alignItems:"center"}}><FontAwesomeIcon {...props} icon={faCaretDown} /></div> 
                                                )}
                                            
                                            >
                                            <MenuItem selected value="">
                                                <em>Seleccionar</em>
                                            </MenuItem>
                                            {
                                                list.map((item) =>{
                                                    return (
                                                        <MenuItem key={item.document_type_id} value={`${item.document_type_id}:${item.name}`}>{item.display_name}</MenuItem>
                                                    )
                                                    
                                                })
                                            }

                                            </MyDocumentTypeSelectSearchField>
                                            </div>
                                            <div className='my-documents__input-form-search'>
                                            <MyCheckBoxSearchField 
                                                searchParams={searchParams}
                                                name='last_signed_date'
                                                label='Firmado'
                                            />
                                            </div>
                                        </div> 
                                    {searchFields.length === 0 && <Button variant='contained' size="small" type="submit">Buscar</Button>}
                            </div>
                            }
                        </Form>
                    )
                }
            </Formik>
            <div className="my-documents__contianer-form-search-metadata">

                {searchFields.length !==0 && typeSearch !== true && 

                        <Formik
                        enableReinitialize
                        initialValues={{
                            ...initialAdvancedFormValues,
                            ...initialValues,

                        }}
                        validationSchema={validationSchema}
                        onSubmit={(values) => {
                            values.freetext = refFormAdvanced.current?.values.freetext || ""
                            values.documentType = refFormAdvanced.current?.values.type || ""
                            values.last_signed_date = refFormAdvanced.current?.values.last_signed_date || false
                            values.type = refFormAdvanced.current?.values.type.split(":")[1] || ""
                            values.folder_id = searchParams.get("folder_id") || ''
                            values.folder_name = searchParams.get("folder_name") || ''
                            // values.typeSearch = searchParams.get("typeSearch") as string  || '';
                            dispatch(startSearchMetadata(1,{...Object.fromEntries([...searchParams]),...values}))
                            setSearchParams({...Object.fromEntries([...searchParams]),...values} as Record<string , string | any > )
                        }}

                        >
                        {
                            (formik) =>(

                                <Form className='my-documents__container-form-metadata-fields'>
                                        <GenerateFields  metadataFields={searchFields} />
                                { <Button disabled={loading} variant='contained' type="submit">Buscar</Button>}
                                </Form>
                            )
                        }
                        </Formik>
                }
            </div>
        
        </div>
    )
}
        