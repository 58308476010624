
import { faChartPie, faToolbox, faUpload, faFileInvoice, faTasks, faInbox, faBoxes } from '@fortawesome/free-solid-svg-icons';
import { IconDefinition } from "@fortawesome/fontawesome-common-types";
import { DashboardView } from "../views/admin/Dashboard/DashboardView";
import React from "react";
import { UploadDocumentView } from '../views/admin/UploadDocument/UploadDocumentView';
import { MyDocumentsView } from '../views/admin/MyDocuments/MyDocuments';
import { MyTasksView } from '../views/admin/MyTasks/MyTasksView';
import { MyRequestsView } from '../views/admin/MyRequests/MyRequestsView';
import { CompaniesView } from '../views/admin/Administration/Companies/CompaniesView';
import { DetailTaskTabs } from '../views/admin/MyTasks/components/DetailTaskTabs';
import { BatchView } from '../views/admin/Batch/BatchView';
import { FormBatch } from '../views/admin/Batch/components/FormBatch';
import { TabsBatch } from '../views/admin/Batch/components/TabsBatch';
import { ListsView } from '../views/admin/Administration/Lists/ListsView';
import { OrganizationsView } from '../views/admin/Administration/Organizations/OrganizationsView';
import { ParametersView } from '../views/admin/Administration/Parameters/ParametersView';
import { TypesDocumentsView } from '../views/admin/Administration/TypesDocuments/TypesDocumentsView';
import { DocumentType } from '../views/admin/Administration/TypesDocuments/components/DocumentType';
import { DetailRequestTabs } from '../views/admin/MyRequests/components/DetailRequestTabs';
import { DetailDocumentView } from '../views/admin/MyDocuments/components/detail-document/DetailDocumentView';
import { ProfileView } from '../views/admin/Profile/ProfileView';


export interface RoutesAdmin {
    layout?:     string ;
    path?:       string ;
    fullpath?:   string;
    name:        string;
    icon:        IconDefinition;
    groups:      string[];
    component?: React.ElementType ;
    isMenuItem:  boolean;
    isSubMenu:   boolean;
    subMenus?:   SubMenuRoute[];
}

export interface SubMenuRoute {
    layout:      string;
    path:        string;
    fullpath:   string;
    name:        string;
    component:   React.ElementType;
    groups:      string[];

}

export const routesAdmin:RoutesAdmin[] = [
    {
        layout:'/admin',
        path:'/dashboard',
        name:"Dashboard",
        fullpath:"/admin/dashboard",
        icon:faChartPie,
        groups:['APPLICATION_ADMIN'],
        component:DashboardView,
        isMenuItem:false,
        isSubMenu:false
    },
    {
        layout:'/admin',
        path:'/mis-documentos',
        fullpath:'/admin/mis-documentos',
        name:"Mis documentos",
        icon:faFileInvoice,
        groups:['APPLICATION_ADMIN'],
        component:MyDocumentsView,
        isMenuItem:true,
        isSubMenu:false
    },
    {
        layout:'/admin',
        path:'/mis-documentos/detalle-documento/:id',
        fullpath:'/admin/mis-documentos/detalle-documento/:id',
        name:"Detalle documento",
        icon:faBoxes,
        groups:['APPLICATION_ADMIN',],
        component:DetailDocumentView,
        isMenuItem:false,
        isSubMenu:false
    },
    {
        layout:'/admin',
        path:'/subir-documento',
        fullpath:'/admin/subir-documento',
        name:"Cargar documento",
        icon:faUpload,
        groups:['APPLICATION_ADMIN',],
        component:UploadDocumentView,
        isMenuItem:true,
        isSubMenu:false
    },
    {
        layout:'/admin',
        path:'/mis-tareas',
        fullpath:'/admin/mis-tareas',
        name:"Mis tareas",
        icon:faTasks,
        groups:['APPLICATION_ADMIN',],
        component:MyTasksView,
        isMenuItem:true,
        isSubMenu:false
    },
    {
        layout:'/admin',
        path:'/mis-tareas/:id',
        fullpath:'/admin/mis-tareas/:id',
        name:"Mi tarea",
        icon:faTasks,
        groups:['APPLICATION_ADMIN',],
        component:DetailTaskTabs,
        isMenuItem:false,
        isSubMenu:false
    },
    {
        layout:'/admin',
        path:'/mis-solicitudes',
        fullpath:'/admin/mis-solicitudes',
        name:"Mis solicitudes",
        icon:faInbox,
        groups:['APPLICATION_ADMIN',],
        component:MyRequestsView,
        isMenuItem:true,
        isSubMenu:false
    },
    {
        layout:'/admin',
        path:'/mis-solicitudes/:id',
        fullpath:'/admin/mis-solicitudes/:id',
        name:"Mi tarea",
        icon:faTasks,
        groups:['APPLICATION_ADMIN',],
        component:DetailRequestTabs,
        isMenuItem:false,
        isSubMenu:false
    },
    {
        layout:'/admin',
        path:'/lotes',
        fullpath:'/admin/lotes',
        name:"Lotes",
        icon:faBoxes,
        groups:['APPLICATION_ADMIN',],
        component:BatchView,
        isMenuItem:true,
        isSubMenu:false
    },
    {
        layout:'/admin',
        path:'/lotes/crear-lote',
        fullpath:'/admin/lotes/crear-lote',
        name:"Lotes",
        icon:faBoxes,
        groups:['APPLICATION_ADMIN',],
        component:FormBatch,
        isMenuItem:false,
        isSubMenu:false
    },
    {
        layout:'/admin',
        path:'/lotes/detalle-lote/:id',
        fullpath:'/admin/lotes/detalle-lote/:id',
        name:"Lotes",
        icon:faBoxes,
        groups:['APPLICATION_ADMIN',],
        component:TabsBatch,
        isMenuItem:false,
        isSubMenu:false
    },
    {
        layout:'/admin',
        path:'/administracion',
        fullpath:'/admin/administracion',
        name:"Administración",
        icon:faToolbox,
        groups:['APPLICATION_ADMIN'],
        isMenuItem:true,
        isSubMenu:true,
            subMenus:[
            {
                layout:'/admin',
                path:'/administracion/listas',
                fullpath:'/admin/administracion/listas',
                name:'Listas',
                component:ListsView,
                groups:['APPLICATION_ADMIN'],
            },
            {
                layout:'/admin',
                path:'/administracion/organizaciones',
                fullpath:'/admin/administracion/organizaciones',
                name:'Organizaciones',
                component:OrganizationsView,
                groups:['APPLICATION_ADMIN'],
            },
            {
                layout:'/admin',
                path:'/administracion/parametros',
                fullpath:'/admin/administracion/parametros',
                name:'Parámetros',
                component:ParametersView,
                groups:['APPLICATION_ADMIN'],
            },
            {
                layout:'/admin',
                path:'/administracion/companias',
                fullpath:'/admin/administracion/companias',
                name:'Compañias',
                component:CompaniesView,
                groups:['APPLICATION_ADMIN'],
            },
            {
                layout:'/admin',
                path:'/administracion/tipos-documentos',
                fullpath:'/admin/administracion/tipos-documentos',
                name:'Tipos documentos',
                component:TypesDocumentsView,
                groups:['APPLICATION_ADMIN'],
            },
        ]
    },
    {
        layout:'/admin',
        path:'/administracion/tipos-documentos/crear',
        fullpath:'/admin/administracion/tipos-documentos/crear',
        name:"Crear tipo de documento",
        icon:faInbox,
        groups:['APPLICATION_ADMIN',],
        component:DocumentType,
        isMenuItem:false,
        isSubMenu:false
    },
    {
        layout:'/admin',
        path:'/administracion/tipos-documentos/:id',
        fullpath:'/admin/administracion/tipos-documentos/:id',
        name:"Detalle tipo de documento",
        icon:faInbox,
        groups:['APPLICATION_ADMIN',],
        component:DocumentType,
        isMenuItem:false,
        isSubMenu:false
    },
    {
        layout:'/admin',
        path:'/administracion/perfil',
        fullpath:'/admin/administracion/perfil',
        name:"Detalles del usuario",
        icon:faInbox,
        groups:['APPLICATION_ADMIN',],
        component:ProfileView,
        isMenuItem:false,
        isSubMenu:false
    },
]

