import { Backdrop, Button, Fade, Modal } from "@mui/material";



interface PropsModalDefault {
    open:boolean;
    title:string;
    className?:string;
    setModal:(modal:boolean) => void;
    children?:JSX.Element | JSX.Element[],
}

export const ModalDefault = ({open,setModal,title,children,className}:PropsModalDefault) => {
    const handleClose = () => {
        setModal(false);
    };
    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className="components-modal__modal-default "
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
            <Fade in={open}>
                <div  
                    className={`${Boolean(className) && className}  components-modal__container-card-title`}>
                        <div className="components-modal__title-card">
                            <p>{title}</p>
                        </div>
                    <div className="components-modal__card-title ">
                        <div className="components-modal__card-title-content">
                            {children}
                        <div className="components-modal__modal-default-buttons" >
                            <Button  onClick={handleClose} color="primary"  variant="text">
                                Cerrar
                            </Button>
                        </div>
                        </div>
                    </div>
                </div>
            </Fade>
        </Modal>
        </div>
    )
}
