import { useFormikContext } from 'formik';
import { MyTextField } from '../../../MyTextField';
import { useEffect } from 'react';
import { InputAdornment, IconButton } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRedo } from '@fortawesome/free-solid-svg-icons';
interface Props{
    selected:string;
}
// Componente para cambiar el valor del input dependiendo si el selected que es la carpeta seleccionada
// cambia 
export const FieldSelectParentFolder = ({selected}:Props) => {
    const {setFieldValue  } = useFormikContext()
    useEffect(() => {
        if(selected){
            setFieldValue("parent_name", selected.split(":")[1]);
            setFieldValue("parent_id",  parseInt(selected.split(":")[0]));
        }
    }, [selected,setFieldValue])
    const handleResetParent = () => {
        setFieldValue("parent_name", "Raíz");
        setFieldValue("parent_id", 0);
    }
    return (
        <MyTextField 
            name="parent_name"
            label="Seleccionar padre"  
            InputProps={{
                endAdornment: (
                    <InputAdornment
                        position="end"
                    >
                        <IconButton
                            type="button"
                            onClick={handleResetParent}
                        >
                            <FontAwesomeIcon size="xs" icon={faRedo} />
                        </IconButton>
                    </InputAdornment>
                )
            }}
            inputProps={{
                readOnly: true, 
            }}
        />
    )
}
