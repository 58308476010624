
import {  Navigate } from "react-router"

interface IPropsPublicRoute {
    children:any[] | any
    isAuthenticated:boolean;
}

export const PublicRoute = ({isAuthenticated,children:Components}:IPropsPublicRoute) => {


    return isAuthenticated  === false
            ?   Components
            :  <Navigate to="/admin/dashboard" />


}
