import { Dispatch } from 'redux';
import { ActionsSearch } from '../actions-interfaces/IActionsSearch';
import { ActionsUi } from '../actions-interfaces/IActionsUi';
import { finishLoadingMetadata, stratLoadingList, finishLoadingList, startLoading, finishLoading } from './ui';
import { ActionTypesSearch } from '../action-types/searchTypes';
import { getActiveDocumentTypesService, getSearchGeneralService, getSearchMetadataService } from '../../services/search/searchServices';
import { ActionTypesAdministration } from '../action-types/administrationTypes';
import { ActionsAdministration } from '../actions-interfaces';
import { IDocumentType } from '../../interfaces/maintainers/IDocumentType';
import { ActionsDocument } from '../actions-interfaces/IActionsDocument';
import { ActionTypeDocument } from '../action-types/documentTypes';
import { RootState } from '../reducers/index';
import { IMetadataField } from '../../interfaces/metadata/IMetadataField';

export const startLoadingSearchMetadataFields = (documentType:IDocumentType) =>   (dispatch:Dispatch<ActionsSearch | ActionsUi >) =>{
    try {
            dispatch({
                type:ActionTypesSearch.LOAD_METADATA_FIELDS_SEARCH,
                payload:{metadata:documentType.metadata}
            })

    } catch (error) {
    
        dispatch(finishLoadingMetadata())
        console.log(error)
    }
}
export const startSearchMetadataGeneral = (value:string) =>  async (dispatch:Dispatch<ActionsDocument | ActionsUi >) =>{
    try {
        dispatch(stratLoadingList())
        const resp = await getSearchGeneralService(value)
        dispatch(finishLoadingList())
        dispatch({
            type:ActionTypeDocument.LOAD_DOCUMENTS,
            payload:{
                page:1,
                searchUrl:`freetext=${value}`,
                ...resp.data}
        })
        
    } catch (error) {
        dispatch(finishLoadingList())
        console.log(error)
    }
}
export const startSearchMetadata = (page:number,data:any) =>  async (dispatch:Dispatch<ActionsDocument | ActionsSearch | ActionsUi >,getState:() => RootState) =>{
    try {
        dispatch(stratLoadingList())
        const {
            search:{searchFields}
            
        } = getState()
        const urlParams = []
        const urlParamsComplete = []
        if(typeof data === 'object'){
            for (let [key, value] of Object.entries(data)) {
                if(key !== 'documentType' && key !== 'typeSearch' && key !== 'folder_name'){

                    
                    if(value !== "" &&  value as any !== false && value !== 'false'){
                    
                        if(key==='last_signed_date' ){  
                            
                                urlParams.push(`${key}=${""}`)
                        }else{
                            
                                urlParams.push(`${key}=${value}`)
                        }
                    }
                
                    
                }
                
                urlParamsComplete.push(`${key}=${value}`)
                
            }
            
        }
        // Validación realizada para verificar si la data es un string o un object
        // si es string quiere decir que es simplemente un busqueda de text
        // si es un objeto quiere decir que es una busqueda avanzada
        const uri = typeof data === 'string' ? data : urlParams.join('&')
        const resp = await getSearchMetadataService(page,uri)
  
        // console.log("URL COMPLETE ",urlParamsComplete.join('&'))
        dispatch({
            type:ActionTypeDocument.LOAD_DOCUMENTS,
            payload:{
                page:page,
                searchUrl:uri,
                ...resp.data
            }
        })

        dispatch({
            type:ActionTypesSearch.UPDATE_METADTATA_FIELDS_SEARCH,
            payload:{
                metadata:searchFields,
                metadataObject:data
            }
        })
        
    } catch (error) {
        dispatch(finishLoadingList())
        console.log(error)
    }finally{
        dispatch(finishLoadingList())
    }
}
export const startLoadingActiveDocumentTypes = (idDocumentType?:number,data?:any) =>  async (dispatch:Dispatch<ActionsAdministration | ActionsSearch | ActionsUi>) =>{
    try {
        dispatch(startLoading())
        const resp = await getActiveDocumentTypesService();
        setTimeout(() => {
            
            dispatch(finishLoading())
        }, 1000);
        if(resp.status === 200){
            dispatch({
                type:ActionTypesAdministration.LOAD_ACTIVE_DOUCUMENT_TYPES,
                payload:resp.data
            })
            if(idDocumentType){
                const documentType = resp.data.find(item => item.document_type_id === idDocumentType)
            
                dispatch({
                    type:ActionTypesSearch.UPDATE_METADTATA_FIELDS_SEARCH,
                    payload:{
                        metadata:documentType?.metadata as IMetadataField[],
                        metadataObject:data
                    }
                })
            }
        }
    } catch (error) {
        dispatch(finishLoading())
        console.log(error)
    }
}
export const cleanSearch = () => ({
    type:ActionTypesSearch.CLEAN_SEARCH
})