
import {  Navigate } from "react-router"

interface IPropsPublicRoute {
    children:any[] | any
    isAuthenticated:boolean;
}

export const PrivateRoute = ({isAuthenticated,children:Components}:IPropsPublicRoute) => {

    return isAuthenticated 
            ? Components
            : <Navigate to="/login" />

}

