import { axiosInstance } from "../../utils/axiosInstance"

// TODO: Agregar token cuando exista auth
const getCommentsByNamspacePrimaryKey =  (idTask:string)=>{
  // namespace:primarykey:id
    const idSplited = idTask.split(':')
    return axiosInstance.get(`${process.env.REACT_APP_URL_COMMENT}${idSplited[0]}/${idSplited[1]}/comments`)
}


export  {
    getCommentsByNamspacePrimaryKey
}