import { axiosInstance } from "../../utils/axiosInstance";
import { IParameter } from '../../interfaces/maintainers/IParameter';


// TODO: Agregar token cuando exista auth
const getAllParametersService = () =>{
    return axiosInstance.get(`${process.env.REACT_APP_URL_PARAMETERS}params`)
}
const addParameterService = (data:IParameter) =>{
    return axiosInstance.post(`${process.env.REACT_APP_URL_PARAMETERS}parameters/${data.namespace}/${data.primary_key}/${data.key}`,data)
}
const updateParameterService = (data:IParameter) => {
    return axiosInstance.put(`${process.env.REACT_APP_URL_PARAMETERS}parameters/${data.id}`,data)
}
const deleteParameter = (data:IParameter) => {
    return axiosInstance.delete(`${process.env.REACT_APP_URL_PARAMETERS}parameters/${data.namespace}/${data.primary_key}/${data.id}/${data.key}`)
}

export  {
    getAllParametersService,
    addParameterService,
    updateParameterService,
    deleteParameter
}