import { Column } from '@material-table/core'
import React from 'react'
import { localizationMaterialTable } from '../../../../../constants/materialTableConfig'
import { IDocumentType, IMetadata } from '../../../../../interfaces/maintainers/IDocumentType';
import MaterialTable from '@material-table/core';
import { useState, useEffect } from 'react';
import { ModalDefault } from '../../../../../components/modals/modal-default/ModalDefault';
import { FormMetadata } from './FormMetadata';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../../../state/reducers/index';
import { startLoadingMetadataByDocumentType, startDeleteMeatadata } from '../../../../../state/actions-creators/administration';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
interface PropsTableMetadata{
    loading:boolean;
    documentType:IDocumentType;
}
export const TableMetadata = ({loading,documentType}:PropsTableMetadata) => {
    const [modal, setModal] = useState(false)
    const [detail,setDetail] = useState<IMetadata>();
    const dispatch = useDispatch()
    const {currentMetadata} = useSelector((state:RootState) => state.administration.typesDocuments);
    const columns:Column<IMetadata>[] = [
        {
            title:"ID",
            field:"metadata_id",
            type:"numeric",
            align:"left",
            editable:"never"
        },
        {
            title:"Visualización",
            field:"display",
            type:"string"
        },
        {
            title:"Tipo ",
            field:"type",
            type:"string"
        },
        {
            title:"Nombre",
            field:"name",
            type:"string"
        },
        {
            title:"Label",
            field:"label"
        },
        {
            title:"Sub-string",
            field:"sub_string",
        },
        {
            title:"Umbral",
            field:"threshold",
            type:"numeric"
        },
        {
            title:"Key lista",
            field:"key_list",
        },
        {
            title:"Es entidad?",
            field:"entity",
            type:"boolean"
        },
        {
            title:"Es filtro?",
            field:"is_filtered",
            type:"boolean"
        }
    ]
    useEffect(() => {
        dispatch(startLoadingMetadataByDocumentType(documentType.document_type_id))
    }, [dispatch,documentType.document_type_id])
    return (
        <>
            <MaterialTable 
                    title={"Metadatos"}
                    columns={columns}
                    data={currentMetadata}
                    style={{minWidth:"100%"}}
                    localization={localizationMaterialTable}
                    actions={[
                        {
                            icon: 'add',
                            onClick: (event, _) => {
                                setModal(true)
                            },
                            isFreeAction: true,
                            tooltip: 'Añadir',
                        },
                        rowData =>({
                            tooltip:'Eliminar',
                            icon:() =>(  <FontAwesomeIcon color="#C60B1E" icon={faTrash} />),
                            onClick:(e) => dispatch(startDeleteMeatadata(rowData)) 
                            
                        })
            
                    ]}
                    onRowClick={(_,rowData) => { 
                        if(rowData){
                            setModal(true)
                            setDetail(rowData)
                        }
                    }}
                    isLoading={loading}
                    options={{
                        pageSizeOptions:[10,20,30],
                        search:false,
                        loadingType:'overlay',
                        pageSize:10,
                        actionsColumnIndex:-1,
                        filtering: false
                    }}
                
                />
            {modal &&
                <ModalDefault title={Boolean(detail) ? `Editando metadato` :"Creando metadato"} className="document-type__modal-metadata" setModal={setModal} open={modal}>
                    <FormMetadata 
                        setMetadata={setDetail}
                        metadataObj={Boolean(detail) ? detail : undefined} 
                        documentType={documentType}
                        setModal={setModal}
                    />
                </ModalDefault>
            }
        </>
    )
}
