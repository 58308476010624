import React from "react"

interface PropsPreviewDocument{
    file?:File ;
    urlFile?:string;
    zoom?:string;
}
export const PreviewDocument = React.memo(({file,urlFile,zoom="71"}:PropsPreviewDocument) => {
    
    const url = file !== undefined ? URL.createObjectURL(file) : urlFile;
    

    return (
        <div className="commons__preview-thumbail-container">
            <iframe 
                src={url+`#toolbar=1&zoom=${zoom}`} 
                title={ Boolean(file) ? file?.name : "Archivo cargado"} 
                className="commons__preview-thumbail-pdf"  
                allowFullScreen    >
                <a href={url} id="enlaceDescargarPdf" download={url}>
                    Tu dispositivo no puede visualizar los PDF, da click aquí para descargarlo
                </a>
            </iframe  > 
        </div>
    )
})
