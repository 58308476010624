
import { localizationMaterialTable } from '../../../../../constants/materialTableConfig'
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../state/reducers/index';
import { IAdministrationState } from '../../../../../state/reducers/administrationReducer';
import { stratLoadingCompanies, startDeleteCompany, startAddCompany, startUpdateCompany } from '../../../../../state/actions-creators/administration';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { ICompany } from '../../../../../interfaces/maintainers/IComapny';
import MaterialTable, { Column } from '@material-table/core';
import { ModalDefault } from '../../../../../components/modals/modal-default/ModalDefault';
import { TableOrganizations } from '../../Organizations/TableOrganizations';

interface PropsTableCompanies {
    loading:boolean
}

export const TableCompanies = ({loading}:PropsTableCompanies) => {
    const dispatch = useDispatch();
    const {companies}:IAdministrationState = useSelector((state:RootState) => state.administration)
    const [modal, setModal] = useState(false)
    const [currentCompany, setCurrentCompany] = useState<ICompany>()
    const columns:Column<ICompany>[]  = [
        {
            title:"ID",
            field:"id",
            type:"numeric",
            align:"left",
            editable:"never"
        },
        {
            title:"Empresa",
            field:"company",
    
            type:"string"
        },
        {
            title:"Rut",
            field:"rut",
            type:"string"
        },
        {
            title:"Logo url",
            field:"logo",
            type:"string"
        },
    ]
    useEffect(() => {
        dispatch(stratLoadingCompanies())
    }, [dispatch])
    const handleAdd = (newData:ICompany)=>{
        return new Promise<void>((resolve,reject) => {
            dispatch(startAddCompany(newData))
            resolve();
        })
    }
    const handleUpdate = (newData:ICompany) => {
        return new Promise<void>((resolve,reject) => {
            dispatch(startUpdateCompany(newData))
            resolve();

        })
    }
    return (
        <>
            <MaterialTable 
                title={"Compañias"}
                columns={columns}
                style={{minWidth:"100%"}}
                data={companies}
                localization={localizationMaterialTable}
                editable={{
                    onRowAdd:handleAdd,
                    onRowUpdate:handleUpdate,
                }}
                actions={[
                    {
                        icon: 'refresh',
                        tooltip: `Refrescar`,
                        isFreeAction: true,
                        onClick:()=> dispatch(stratLoadingCompanies())
                    },
                    rowData =>({
                        tooltip:'Eliminar',
                        icon:() =>(  <FontAwesomeIcon color="#C60B1E" icon={faTrash} />),
                        onClick:(e) => dispatch(startDeleteCompany(rowData)) 
                        
                    }),
        
                ]}
                onRowClick={(e,rowData) => { 
                    setCurrentCompany(rowData)
                    setModal(true)}}
                isLoading={loading}
                options={{
                    pageSizeOptions:[10,20,30],
                    search:false,
                    loadingType:'overlay',
                    pageSize:10,
                    actionsColumnIndex:-1,
                    filtering: false
                }}
            
            />
        {modal &&  
            <ModalDefault title={`Organizaciones de ${currentCompany?.company}`}  open={modal} setModal={setModal} >
                <div className="administration__content-modal-organization">
                   <TableOrganizations company={currentCompany} loading={loading} />
                </div>
            </ModalDefault>
        }
        </>
    )
}
