
import { Provider } from 'react-redux';
import { store } from './state';
// import { TestScreen } from './components/TestScreen';
// import { AdminLayout } from './layouts/AdminLayout';
import './assets/scss/styles.scss'
import { AppRouter } from './routers/AppRouter';
import { axiosInstance } from './utils/axiosInstance';
import { useEffect } from 'react';
import { getParametesrService } from './services/params/paramsService';
import { ActionTypesParameters } from './state/action-types/parametersTypes';
import {useState} from 'react';
import { IParameter } from './interfaces/maintainers/IParameter';
import Swal from 'sweetalert2';


const  { getState } = store;
// interceptador usado principalmente para manejar el toekn de acceso al momento de hacer una petición
axiosInstance.interceptors.request.use((config) =>{
  const token =  localStorage.getItem('token') || '';
  config!.headers!.Authorization = `Bearer ${token}`;
  config!.headers!.uuid =  getState().auth.tokenInfo?.sub! || '';

  return config;
})
// Interceptador usada principalmente para  controlar response generales dependiendo el status code
axiosInstance.interceptors.response.use((response) => {
    return response;
},(error) => {
  console.log("error ",error);
  if (error.response.status === undefined) {
    return;
  } else {
    const code = error.response.status;
    if (code === 502 || code === 504) {
      Swal.fire({title:'Atención',text:'La operación está tardando más de lo previsto, consulte el estado en unos minutos',icon:'info'})
      return Promise.reject(error);
    } 
    if(code === 401 || error.response.statusText === 'UNAUTHORIZED'){
        Swal.fire({title:'Error',text:'Credenciales incorrectas',icon:'error'})
        return Promise.reject(error);
    }
  }
})
  // if (error.response && error.response.data) {
  //     return Promise.reject(error.response.data);
  // }
  // return Promise.reject(error.message);




  
function App() {
  const [loading, setLoading] = useState(false)
  // const dispatch = useDispatch()
  useEffect(() => {
    const getParamters =  async( ) =>{
        try {
          
            setLoading(true)

            const resp = await getParametesrService('company','1');
            
            if(resp.status === 200){
              store.dispatch({
                  type:ActionTypesParameters.LOAD_GENERAL_PARAMETERS,
                  payload:resp.data
                })
            }
        } catch (error) {
            const paramsTmp:IParameter[] =[ {
              id:0,
              key:'',
              namespace:'',
              value:'',
              primary_key:'',
              label:''
          }]
            store.dispatch({
              type:ActionTypesParameters.LOAD_GENERAL_PARAMETERS,
              payload:paramsTmp
            })
            console.log(error)
        }finally{
          
            setLoading(false)
        }
    }
    getParamters();
  }, [])
  if(loading){
    return (<div>LOADING....</div>)
  }else{
    return (
      <Provider store={store}>
        <AppRouter />
      </Provider>
    );
  }
}



export default App;
