
import { TextField, InputAdornment, IconButton, CircularProgress } from '@mui/material';
import React, { useEffect } from 'react';
import moment from 'moment-timezone';
import { useFieldFast } from '../../../hooks/useFastField';
import { RootState } from '../../../state/reducers/index';
import { useSelector } from 'react-redux';

interface Props  {
    label: string;
    name: string;
    placeholder?: string;
    [x: string]: any;

}
export const MyDeriveDateField = React.memo(({ label, ...props }: Props ) => {

    const [field,metaProps,{setValue}]=useFieldFast(props)
    const { error,touched} = metaProps;
    const { parameter:{general:{slaTask}}} = useSelector((state:RootState) => state)
    useEffect(() => {
        const now = new Date()
        const noewPlusDays =now.setDate(now.getDate() + parseInt(slaTask || '1'))
        setValue(  moment(noewPlusDays).format('YYYY-MM-DD'),true)
    }, [setValue,slaTask])
    
    return (
        <TextField
            fullWidth
            InputLabelProps={{shrink:true}}
            {...field}
            {...props}
            type="date"
            error={Boolean(error) && touched}
            helperText={Boolean(error) && touched && error}
            size="small"
            label={label}
            autoComplete="nope"
            InputProps={{
                ...( slaTask === null && {endAdornment: (
                    <InputAdornment
                        position="end"
                    >
                        <IconButton
                        disabled
                        >
                            <CircularProgress size={30} />
                        </IconButton>
                    </InputAdornment>
                )})
            }}
        />
    )
})
