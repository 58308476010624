import { getBarsCountService, getCardInfoService, getLastDocumentsService, getMostViewedService, getRecentActivitesService } from '../../services/dashboard/dashboardServices';
import { ActionsDashboard } from '../actions-interfaces/IActionsDashboard';
import { stratLoadingList, finishLoadingList } from './ui';
import { ActionTypesDashboard } from '../action-types/dashboardTypes';
import { Toast } from '../../utils/sweetAlertMix';
import { ActionsUi } from '../actions-interfaces/IActionsUi';
import { Dispatch } from 'redux';
export const startLoadingLastDocuments = () => async (dispatch:Dispatch<ActionsUi | ActionsDashboard>) =>{
    try {
        dispatch(stratLoadingList())
        const resp = await getLastDocumentsService()
        dispatch(finishLoadingList())
        if(resp.status === 200){
            dispatch({
                type:ActionTypesDashboard.LOAD_LAST_DOCUMENTS,
                payload:resp.data
            })
        }
    } catch (error) {
        Toast.fire({icon:"error",title:`Algo salió mal`})
        dispatch(finishLoadingList())
        console.log(error)
    }
}
export const startLoadingMostViewed = () => async (dispatch:Dispatch<ActionsUi | ActionsDashboard>) =>{
    try {
        dispatch(stratLoadingList())
        const resp = await getMostViewedService()
        dispatch(finishLoadingList())
        if(resp.status === 200){
            dispatch({
                type:ActionTypesDashboard.LOAD_MOST_VIEWED,
                payload:resp.data
            })
        }
    } catch (error) {
        Toast.fire({icon:"error",title:`Algo salió mal`})
        dispatch(finishLoadingList())
        console.log(error)
    }
}
export const startLoadingRecentActivities = () => async (dispatch:Dispatch<ActionsUi | ActionsDashboard>) =>{
    try {
        dispatch(stratLoadingList())
        const resp = await getRecentActivitesService()
        dispatch(finishLoadingList())
        if(resp.status === 200){
            dispatch({
                type:ActionTypesDashboard.LOAD_RECENT_ACTIVITIES,
                payload:resp.data
            })
        }
    } catch (error) {
        Toast.fire({icon:"error",title:`Algo salió mal`})
        dispatch(finishLoadingList())
        console.log(error)
    }
}
export const starLoadingCardInfo = () => async (dispatch:Dispatch<ActionsUi | ActionsDashboard>) =>{
    try {
        dispatch(stratLoadingList())
        const resp = await getCardInfoService()
        dispatch(finishLoadingList())
        if(resp.status === 200){
            dispatch({
                type:ActionTypesDashboard.LOAD_CARD_COUNT,
                payload:resp.data
            })
        }
    } catch (error) {
        Toast.fire({icon:"error",title:`Algo salió mal`})
        dispatch(finishLoadingList())
        console.log(error)
    }
}
export const startLoadingBarsCount = () => async (dispatch:Dispatch<ActionsUi | ActionsDashboard>) =>{
    try {
        dispatch(stratLoadingList())
        const resp = await getBarsCountService()
        dispatch(finishLoadingList())
        if(resp.status === 200){
            dispatch({
                type:ActionTypesDashboard.LOAD_BARS_COUNT,
                payload:resp.data
            })
        }
    } catch (error) {
        Toast.fire({icon:"error",title:`Algo salió mal`})
        dispatch(finishLoadingList())
        console.log(error)
    }
}
export const cleanDashboard = () => () =>({
    type:ActionTypesDashboard.CLEAN_DASHBOARD
})