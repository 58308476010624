import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { SkeletonText } from '../../../../../components/skeletons/SkeletonText';
import { SkeletonArray } from '../../../../../components/skeletons/SkeletonArray';
interface Props{
    icon:IconProp
    rows:number
}
export const CardWithListSkeleton = ({icon,rows}:Props) => {
    return (
        <div className="dashboard__list-section-items">
        <SkeletonArray rows={4}>
            <div >
                <div className="dashboard__list-item">
                    <div className="dashboard__list-name">
                        <FontAwesomeIcon icon={icon} /> 
                        <SkeletonText height={15} />
                    </div>
                    <div className="dashboard__list-date">
                        <SkeletonText />
                    </div>
                </div>
                <hr className="base__hr-right-left-ease" />
            </div> 
        </SkeletonArray>
        
        </div>
    )
}
