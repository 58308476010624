import { Fragment } from "react"

interface Props{
    children:JSX.Element,
    rows:number
}

export const SkeletonArray = ({rows,children}:Props) => {

    return (
        <>
            {
                [...Array(rows)].map((_, i) =>(
                    <Fragment  key={i}>
                        {children}
                    </Fragment>
                ))
            }
        </>
    )
}
