import  { AxiosResponse } from "axios"
import { IModel } from '../../interfaces/entity/IModel';
import { axiosInstance } from "../../utils/axiosInstance";



const getModelsService = (project_id:string,dataset_id:string) =>{
    const response:Promise<AxiosResponse<IModel[]>> =  axiosInstance.post(`${process.env.REACT_APP_URL_ENTITY}get_models`,{project_id,dataset_id})
    return response
}
const updateStatusModelService = (project_id:string,model_id:string,status:string) =>{
    // status: deploy/undeploy
    
    return axiosInstance.post(`${process.env.REACT_APP_URL_ENTITY}${status}`,{project_id,model_id})
}

export  {
    getModelsService,
    updateStatusModelService
    
}