import { useState } from 'react';
import Autosuggest from 'react-autosuggest';
import AutosuggestHighlightMatch from 'autosuggest-highlight/match'
import AutosuggestHighlightParse from 'autosuggest-highlight/parse'
import { IDocument } from '../../../../interfaces/documents/IDocument';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../state/reducers/index';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { getSearchGeneralService } from '../../../../services/search/searchServices';



    function renderSuggestion(suggestion:IDocument, { query }:{query:string}) {
        const suggestionText = `${suggestion.name}`;
        const matches = AutosuggestHighlightMatch(suggestionText, query);
        const parts   = AutosuggestHighlightParse(suggestionText, matches);
    
        return (
        <Link to={`/admin/mis-documentos/detalle-documento/${suggestion.uuid}`}  className={`my-documents__suggestion-content` }>
            <span className="my-documents__name">
                {
                    parts.map((part, index) => {
                        const className = part.highlight ? 'my-documents__highlight' : undefined;
                        return (
                            <span className={`${className} my-documents__search-suggestion-value`} key={index}>{part.text}</span>
                        );
                    })
                }
                <span className="my-documents__search-suggestion-date">{suggestion.create_date}</span>
            </span>
        </Link>
        );
    }
    

export const InputSearchGeneral = () => {
    const {loading} = useSelector((state:RootState) => state.ui)
    const [myValue, setMyValue] = useState("");
    const [suggestions, setSuggestions] = useState<IDocument[]>([]);
    const navigate = useNavigate()

    function getSuggestionValue(suggestion:IDocument) {
        return `${suggestion.name}`;
    }
    const  getSuggestions = async (value:string)  =>{
        try {
            
            const resp = await getSearchGeneralService(value)
            const documents = resp.data.documents || []
            return documents;
        } catch (error) {
            const documents:IDocument | [] = []
            return documents;
        }
    }


    return (
        <div className="my-documents__container-inputs-search">
        <Autosuggest 
            suggestions={suggestions}
            onSuggestionsFetchRequested={ async ({value,reason}) => {
                setSuggestions(await getSuggestions(value))
                
            }}
            onSuggestionsClearRequested={() => setSuggestions([])}
            getSuggestionValue={getSuggestionValue}
            onSuggestionSelected={(_,value) =>{
                navigate(`/admin/mis-documentos/detalle-documento/${value.suggestion.uuid}`)
            }}
            id="my-documents__input-search"
            renderSuggestion={renderSuggestion}
            focusInputOnSuggestionClick
            highlightFirstSuggestion
            inputProps={{
                disabled:loading,
                placeholder: "Buscar",
                type:"search",
                value: myValue,
                onChange: (_, { newValue, method}) => {
                    setMyValue(newValue);
                }
            }} 
        />
        </div>
    )
}
