
import { Column } from '@material-table/core'
import { IDataset } from '../../../../../interfaces/entity/IDataset';
import { localizationMaterialTable } from '../../../../../constants/materialTableConfig';
import { RootState } from '../../../../../state/reducers/index';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import MaterialTable from '@material-table/core';
import { IModel } from '../../../../../interfaces/entity/IModel';
import { startLoadingModels, cleanModels, startUpdateStatusModel } from '../../../../../state/actions-creators/administration';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes, faWrench } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment-timezone';
interface Props{
    loading:boolean,
    dataset:IDataset
    projectId:string;
}
export const TableModels = ({loading,dataset,projectId}:Props) => {
    const { models } = useSelector((state:RootState) => state.administration.typesDocuments)
    const dispatch = useDispatch()

    const columns:Column<IModel>[] = [{
            title:"ID",
            field:"model_id",
            type:"numeric",
            align:"left",
            editable:"never"
        },
        {
            title:"Nombre",
            field:"model_display_name", 
            type:"string"
        },
        {
            title:"Creación",
            field:"model_create_time", 
            type:"string"
        },
        {
            title:"Desplegado",
            field:"model_deployment_state", 
            type:"string",
            render:(rowData:IModel) => (
                <>
                    {rowData.model_deployment_state === "deployed" ? <FontAwesomeIcon icon={faCheck}/> : <FontAwesomeIcon icon={faTimes}/> }
                </>
            )
        },
    ]
    // 
    useEffect(() => {
        dispatch(startLoadingModels(projectId,dataset.dataset_id))

    }, [projectId,dataset.dataset_id,dispatch])
    useEffect(() => {
        dispatch(startLoadingModels(projectId,dataset.dataset_id))
        return () => {
            dispatch(cleanModels())
        }
    }, [dispatch,dataset.dataset_id,projectId])


    return (
        <>
            <MaterialTable 
                    title={"Modelos"}
                    columns={columns}
                    style={{minWidth:"100%"}}
                    data={models.map((model)=>{
                        model.model_create_time = moment(new Date(model.model_create_time+"+00:00")).tz("America/Santiago").format("DD/MM/YYYY HH:mm")
                        return model
                    })}
                    components={{
                        Container: props => <div {...props} className="administration__table-styles-container " />,
                    }}
                    localization={localizationMaterialTable}
                    actions={[
                        {
                            icon: 'refresh',
                            tooltip: `Refrescar`,
                            isFreeAction: true,
                            onClick:()=> {
                                dispatch(startLoadingModels(projectId,dataset.dataset_id))
                            }
                        },
                        rowData =>({
                            tooltip: rowData.model_deployment_state === 'deployed' ? 'Desemplear': 'Desplegar',
                            icon:() =>(  <FontAwesomeIcon color={ rowData.model_deployment_state === 'deployed' ? "#C60B1E" :"#4da851"} icon={faWrench} />),
                            onClick:(e) => {
                                dispatch(startUpdateStatusModel(projectId,rowData.model_id, rowData.model_deployment_state === 'deployed' ? 'undeployed': 'deployed'))
                            }
                            
                        })
                    ]}

                    isLoading={loading}
                    options={{
                        pageSizeOptions:[],
                        search:false,
                        loadingType:'overlay',
                        pageSize:5,
                        actionsColumnIndex:-1,
                        filtering: false
                    }}
                
                />
        </>
    )
}
