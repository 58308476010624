import {  useField } from 'formik'
import { TextField } from '@mui/material';
import { ModalFolder } from './components/ModalFolder';
import { useState } from 'react';
interface Props  {
    label: string;
    name: string;
    type?: 'text' | 'email' | 'password';
    disabledFolder?: boolean;
    placeholder?: string;
    folderId?:number | null;
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void
    [x: string]: any;

}
// Este field o input maneja dos parametros que son: parent_path y folder_id,
// para usar este field custom declarar en el initialValues del formulario los valorres antes mencionados
export const MyFolderField = ({ label,setFieldValue,folderId,disabledFolder, ...props }: Props ) => {
    const [ field,metaProps] = useField(props)
    const { error,touched} = metaProps;
    const [ modal, setModal] = useState(false)
    return (
        <>
        <TextField
            
            fullWidth
            {...field}
            {...props}
            error={Boolean(error) && touched}
            helperText={Boolean(error) && touched && error}
            size="small"
            label={label}
            autoComplete="nope"
            onClick={()=>setModal(true)}
        />
        <ModalFolder 
            disabled={disabledFolder}
            folderId={folderId}
            open={modal} 
            setModal={setModal}
            setFieldValue={setFieldValue}
        />
        </>
    )
}
