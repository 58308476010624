import React from 'react'
import { TableLists } from './components/TableLists'
import { useSelector } from 'react-redux';
import { RootState } from '../../../../state/reducers/index';

export const ListsView = () => {
    const {loadingList} = useSelector((state:RootState) => state.ui)
    return (
        <div>
            <TableLists loading={loadingList} />
        </div>
    )
}
