import { Formik, Form } from 'formik';

import { useSelector } from 'react-redux';
import { RootState } from '../../../../state/reducers/index';
import { useGenerateFields } from '../../../../hooks/useGenerateFields';
import { GenerateFields } from '../../../metadata/GenerateFields';
import { GenerateFieldsSkeleton } from '../../../metadata/skeleton/GenerateFieldsSkeleton';


interface PropsFormMetadata {
    loading:boolean,

}
export const FormMetadataPreview = ({loading}:PropsFormMetadata) => {
    const {metadataFields} = useSelector((state:RootState) => state.document.currentDocument)

    const {validationSchema,initialValues} = useGenerateFields({
        metadataFields:metadataFields
    })
    // TODO: ver si usarlo o no
    // const formatTypeDocument = (type:string | undefined) =>{
    //     if(typeof type  === 'string'){
    //         const myNewTypeList = []
    //         for(  const p in type.split("_")){
    //             myNewTypeList.push(`${type.split('_')[p][0].toUpperCase() + type.split('_')[p].slice(1).toLocaleLowerCase() } `)  
    //         }
    //         return myNewTypeList.join(' ')
    //     }else{
    //         return ""
    //     }
    // }
    return (
        <>
        { metadataFields.length !== 0 && loading === false  ?
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values) =>{

            }}
        >
            {
                (formik) => (
                    <Form>
                        <div className="components-modal__metadata-container"  >
                            <GenerateFields  
                                preview
                                smallForm 
                                metadataFields={metadataFields} /> 
                        </div>

                    </Form>
                )
            }
        </Formik>
        :
        <div className="components-modal__metadata-container"  >
            {   loading === true ?
                <GenerateFieldsSkeleton smallForm />
                : 
                <span>No hay metadatos</span>
            }
        </div>

    }
    </>
    )
}
