
import {  IDocument } from '../../interfaces/documents/IDocument';
import { ActionTypeDocument } from '../action-types/documentTypes';
import { ActionsDocument } from '../actions-interfaces/IActionsDocument';
import { IMetadataField } from '../../interfaces/metadata/IMetadataField';
import { ISignature } from '../../interfaces/documents/ISignature';

export interface IDocumentsState {
    count:number;
    from:number;
    to:number;
    page:number;
    documents:IDocument[],
    search:{
        searchUrl:string | null,
        folderId:string | null,
    }
    currentDocument:{
        document:IDocument | null,
        metadataFields:IMetadataField[],
        fillMetadataFields:IMetadataField[],
        checkingPending:boolean;
        signatures:ISignature[]
    }

}

const initialState:IDocumentsState = {
    count:0,
    from:1,
    to:0,
    page:1,
    documents:[],
    search:{
        searchUrl:null,
        folderId:null,
    },
    currentDocument:{
        document:null,
        metadataFields:[],
        fillMetadataFields:[],
        checkingPending:false,
        signatures:[]
    }
}


const documentReducer = (state:IDocumentsState=initialState,action:ActionsDocument):IDocumentsState => {
    switch (action.type) {
        case ActionTypeDocument.LOAD_DOCUMENTS:
            return {
                ...state,
                documents:[...action.payload.documents],
                count:action.payload.count,
                from:action.payload.from,
                to:action.payload.to,
                page:action.payload.page,
                search:{
                    ...state.search,
                    searchUrl:action.payload.searchUrl,
                },

            }
        case ActionTypeDocument.SET_CURRENT_DOCUMENT:
            return {
                ...state,
                currentDocument:{
                    ...state.currentDocument,
                    document:{...action.payload}
                }
            }
        case ActionTypeDocument.LOAD_METADATA_FIELDS:
            return {
                ...state,
                currentDocument:{
                    ...state.currentDocument,
                    metadataFields:[...action.payload]
                }
            }
        case ActionTypeDocument.LOAD_FILL_METADATA_FIELDS:
            return {
                ...state,
                currentDocument:{
                    ...state.currentDocument,
                    fillMetadataFields:[...action.payload]
                }
            }
        case ActionTypeDocument.CHECKING_DOCUMENT_PENDING:
            return {
                ...state,
                currentDocument:{
                    ...state.currentDocument,
                    checkingPending:action.payload
                }
            }
        case ActionTypeDocument.LOAD_SIGNATURES:
            return {
                ...state,
                currentDocument:{
                    ...state.currentDocument,
                    signatures:[...action.payload]
                }
            }
        case ActionTypeDocument.CLEAN_CURRENT_DOCUMENT:
            return {
                ...state,
                currentDocument:initialState.currentDocument
            };
        case ActionTypeDocument.CLEAN_DOCUMENTS:
            return initialState;
        case ActionTypeDocument.CLEAN_METADATAS:
            return {
                ...state,
                currentDocument:{
                    ...state.currentDocument,
                    fillMetadataFields:[],
                    metadataFields:[],
                }
            }
        default:
            return state;
    }
}

export default documentReducer;