import { Route, Routes } from 'react-router-dom';
import { RoutesAdmin } from './routes';
import { checkGroupsUser } from '../utils/checkGroupsUser';
import { IUserToken } from '../interfaces/auth/IToken';
import React from 'react';
import { NotFound } from '../views/admin/NotFound';
interface IPropsRoutesView {
    routes:RoutesAdmin[],
    user:IUserToken 

}

export const RoutesView = React.memo(({routes,user}:IPropsRoutesView) => {

    return (
        <Routes>
    {
        
        routes.map(({component:Component,layout,path,groups,isSubMenu,subMenus},key)=>(
            // Verifico si cuenta con el grupo relacionado con el componente  
        
            checkGroupsUser(user,groups)   &&
            ( isSubMenu === false ? 
                <Route 
                    path={path!}
                    // exact
                    // render={(props) => Component ? <Component {...props} /> : null}
                    element={
                        Component ?
                        <Component />
                        :
                        null
                    }
                    key={key}
                />
            :
                subMenus?.map(({component:Component,layout,path},key) =>(
                    // Aqui si deseo puedo hacer uan condicion segun los permisos del usuario
                    <Route 
                        path={path}
                        // exact
                        // render={(props) => <Component {...props} />}
                        element={<Component />}
                        key={key}
                    />
                ))
            )
        ))
    }

            <Route path="*" element={ <NotFound />} />
        </Routes>
    )
})