import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTasks, faInbox } from '@fortawesome/free-solid-svg-icons';
import Typography from '@mui/material/Typography';
import { formatStatusTasks } from '../../../utils/formatStatusTasks';
import { ITask } from '../../../interfaces/tasks/ITask';


interface Props{
    tasksHistory:ITask[]
}
export const TaskHistory = ({tasksHistory}:Props) => {
    return (
        <div className="commons__history-container">
            <div className="commons__history">

                <Timeline position="alternate">
                    {
                        tasksHistory.map((task, index) => (
                        <div key={index}>
                        <TimelineItem>
                            <TimelineOppositeContent
                            sx={{ m: 'auto 0' }}
                            align="right"
                            variant="body2"
                            color="text.secondary"
                            >
                                {moment(task.create_date+"+00:00").tz("America/Santiago").format("DD/MM/YYYY HH:mm") }
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                            <TimelineDot color="success"  variant="filled">
                                <FontAwesomeIcon icon={faTasks} />
                            </TimelineDot>
                            <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent>
                            <div className="commons__history-content">
                                <h3 >
                                    {task.user_name_from}
                                </h3>
                            </div>
                            </TimelineContent>
                        </TimelineItem>
                        <TimelineItem>
                            <TimelineSeparator>
                            <TimelineDot variant="filled" color="primary">
                                <FontAwesomeIcon icon={faInbox} />
                            </TimelineDot>
                            
                            {task.status !== 2 && task.status !== 3 &&  <TimelineConnector />}
                            </TimelineSeparator>
                            <TimelineContent>
                            <div className="commons__history-content">
                                <h3>
                                    {task.user_name_to}
                                </h3>
                                <Typography>Estado: {formatStatusTasks(task.status)}</Typography>
                            </div>
                            </TimelineContent>
                        </TimelineItem>
                            </div>
                        ))
                    }
                </Timeline>
            </div>
        </div>
    )
}
