import {  useField } from 'formik'
import {  FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material';
import { useState, useEffect } from 'react';
import { IDetailList } from '../../../interfaces/maintainers/ILists';
import { getDetailListByKey } from '../../../services/maintainers/listsServices';

interface Props  {
    label: string;
    name: string;
    keyList:string;
    handleOnBlur?:(e:React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) =>void;
    [x: string]: any;

}
export const MySelectFieldList = ({ label,handleOnBlur,keyList, ...props }: Props ) => {
    const [ fields,metaProps] = useField(props)
    const { error,touched} = metaProps;
    const {onBlur,...field} = {...fields}
    const [lists, setLists] = useState<IDetailList[]>([])
    useEffect(() => {
        const getList = async () => {
            const resp =  await getDetailListByKey(keyList)
            try {
                if (resp.status === 200){
                    setLists(resp.data)
                }
            } catch (error) {
                console.log(error)
            }
        }
        getList()
        return () => {
            setLists([])
        }
    }, [keyList])

    return (
        <FormControl size="small" fullWidth  variant="outlined" >             
            <InputLabel htmlFor={props.name}>{label}</InputLabel>
            <Select
                error={Boolean(error) && touched}
                label={label}
                {...props}
                onBlur={(e) =>{
                    onBlur(e)
                    if(handleOnBlur){
                        handleOnBlur(e)
                    }
                }}
                {...field}
                inputProps={{
                    id: props.name,     
                }}
                >
                    <MenuItem value="" selected>Selccionar</MenuItem>
                    {
                        lists.map((list) => (
                            <MenuItem key={list.id} value={list.id}>{list.value}</MenuItem>
                        ))
                    }
                </Select>
                
            {Boolean(error) && touched && <FormHelperText error={Boolean(error) && touched} >{error}</FormHelperText>}
        </FormControl>
    )
}