import { axiosInstance } from "../../utils/axiosInstance";
import { IMetadata } from '../../interfaces/maintainers/IDocumentType';


const addMetadataService = (metadata: IMetadata) =>{
    return axiosInstance.post<IMetadata>(`${process.env.REACT_APP_URL_DATASET}metadata`, metadata)
}
const updateMetadataService = (metadata: IMetadata) =>{
    return axiosInstance.put<IMetadata>(`${process.env.REACT_APP_URL_DATASET}metadata/${metadata.metadata_id}`, metadata)
}
const getMetadataByDocumentTypeService = (idDocumentType:number) =>{
    return axiosInstance.get<IMetadata[]>(`${process.env.REACT_APP_URL_DATASET}metadata/company/${idDocumentType}`)
}
const deleteMetadataService = (idMetadata:number) =>{
    return axiosInstance.delete(`${process.env.REACT_APP_URL_DATASET}metadata/${idMetadata}`)
}
export {
    addMetadataService,
    getMetadataByDocumentTypeService,
    updateMetadataService,
    deleteMetadataService
}