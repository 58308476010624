import { BatchStatus } from '../constants/batchStatus';
export const formatStatusBatch = (status:number | string):string => {
    switch (status) {
        case BatchStatus.PENDIENTE:
            return "Pendiente"
        case BatchStatus.PROCESANDO:
            return "Procesando"
        case BatchStatus.PROCESADO:
            return "Procesado"
        case BatchStatus.PARCIAL:
            return "Parcial"
        default:
            return ""
    }
}