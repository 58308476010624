import { Formik, Form } from 'formik';
import { Card } from '../../../../../components/cards/Card';
import { GenerateFields } from '../../../../../components/metadata/GenerateFields';
import { Button } from '@mui/material';
import { RootState } from '../../../../../state/reducers/index';
import { useSelector, useDispatch } from 'react-redux';
import { IDocument } from '../../../../../interfaces/documents/IDocument';
import { GenerateFieldsSkeleton } from '../../../../../components/metadata/skeleton/GenerateFieldsSkeleton';
import { startUpdateDocumentMetadata } from '../../../../../state/actions-creators/document';
import { useGenerateFields } from '../../../../../hooks/useGenerateFields';
import { FormSelectDocumentType } from './FormSelectDocumentType';
import { formatTypeDocument } from '../../../../../utils/formatTypeDocument';

interface PropsFormMetadata {
    document:IDocument | null,
    loading:boolean,
    id:string;
}
export const FormMetadata = ({document,loading,id}:PropsFormMetadata) => {
    const dispatch = useDispatch()
    const {metadataFields} = useSelector((state:RootState) => state.document.currentDocument)

    const {validationSchema,initialValues} = useGenerateFields({
        metadataFields:metadataFields
    })


    return (
        <>
        { metadataFields.length !== 0 && loading === false  ?
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values) =>{

                dispatch(startUpdateDocumentMetadata(id,values))

            }}
        >
            {
                (formik) => (
                    <Form>
                        <Card className="my-documents__details-documents-metadata-container"  title={`Metadatos: ${formatTypeDocument(document?.type)}`}>
                            <GenerateFields  smallForm metadataFields={metadataFields} /> 
                            <Button disabled={loading}  size="large"   variant="outlined" color="primary" type="submit">Guardar</Button>
                        </Card>

                    </Form>
                )
            }
        </Formik>
        :
        <Card className="my-documents__details-documents-metadata-container"  title={`Metadatos: ${formatTypeDocument(document?.type)}`}>
            {   loading === true ?
                <GenerateFieldsSkeleton smallForm />
                : 
                <FormSelectDocumentType 
                    uuidDocument={document?.uuid}
                />
            }
        </Card>

    }
    </>
    )
}
