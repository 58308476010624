
import { IMetadata, IDocumentType } from '../../../../../interfaces/maintainers/IDocumentType';
import {  Button, MenuItem  } from '@mui/material';
import { useEffect } from 'react';
import { Formik, Form } from 'formik';
import { MyTextField } from '../../../../../components/fields/MyTextField';
import { MySelectField } from '../../../../../components/fields/MySelectField';
import { MyCheckBoxField } from '../../../../../components/fields';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { startAddMetadata } from '../../../../../state/actions-creators';
import { startUpdateMetadata, stratLoadingAllLists } from '../../../../../state/actions-creators/administration';
import { RootState } from '../../../../../state/reducers/index';
const initialForm ={
    display:"",
    type:"",
    label:"",
    sub_string:undefined,
    key_list:"",
    entity:false,
    threshold:undefined,
    metadata_id:undefined,
    name:"",
    document_type_id:undefined,
    is_filtered:false,

}
interface PropsFormMetadata{
    metadataObj?:IMetadata,
    documentType:IDocumentType;
    setMetadata:React.Dispatch<React.SetStateAction<IMetadata | undefined>>,
    setModal:React.Dispatch<React.SetStateAction<boolean>>;
}
export const FormMetadata = ({metadataObj,documentType,setModal,setMetadata}:PropsFormMetadata) => {
    const dispatch = useDispatch()
    const {lists} = useSelector((state:RootState) => state.administration)
    useEffect(() => {
        dispatch(stratLoadingAllLists())
        return () => {
            setMetadata(undefined)
        }
    }, [setMetadata,dispatch])

    return (
        <Formik
            initialValues={metadataObj !== undefined ? metadataObj :  initialForm}
            validationSchema={Yup.object({
                name: Yup.string().required("Campo name requerido"),
                label: Yup.string().required("Campo label requerido"),
                type: Yup.string().required("Campo type requerido"),
                display: Yup.string().required("Campo visualización requerido"),
                sub_string: Yup.number().required("Campo sub_string requerido"),
                threshold: Yup.number().required("Campo umbral requerido"),
                key_list: Yup.string().when('type', {
                    is:"select",
                    then:Yup.string().required("Campo key_list requerido"),
                }),
                entity: Yup.boolean(),
                is_filtered:Yup.boolean(),

            })}
            onSubmit={(values) => {
                values.document_type_id = documentType.document_type_id
                if(metadataObj !== undefined){
                    dispatch(startUpdateMetadata(values as IMetadata))
                }else{
                    dispatch(startAddMetadata(values as IMetadata,setModal))
                }
            }}

        >
            {
                ({isValid,values}) =>(
                    <Form className="document-type__form-metadata-container">
                        <div className="document-type__form-input-container">
                            <MyTextField
                                name="name"
                                label="Name"
                            />
                        
                        </div>
                        <div className="document-type__form-input-container">
                            <MySelectField
                                name="type"
                                label="Type">
                                <MenuItem value={undefined} >Seleccionar</MenuItem>
                                <MenuItem value="text">Texto</MenuItem>
                                <MenuItem value="date">Fecha</MenuItem>
                                <MenuItem value="bool">Bool</MenuItem>
                                <MenuItem value="email">Correo</MenuItem>
                                <MenuItem value="number">Numero</MenuItem>
                                <MenuItem value="select">Select</MenuItem>
                            </MySelectField>
                            
                        </div>
                        <div className="document-type__form-input-container">
                            <MySelectField
                                name="display"
                                label="Visualización">
                                <MenuItem value={undefined} selected>Seleccionar</MenuItem>
                                <MenuItem value="text_input" >Input</MenuItem>
                            </MySelectField>
                        </div>
                        <div className="document-type__form-input-container">
                            <MyTextField 
                                name="label" 
                                label="Label" 
                            />
                            
                        </div>
                        <div className="document-type__form-input-container">
                            <MyTextField 
                                name="threshold"
                                label="Umbral"
                                type="number"
                            />
                        </div>
                        <div className="document-type__form-input-container">
                            <MyTextField 
                                label="Substring"
                                name="sub_string"
                                type="number"
                            />
                        </div>
                        <div className="document-type__form-input-container">
            
                            <MySelectField 
                                label="Key list"
                                name="key_list"
                                disabled={values.type !== "select"}
                                >
                                    <MenuItem value={""} selected>Seleccionar</MenuItem>
                                {
                                    lists.map(list => (
                                        <MenuItem key={list.id} value={list.key}>{list.key}</MenuItem>
                                    ))
                                }
                                
                            </MySelectField>
                        </div>
                        <div className="document-type__form-input-container">
                            <MyCheckBoxField 
                                label="Es entidad?"
                                name="entity"
                            />
                            <MyCheckBoxField 
                                label="Es filtro?"
                                name="is_filtered"
                                labelPlacement="start"
                            />
                        </div>
                        <div className="document-type__form-buttons-container">
                            <Button disabled={isValid===false} type="submit" variant="contained">
                                { Boolean(metadataObj)  ? "Guardar" : "Agregar"}
                            </Button>    
                        </div>

                    </Form>
                )
            }
        </Formik>
    )
}
