


import { TableDocuments } from './components/TableDocuments';
import { useState, SyntheticEvent } from 'react';
import { SearchAdvanced } from './components/SearchAdvanced';
import { useSearchParams } from 'react-router-dom';
import {  useDispatch } from 'react-redux';
import { FolderTree } from '../../../components/commons/folder/folder-tree/FolderTree';
import { startSearchMetadata } from '../../../state/actions-creators/search';

export const MyDocumentsView = () => {
    const [searchParams,setSearchParams] = useSearchParams()
    const [selected, setSelected] = useState('');
    const dispatch = useDispatch()
    

    const handleSelect = (event: SyntheticEvent, nodeIds: string) => {
        //nodeIds: folder_id:folder_name
        setSelected(nodeIds);
        dispatch(startSearchMetadata(1,{...Object.fromEntries([...searchParams]),
                                        folder_id:nodeIds.split(":")[0],
                                        folder_name:nodeIds.split(":")[1],
                                    }))
        setSearchParams({...Object.fromEntries([...searchParams]),
                        folder_id:nodeIds.split(":")[0],
                        folder_name:nodeIds.split(":")[1],
                    })
    
    };
    
    return (
        <>
       
         <SearchAdvanced />


        <div className='my-documeents__container-folder-table'>
            <div className="my-documents__container-folder">
                <div className="my-documents__item-folder-title">
                    <h4>Carpeta:</h4>
                    <p>{searchParams.get('folder_name') }</p>
                </div>
                <FolderTree 
                    handleSelect={handleSelect}
                    selected={selected}
                    // folderId={searchParams.get("folder_id") !== null ?  searchParams.get("folder_id") : null  }  
                />
            </div>
            <div className="my-documents__container-table">
                <TableDocuments />
            </div>
        </div>
        </>
    )
}
