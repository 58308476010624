import MaterialTable, { Column } from '@material-table/core'
import  { useState } from 'react'
import { localizationMaterialTable } from '../../../../../constants/materialTableConfig'
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../state/reducers/index';
import { IAdministrationState } from '../../../../../state/reducers/administrationReducer';
import { startAddList, startDeleteList, stratLoadingAllLists } from '../../../../../state/actions-creators/administration';
import { IList } from '../../../../../interfaces/maintainers/ILists';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { ModalDefault } from '../../../../../components/modals/modal-default/ModalDefault';
import { TableDetailList } from './TableDetailList';

interface PropsTableLists {
    loading:boolean
}

export const TableLists = ({loading}:PropsTableLists) => {
    const dispatch = useDispatch();
    const {lists}:IAdministrationState = useSelector((state:RootState) => state.administration)
    const [modal, setModal] = useState(false)
    const [currentList, setCurrentList] = useState<IList>()
    const columns:Column<IList>[]  = [
        {
            title:"ID",
            field:"id",
            type:"numeric",
            align:"left",
            editable:"never",
        
        },
        {
            title:"Namespace",
            field:"namespace",
            type:"string",
            editable:"onAdd"
        },
        {
            title:"Primary key",
            field:"primary_key",
            type:"string",

        },
        {
            title:"Key",
            field:"key",
            type:"string",
            editable:"onAdd"
        },
    ]
    useEffect(() => {
        dispatch(stratLoadingAllLists())
    }, [dispatch])
    const handleAdd = (newData:IList)=>{
        return new Promise<void>((resolve,reject) => {
            dispatch(startAddList(newData))
            resolve();
        })
    }
 
    return (
    <>
        <MaterialTable 
            title="Listas"
            columns={columns}
            style={{minWidth:"100%"}}
            data={lists}
            localization={localizationMaterialTable}
            editable={{
                onRowAdd:handleAdd,
            }
            }
            actions={[
                {
                    icon: 'refresh',
                    tooltip: `Refrescar`,
                    isFreeAction: true,
                    onClick:()=> dispatch(stratLoadingAllLists())
                },
                rowData =>({
                    tooltip:'Eliminar',
                    icon:() =>(  <FontAwesomeIcon color="#C60B1E" icon={faTrash} />),
                    onClick:(e) => dispatch(startDeleteList(rowData)) 
                    
                })
            ]}
            onRowClick={(e,rowData) => { 
                setCurrentList(rowData)
                setModal(true)}}
            isLoading={loading}
            options={{
                pageSizeOptions:[10,20,30],
                search:false,
                loadingType:'overlay',
                pageSize:10,
                actionsColumnIndex:-1,
                filtering: false
            }}
        
        />
        {modal && currentList &&  
            <ModalDefault title={`Detalles de lista ${currentList?.primary_key}`}  open={modal} setModal={setModal} >
                <div className="administration__content-modal-list-detail">
                    <TableDetailList list={currentList} loading={loading} />
                </div>
            </ModalDefault>
        }
    </>
    )
}
