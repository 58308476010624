import { Backdrop, Button, Fade, Modal } from '@mui/material';
import { PreviewDocument } from '../../commons/PreviewDocument';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { startLoadingMetadataFields, cleanCurrentDocument } from '../../../state/actions-creators/document';
import { RootState } from '../../../state/reducers/index';
import { FormMetadataPreview } from './components/FormMetadataPreview';

interface PropsModalPreivewDocument {
    open:boolean;
    setModal:(modal:boolean) => void;
    children?:JSX.Element | JSX.Element[],
    urlFile:string | undefined,
    uuidDocument:string;
}

export const ModalPreviewMetadata = ({open,setModal,uuidDocument,urlFile}:PropsModalPreivewDocument) => {
    const dispatch = useDispatch()
    const {loadingMetadata} = useSelector((state:RootState) => state.ui)
    const handleClose = () => {
        setModal(false);
    };
    useEffect(() => {
        dispatch(startLoadingMetadataFields(uuidDocument))
        return () => {
            dispatch(cleanCurrentDocument())
        }
    }, [dispatch,uuidDocument])
    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className="components-modal__modal-metadata-document "
                open={open}
                disableEscapeKeyDown={true}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
            <Fade in={open}>
            <div className="components-modal__modal-metadata-document-container ">
                <span onClick={handleClose} className="components-modal__modal-metadata-document-close">X</span>
                <div className="components-modal__modal-metadata-document-title">
                    <h4>Previsualización de documento</h4>
                </div>
                <div className="components-modal__modal-metadata-content">

                    <div className="components-modal__modal-metadata-document-item">
                        <PreviewDocument  zoom='75' urlFile={urlFile} />
                    </div>
                    <div className="components-modal__modal-metadata-document-item">
                    <FormMetadataPreview loading={loadingMetadata}  /> 
                    </div>
                </div>
                <div className="components-modal__modal-metadata-document-buttons" >
                <Button onClick={handleClose}   color="primary" type="button"  variant="contained">
                    Cerrar
                </Button>
                </div>
                {/* <p id="transition-modal-description">react-transition-group animates me.</p> */}        
            </div>
            </Fade>
        </Modal>
        </div>
    )
}
