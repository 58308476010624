
interface Props{
    height?: number;
}
export const SkeletonText = ({height}:Props) => {
    return (
        <div
            style={{
                ...(Boolean(height) && {height: `${height}px`}),
            }}
            className='skeleton skeleton__text'
        />   
    )
}
