import React from 'react'
import { RootState } from '../../../../state/reducers'
import { useSelector } from 'react-redux';
import { TableParameters } from './components/TableParameters';

export const ParametersView = () => {
    const {loadingList} = useSelector((state:RootState) => state.ui)
    return (
        <div>
            <TableParameters loading={loadingList} />
        </div>
    )
}
