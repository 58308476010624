import { IComment } from '../../../interfaces/comment/IComment';
import TimelineItem from '@mui/lab/TimelineItem';
import Timeline from '@mui/lab/Timeline';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineDot from '@mui/lab/TimelineDot';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faComment } from '@fortawesome/free-solid-svg-icons';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import moment from 'moment';

interface Props {
    loading: boolean
    comments:IComment[]
}
export const TaskComment = ({loading,comments}:Props) => {
    return (
        <div className="commons__comment-container">
            <div className="commons__comment">

                <Timeline position="right">
                    {
                        comments.map((comment, index) => (
                        <div key={index}>
                        <TimelineItem key={index}>
                            <TimelineOppositeContent
                            // sx={{ m: 'auto 0' }}
                            align="right"
                            variant="body2"
                            color="text.secondary"
                            style={{flex:0.1}}
                            >
                                {moment(comment.date+"+00:00").tz("America/Santiago").format("DD/MM/YYYY HH:mm")}
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                <TimelineDot  color="info"  variant="filled">
                                    <FontAwesomeIcon icon={faComment} />
                                </TimelineDot>
                                {comments.length -1 !== index &&  <TimelineConnector />}
                            </TimelineSeparator>
                            <TimelineContent>
                            <div className="commons__comment-content">
                                <h3>{comment.user_name}</h3>
                                <p>
                                    {comment.comment}
                                </p>
                        
                            </div>
                            </TimelineContent>
                        </TimelineItem>
        
                            </div>
                        ))
                    }
                </Timeline>
            </div>
        </div>
    )
}
