import React from 'react'
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Tabs, Tab } from '@mui/material';
import { TableTypesDocuments } from './TableTypesDocuments';
import { RootState } from '../../../../../state/reducers/index';
import { TableDatasets } from './TableDatasets';
const a11yProps = (index:number) => {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,

    };
}

export const TabsDocumentTypes = () => {

    const {loadingList,loading} = useSelector((state:RootState) => state.ui)
    const [tabValue, setTabValue] = useState<number>(0)
    const handleChangeTab = (_:React.ChangeEvent<{}>, newValue:any) => {
        setTabValue(newValue);
    };


    return (
        <>
        <div className="base__container-tabs ">
            <Tabs disabled={loading} indicatorColor="primary" variant="scrollable" onChange={handleChangeTab} scrollButtons="auto" value={tabValue} aria-label="simple tabs example">
                    <Tab className="base__tab" label="Tipos de documento" {...a11yProps(0)} />
                    <Tab className="base__tab" label="Administración modelos" {...a11yProps(1)} />
            </Tabs>
        </div>
        {
            tabValue === 0  ?
                <TableTypesDocuments loading={loadingList} />
            :
            tabValue  === 1 ? 
                <TableDatasets  loading={loadingList} />
            :
                <h5>loading</h5>
        }
        </>
    )
}
