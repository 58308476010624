import  { AxiosResponse } from "axios"
import { IDataset } from '../../interfaces/entity/IDataset';
import { axiosInstance } from "../../utils/axiosInstance";


const getDataSetsService =  (project_id:string)=>{
    const response:Promise<AxiosResponse<IDataset[]>> =  axiosInstance.post(`${process.env.REACT_APP_URL_ENTITY}get_datasets`,{project_id})
    return response
}


export  {
    getDataSetsService,

}