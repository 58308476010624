
import { ActionTypesParameters } from '../action-types';
import { ActionsParameter } from '../actions-interfaces/IActionsParameter';

export interface IGeneralParams{
    projectId:string | null,
    slaTask:string | null,
}
export interface IParamsState {
    general:IGeneralParams
   
}
const initialState:IParamsState = {
    general:{
        projectId:null,
        slaTask:null,
    }

}


const parameterReducer = (state:IParamsState=initialState,action:ActionsParameter) => {
    switch (action.type) {
        case ActionTypesParameters.LOAD_GENERAL_PARAMETERS:
        
            const generalParams:IGeneralParams ={
                projectId:'',
                slaTask:'',
                
            }
        
            for(const parameter of action.payload){
        
                if(parameter.key === 'gcp_default_project_id'){
                    generalParams.projectId = parameter.value;
                }
                if(parameter.key === 'sla_task'){
                    generalParams.slaTask = parameter.value;
                }
            
            }
            return {
                ...state, 
                general:generalParams
                
            }
        
        default:
            return state;
    }
}
export default parameterReducer;