
import { localizationMaterialTable } from '../../../../constants/materialTableConfig'
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../state/reducers/index';
import { IAdministrationState } from '../../../../state/reducers/administrationReducer';
import { startAddOrganization, startUpdateOrganization, startDeleteOrganization, stratLoadingOrganizations, stratLoadingOrganizationsByCompany } from '../../../../state/actions-creators/administration';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

import MaterialTable, { Column } from '@material-table/core';
import { IOrganization } from '../../../../interfaces/maintainers/IOrganization';
import { ICompany } from '../../../../interfaces/maintainers/IComapny';

interface PropsTableOrganizations {
    loading:boolean
    company?:ICompany 
}

export const TableOrganizations = ({loading,company}:PropsTableOrganizations) => {
    const dispatch = useDispatch();
    const {organizations}:IAdministrationState = useSelector((state:RootState) => state.administration)
    const columns:Column<IOrganization>[]  = [
        {
            title:"ID",
            field:"id",
            type:"numeric",
            align:"left",
            editable:"never"
        },
        {
            title:"Nombre",
            field:"name",
            type:"string"
        },
        {
            title:"Dirección",
            field:"address",
            type:"string"
        }
    ]
    useEffect(() => {
        if(company){
            dispatch(stratLoadingOrganizationsByCompany(company.id))
        }else{

            dispatch(stratLoadingOrganizations())
        }
    }, [dispatch,company])
    const handleAdd = (newData:IOrganization)=>{
        return new Promise<void>((resolve,reject) => {
            if(company){
                newData.company_id = company.id
            }
            dispatch(startAddOrganization(newData))
            resolve();
        })
    }
    const handleUpdate = (newData:IOrganization) => {
        return new Promise<void>((resolve,reject) => {
            dispatch(startUpdateOrganization(newData))
            resolve();

        })
    }
    return (
        <MaterialTable 
            title="Organizaciones"
            columns={columns}
            style={{minWidth:"100%"}}
            data={organizations}
            localization={localizationMaterialTable}
            components={{
                Container: props => <div {...props} className="administration__table-styles-container" />,
            }}
            editable={{
                ...(company && {onRowAdd:handleAdd}),
                onRowUpdate:handleUpdate,
            }}
            actions={[
                {
                    icon: 'refresh',
                    tooltip: `Refrescar`,
                    isFreeAction:true,
                    onClick:()=> {
                        if(company){
                            dispatch(stratLoadingOrganizationsByCompany(company.id))
                        }else{
                            dispatch(stratLoadingOrganizations())
                        }
                    }
                }
                ,
                rowData =>({
                    tooltip:'Eliminar',
                    icon:() =>(  <FontAwesomeIcon color="#C60B1E" icon={faTrash} />),
                    onClick:(e) => dispatch(startDeleteOrganization(rowData)) 
                    
                })
            ]}
            isLoading={loading}
            options={{
                pageSizeOptions:!!!company ? [10,20,30] : [],
                search:false,
                loadingType:'overlay',
                pageSize:!!!company ? 10 : 5,
                actionsColumnIndex:-1,
                filtering: false
            }}
        
        />
    )
}
