import { axiosInstance } from "../../utils/axiosInstance";
import { IDocumentType, IResponseDocumentType } from '../../interfaces/maintainers/IDocumentType';

// TODO: Agregar token cuando exista auth
const getAllDocumentTypesService = (page:number) =>{
    return axiosInstance.get<IResponseDocumentType>(`${process.env.REACT_APP_URL_DATASET}document_types?size=10&page=${page}`)
}
const getAllActiveDocumentTypesService = () =>{
    return axiosInstance.get<IResponseDocumentType>(`${process.env.REACT_APP_URL_DATASET}document_types?size=100&page=1`)
}
const addDocumentTypeService = (documentType: IDocumentType) =>{
    documentType.company_id = 1;
    documentType.metadata =[]
    return axiosInstance.post<IDocumentType>(`${process.env.REACT_APP_URL_DATASET}document_types`, documentType)
}
const updateDocumentTypeService = (documentType: IDocumentType) =>{
    documentType.company_id = 1;
    documentType.metadata =[]
    return axiosInstance.put<IDocumentType>(`${process.env.REACT_APP_URL_DATASET}document_types/${documentType.document_type_id}`, documentType)
}
const deleteDocumentTypeService = (documentTypeId: number) =>{
    return axiosInstance.delete(`${process.env.REACT_APP_URL_DATASET}document_types/${documentTypeId}`)
}
const getDetailDocumentTypeService = (documentTypeId: number) =>{
    return axiosInstance.get<IDocumentType>(`${process.env.REACT_APP_URL_DATASET}document_types/by_id/${documentTypeId}`)
}
export {
    getAllDocumentTypesService,
    getDetailDocumentTypeService,
    addDocumentTypeService,
    updateDocumentTypeService,
    deleteDocumentTypeService,
    getAllActiveDocumentTypesService

}