import { FormTask } from './FormTask';
import { useEffect } from 'react';
import { startLoadingDetailTask, cleanCurrentTask } from '../../../../../state/actions-creators/task';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../state/reducers/index';
import { TaskTable } from '../../../../../components/commons/tabs-tasks/TaskTable';

export const TaskTab = ({id}:{id:string}) => {
    const dispatch = useDispatch()
 
    useEffect(() => {
        dispatch(startLoadingDetailTask(id));
        return () => {
            dispatch(cleanCurrentTask())
        }
    }, [dispatch,id])
    const {task} = useSelector((state:RootState) => state.task.currentTask)
    return (
        <>
            <TaskTable task={task} />
            <FormTask id={id} task={task} />
        </>
    )
}
