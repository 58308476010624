
import { ActionTypeUi } from '../action-types';
import { ActionsUi } from '../actions-interfaces';

export interface IUiState {
    loading:boolean;
    loadingList:boolean;
    loadingMetadata:boolean;
    loadingDocument:boolean;
    loadingGeneral:boolean;
}

const initialState:IUiState = {
    loading:false,
    loadingList:false,
    loadingMetadata:false,
    loadingDocument:false,
    loadingGeneral:false,
}


const uiReducer = (state:IUiState=initialState,action:ActionsUi):IUiState => {
    switch (action.type) {
        case ActionTypeUi.START_LOADING:
            return {
                ...state,
                loading:true
                
            }
        case ActionTypeUi.FINISH_LOADING:
            return {
                ...state,
                loading:false
            }
        case ActionTypeUi.START_LOADING_LIST:
            return {
                ...state,
                loadingList:true
            }
        case ActionTypeUi.FINISH_LOADING_LIST:
            return {
                ...state,
                loadingList:false
            }
        case ActionTypeUi.START_LOADING_METADATA:
            return {
                ...state,
                loadingMetadata:true
            }
        case ActionTypeUi.FINISH_LOADING_METADATA:
            return {
                ...state,
                loadingMetadata:false
            }
        case ActionTypeUi.START_LOADING_DOCUMENT:
            return {
                ...state,
                loadingDocument:true
            }
        case ActionTypeUi.FINISH_LOADING_DOCUMENT:
            return {
                ...state,
                loadingDocument:false
            }
        case ActionTypeUi.START_LOADING_GENERAL:
            return {
                ...state,
                loadingGeneral:true
            }
        case ActionTypeUi.FINISH_LOADING_GENERAL:
            return {
                ...state,
                loadingGeneral:false
            }
        default:
            return state;
    }
}

export default uiReducer;