export enum FileStatus {
    ERROR = -1,
    PENDIENTE = 0,
    BORRADOR = 1,
    CLASIFICADO = 2,
    NO_CLASIFICADO = 3,
    PROCESANDO = 4,
    PROCESADO = 5,
    INDEXADO = 6,
    APROBADO = 7
}