export enum  ActionTypesAdministration {
    LOAD_LISTS  = "[Administration] Load lists",
    ADD_LIST    = "[Administration] Add list",
    UPDATE_LIST = "[Administration] Update list",
    DELETE_LIST = "[Administration] Delete list",

    LOAD_DETAIL_LIST   = "[Administration] Load detail lists",
    ADD_DETAIL_LIST    = "[Administration] Add detail list",
    UPDATE_DETAIL_LIST = "[Administration] Update detail list",
    DELETE_DETAIL_LIST = "[Administration] Delete detail list",
    
    LOAD_COMPANIES = "[Administration] Load companies",
    ADD_COMPANY    = "[Administration] Add company",
    UPDATE_COMPANY = "[Administration] Update company",
    DELETE_COMPANY = "[Administration] Delete company",

    LOAD_ORGANIZATIONS  = "[Administration] Load organization",
    ADD_ORGANIZATION    = "[Administration] Add organization",
    UPDATE_ORGANIZATION = "[Administration] Update organization",
    DELETE_ORGANIZATION = "[Administration] Delete organization",

    LOAD_PARAMETERS  = "[Administration] Load parameters",
    ADD_PARAMETER    = "[Administration] Add parameters",
    UPDATE_PARAMETER = "[Administration] Update parameters",
    DELETE_PARAMETER = "[Administration] Delete parameters",

    LOAD_TYPES_DOCUMENTS = "[Administration] Load types documents",
    LOAD_ACTIVE_DOUCUMENT_TYPES = "[Administration] Load active document types",
    ADD_TYPE_DOCUMENT    = "[Administration] Add type document",
    UPDATE_TYPE_DOCUMENT = "[Administration] Update type document",
    DELETE_TYPE_DOCUMENT = "[Administration] Delete type document",
    SET_CURRENT_DOCUMENT_TYPE = "[Administration] Set current document type",
    CLEAN_DOCUMENT_TYPES = "[Administration] Clean types documents",
    CLEAN_MODELS = "[Administration] Clean models",
    LOAD_MODELS = "[Administration] Load models",
    UPDATE_MODEL = "[Administration] Update model",
    LOAD_DATASETS = "[Administration] Load datasets",
    

    LOAD_METADATA   = "[Administration] Load metadata",
    ADD_METADATA    = "[Administration] Add metadata",
    UPDATE_METADATA = "[Administration] Update metadata",
    DELETE_METADATA = "[Administration] Delete metadata",

    LOAD_FOLDERS = "[Administraton] Load folders",
}