import { TableMyTasks } from "./components/TableMyTasks"


export const MyTasksView = () => {
    return (
        <div>
            <TableMyTasks />
        </div>
    )
}
