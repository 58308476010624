import { DocumentStatus } from '../constants/documentStatus';

export const formatStatusDocument = (status:number | string | undefined):string => {
    switch (status) {
        case DocumentStatus.ERROR:
            return "Error";
        case DocumentStatus.DRAFT:
            return "Borrador";
        case DocumentStatus.CLASSIFIED:
            return 'Clasificado';
        case DocumentStatus.UNCLASSIFIED:
            return "Sin clasiificar";
        case DocumentStatus.PROCESSING:
            return "Procesando";
        case DocumentStatus.PROCESSED:
            return "Procesado"
        case DocumentStatus.INDEXED:
            return "Indexado"
        case DocumentStatus.APPROVED:
            return "Aprobado"
        default:
            return ""
    }
}