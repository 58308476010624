import { IDocument } from '../../interfaces/documents/IDocument';
import { ActionsDashboard } from '../actions-interfaces/IActionsDashboard';
import { ActionTypesDashboard } from '../action-types/dashboardTypes';
import { ICardInfo } from '../../interfaces/dashboard/ICardInfo';
import { IBarsCount } from '../../interfaces/dashboard/IBarsCount';

export interface IDashboardState {
    lastDocuments: IDocument[];
    recentActivities: IDocument[];
    cardInfo:ICardInfo | null;
    barsCount:IBarsCount[] ;
    mostViewed:IDocument[];
}
const initialState:IDashboardState = {
    lastDocuments:[],
    recentActivities:[],
    cardInfo:null,
    barsCount:[],
    mostViewed:[]
}
// TODO: cambiar las action ui por load user
const dashboardReducer = (state:IDashboardState=initialState,action:ActionsDashboard) => {
    switch (action.type) {
        case ActionTypesDashboard.LOAD_LAST_DOCUMENTS:
            return {
                ...state,
                lastDocuments:[...action.payload]
            }
        case ActionTypesDashboard.LOAD_RECENT_ACTIVITIES:
            return {
                ...state,
                recentActivities:[...action.payload]
            }
        case ActionTypesDashboard.LOAD_CARD_COUNT:
            return {
                ...state,
                cardInfo:{...action.payload}
            }
        case ActionTypesDashboard.LOAD_BARS_COUNT:
            return {
                ...state,
                barsCount:[...action.payload]
            }
        case ActionTypesDashboard.LOAD_MOST_VIEWED:
            return {
                ...state,
                mostViewed:[...action.payload]
            }
        case ActionTypesDashboard.CLEAN_DASHBOARD:
            return initialState;
        
        default:
            return state;
    }
}
export default dashboardReducer;