
import MaterialTable, { Column } from '@material-table/core'
import { localizationMaterialTable } from '../../../../constants/materialTableConfig'
import { IBatch } from '../../../../interfaces/batch/Ibatch'
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentBatch, startLoadingBatchs } from '../../../../state/actions-creators/batch';
import { RootState } from '../../../../state/reducers/index';
import { useEffect, useState } from 'react';
import { formatStatusBatch } from '../../../../utils/formatStatusBatch';
import { TablePagination } from '@mui/material';
import moment from 'moment-timezone';


export const TableBatchs = ({loading}:{loading:boolean}) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const {batch:{batchs,count}} = useSelector((state:RootState) => state.batch)
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const columns:Column<IBatch>[]  = [
        {
            title:"ID",
            field:"id",
            type:"numeric",
            align:"left",
            editable:"never",
            filtering: false
        },
        {
            title:"Espacio",
            field:"parent_path",
            type:"string",
            filtering: false
        },
        {
            title:"Nombre",
            field:"name", 
            type:"string",
        },
        {
            title:"Estado",
            field:"status",
            type:"string",
            lookup:{
                "Pendiente": "Pendiente",
                "Procesado": "Procesado",
                "Procesando": "Procesando",
                "Parcial": "Parcial",
            }
        },
        {
            title:"Creación",
            field:"create_date",
            type:"date",
            filtering: false
        },
        {
            title:"Modificación",
            field:"write_date",
            type:"date",
            filtering: false
        },
        {
            title:"Tipo",
            field:"type_load",
            type:"string",
            filtering: false
        },
    ]

    const handleChangePage = (event:any,newPage:number) => {
        dispatch(startLoadingBatchs(1+ newPage))   
        setPage(newPage)
    }
    const handleChangeRowsPerPage = (event:any | null) => {
        setRowsPerPage(parseInt(event.target.value));
    };
    useEffect(() => {
        dispatch(startLoadingBatchs(1))
    }, [dispatch])

    return (
        <div>
            <MaterialTable 
                title={"Lotes"}
                columns={columns}
                style={{minWidth:"100%"}}
                data={batchs.map(batch => {
                    batch.status = formatStatusBatch(batch.status)
                    batch.create_date = moment(new Date(batch.create_date+"+00:00")).tz("America/Santiago").format("DD/MM/YYYY HH:mm")
                    batch.write_date = moment(new Date(batch.write_date+"+00:00")).tz("America/Santiago").format("DD/MM/YYYY HH:mm")
                    return batch
                })}
                localization={localizationMaterialTable}
                actions={[
                    {
                        icon: 'add',
                        onClick: (event: any, rowData) => {
                            navigate("/admin/lotes/crear-lote")
                        },
                        isFreeAction: true,
                        tooltip: 'Añadir',
                    },
                    {
                        icon: 'refresh',
                        tooltip: `Refrescar`,
                        isFreeAction: true,
                        onClick:()=> dispatch(startLoadingBatchs(1))
                    }
        
                ]}
                onRowClick={(_,rowData) => { 
                    if(rowData){
                        dispatch(setCurrentBatch(rowData))
                        navigate(`/admin/lotes/detalle-lote/${rowData.id}`)
                    }
                }}
                components={{
                    Pagination: props => (
                                <TablePagination
                                    {...props}
                                    rowsPerPageOptions={[10]}
                                    count={count}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
        
                                />
                    ),
                }}
                isLoading={loading}
                options={{
                    pageSizeOptions:[10],
                    search:false,
                    loadingType:'overlay',
                    pageSize:10,
                    actionsColumnIndex:-1,
                    filtering: true
                }}
            
            />
        </div>
    )
}
