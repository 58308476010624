import { ActionTypeUi } from "../action-types"



export const startLoading = () =>({
    type:ActionTypeUi.START_LOADING
})
export const finishLoading = () => ({
    type:ActionTypeUi.FINISH_LOADING
})
export const stratLoadingList = () => ({
    type:ActionTypeUi.START_LOADING_LIST
})
export const finishLoadingList = () => ({
    type:ActionTypeUi.FINISH_LOADING_LIST
})
export const startLoadingDocument = () => ({
    type:ActionTypeUi.START_LOADING_DOCUMENT
})
export const finishLoadingDocument = () => ({
    type:ActionTypeUi.FINISH_LOADING_DOCUMENT
})
export const startLoadingMetadata = () => ({
    type:ActionTypeUi.START_LOADING_METADATA
})
export const finishLoadingMetadata = () => ({
    type:ActionTypeUi.FINISH_LOADING_METADATA
})