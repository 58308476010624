
import { CustomAccordion } from '../../../../commons/accordion/CustomAccordion';
import { AccordionSummary, Typography, AccordionDetails, Button } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { Form, Formik } from 'formik';
import { MyTextField } from '../../../MyTextField';
import { FieldSelectParentFolder } from './FieldSelectParentFolder';
import * as Yup from 'yup'
import { useDispatch } from 'react-redux';
import { startAddFolder } from '../../../../../state/actions-creators/administration';
import { IFolder } from '../../../../../interfaces/documents/IFolders';

interface Props {
    selected:string;
}
export const FormCreateFolder = ({selected}:Props) => {
    const dispatch = useDispatch()



    return (
        <div className="components-field__folder-accordion">
        <CustomAccordion TransitionProps={{unmountOnExit:true}} >
            <AccordionSummary
            expandIcon={<FontAwesomeIcon   icon={faCaretDown} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            >
            <Typography>Crear carpeta</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Formik
                    initialValues={{
                        name: "",
                        parent_id:Boolean(selected) ? selected.split(":")[0]: 0,
                        parent_name:"Raíz",
                        
                    }}
                    validationSchema={Yup.object({
                        name: Yup.string().required('El nombre es requerido'),
                        parent_name:Yup.string()
                    })}
                    onSubmit={(values) => {
                        dispatch(startAddFolder(values as unknown as IFolder)) 
                    }}

                >
                    {
                        ({isValid}) =>(
                        <Form className="components-field__folder-accordion-content">
                            <div className="components-field__folder-accordion-input">
                                <MyTextField 
                                    name="name"
                                    label="Nombre"
                                />
                            </div>
                            <div className="components-field__folder-accordion-input">
                            
                                <FieldSelectParentFolder selected={selected}/> 
                            </div>
                            <div className="components-field__folder-accordion-input">
                                <Button disabled={!isValid} variant="outlined" type="submit">Crear</Button>
                            </div>
                        </Form>
                            
                        )
                    }
                </Formik>
            </AccordionDetails>
        </CustomAccordion>
    </div>
    )
}
