import React from 'react'


import { Table, TableContainer, TableHead, TableRow,TableCell,TableBody } from "@mui/material";

interface PropsTableDetial {
    columnNames:string[];
    children:React.ReactElement<typeof TableRow>[] | React.ReactElement<typeof TableRow>
}
export const TableDetail = ({children,columnNames}:PropsTableDetial) => {

    return (
        <TableContainer className="tables__table-detail-container" >
                <Table className="tables__table-detail">
                    <TableHead className="tables__table-detail-head">
                        <TableRow>
                            {
                                columnNames.map((column,index) => (
                                    <TableCell 
                                        key={index} 
                                        className="tables__table-detail-head-cell" 
                                        align={index !==0 ? 'right':undefined} >
                                        {column}
                                    </TableCell>
                                ))
                            }
                            
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {children}
                    </TableBody>
                </Table>
        </TableContainer>
    )
}
