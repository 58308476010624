import React from 'react'

import { SkeletonShape } from '../../skeletons/SkeletonShape';
import { SkeletonArray } from '../../skeletons/SkeletonArray';
interface Props{

    smallForm?:boolean;
}
export const GenerateFieldsSkeleton = ({smallForm}:Props) => {
    return (
        <div className="metadata__container-metadata">
            
            <SkeletonArray rows={8}>
                <div  className={smallForm ?   `metadata__input-skeleton-small`: `metadata__input-skeleton`}>
                        <SkeletonShape 
                            height={24} 
                            margin={5} 
                        />
                </div>
           </SkeletonArray>
        </div>
    )
}
