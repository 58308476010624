import { CardWithList } from "./components/CardWithList"
import { faFile, faListAlt, faUsers } from '@fortawesome/free-solid-svg-icons';
import { CardStatistics } from "../../../components/cards";
import { BarChartDocuments } from "./components/BarChartDocuments";
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { startLoadingRecentActivities, cleanDashboard, startLoadingLastDocuments, starLoadingCardInfo, startLoadingBarsCount, startLoadingMostViewed } from '../../../state/actions-creators/dashboard';
import { RootState } from '../../../state/reducers/index';

export const DashboardView = () => {
    const dispatch = useDispatch()
    const {recentActivities,lastDocuments,cardInfo,barsCount,mostViewed} = useSelector((state:RootState) => state.dashboard)
    const {loadingList} = useSelector((state:RootState) => state.ui)
    useEffect(() => {
        dispatch(startLoadingRecentActivities())
        dispatch(startLoadingLastDocuments())
        dispatch(starLoadingCardInfo())
        dispatch(startLoadingBarsCount())
        dispatch(startLoadingMostViewed())
        return () => {
            dispatch(cleanDashboard())
        }
    }, [dispatch])
    return (
        <div className="dashboard__container">
            <div className="dashboard__container-last-documents-and-cards">
                <CardWithList title="Mis últimos documentos" 
                    icon={faFile} 
                    documents={lastDocuments} 
                    loading={loadingList}
                    showName={false}
                    />
                <div className="dashboard__container-cards">
                    <CardStatistics 
                        icon={faFile}
                        title="Total documentos subidos por mí"
                        value={cardInfo?.my_doc}
                        color="#E91E63"
                    />
                    <CardStatistics 
                        icon={faUsers}
                        title="Total usuarios"
                        value={cardInfo?.all_users}
                        color="#00BCD4"
                    />
                    <CardStatistics 
                        icon={faFile}
                        title="Total documentos"
                        value={cardInfo?.all_doc}
                    />
            
                </div>
                <div className="dashboard__container-section-bar-chart">
                    <div className="dashboard__container-bar-chart">

                        <div className="dashboard__title-bar-chart">
                            <h4>Tipos de documentos</h4>
                            <hr  className="base__hr-right-ease" style={{marginBottom:"20px"}} />
                        </div>
                        <BarChartDocuments barsCount={barsCount} />
                    </div>
                </div>
        
            </div>

            <div className="dashboard__container-side-right">
                <CardWithList 
                    title="Actividades recientes" 
                    icon={faListAlt} 
                    documents={recentActivities} 
                    loading={loadingList}
                    />
                <CardWithList title="Documentos más vistos" showName={false} loading={loadingList} icon={faListAlt} documents={mostViewed} />
            </div>
        </div>
    )
}
