
import { ActionTypesAdministration } from '../../../../../state/action-types/administrationTypes';
import { cleanDocumentType } from '../../../../../state/actions-creators';
import { Column } from '@material-table/core'
import { getDataSetsService } from '../../../../../services/entity/datasetsServicest';
import { IDataset } from '../../../../../interfaces/entity/IDataset';
import { localizationMaterialTable } from '../../../../../constants/materialTableConfig';
import { RootState } from '../../../../../state/reducers/index';
import { useEffect, useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import MaterialTable from '@material-table/core';
import Swal from 'sweetalert2';
import { ModalDefault } from '../../../../../components/modals/modal-default/ModalDefault';
import { TableModels } from './TableModels';
import moment from 'moment-timezone';


export const TableDatasets = ({loading}:{loading:boolean}) => {
    const { administration:{
                            typesDocuments:{
                                datasets
                            }
                        },
            parameter:{
                general:{
                    projectId:paramProjectId
                }
            }
                    } = useSelector((state:RootState) => state)
    const dispatch = useDispatch()
    const [modal, setModal] = useState(false)
    const [currentDataset, setCurrentDataset] = useState<IDataset>()
    const [projectId, setProjectId] = useState('')
    const columns:Column<IDataset>[] = [{
            title:"ID",
            field:"dataset_id",
            type:"numeric",
            align:"left",
            editable:"never"
        },
        {
            title:"Nombre",
            field:"dataset_display_name", 
            type:"string"
        },
        {
            title:"Creación",
            field:"dataset_create_time", 
            type:"string"
        },
    ]
    
    const showModal = useCallback(
        () => {
            Swal.fire({
                title: 'Ingresa el id de proyecto',
                input: 'text',
                inputValue: paramProjectId !== null ? paramProjectId : '',
                inputAttributes: {
                    autocapitalize: 'off',
                },
                showCancelButton: true,
                confirmButtonText: 'Enviar',
                showLoaderOnConfirm: true,
                cancelButtonText: 'Cancelar',
                backdrop:true,
                preConfirm: (project_id) => {
                    setProjectId(project_id)
                    return getDataSetsService(project_id)
                        .then(response => {
                        if (response.status !== 200) {
                        throw new Error("Verifique el id del proyecto")
                        }
                        return response.data
                        

                    })
                    .catch(error => {
                        Swal.showValidationMessage(
                        ` Verifique el id del proyecto`
                    )
                    })
                    // getModelsService(project_id,currentDataset!.dataset_id)
                    //     .then(response => {
                           
                    //         if (response.status !== 200) {
                    //         throw new Error("Verifique el id del proyecto")
                    //         }
                    //         return response.data
                    //     })
                   
                },
                allowOutsideClick: () => !Swal.isLoading()
                }).then((result) => {
                    if (result.isConfirmed) {
                        
                        dispatch({
                            type: ActionTypesAdministration.LOAD_DATASETS,
                            payload: result.value
                        })
                    }
            })
        },
        [dispatch,paramProjectId],
    )

    useEffect(() => {
        showModal()
        return () => {
            dispatch(cleanDocumentType())
        }
    }, [showModal,dispatch])


    return (
        <>
            <MaterialTable 
                    title={"Datasets"}
                    columns={columns}
                    style={{minWidth:"100%"}}
                    data={datasets.map((dataset)=>{
                        dataset.dataset_create_time = moment(new Date(dataset.dataset_create_time+"+00:00")).tz("America/Santiago").format("DD/MM/YYYY HH:mm")
                        return dataset
                    })}
                    localization={localizationMaterialTable}
                    actions={[
                        {
                            icon: 'add',
                            onClick: (event: any, _) => {
                                showModal()
                            },
                            isFreeAction: true,
                            tooltip: 'Añadir',
                        }
                    ]}
                    onRowClick={(_,rowData) => { 
                        setModal(true)
                        setCurrentDataset(rowData)
                    }}
                    isLoading={loading}
                    options={{
                        pageSizeOptions:[10],
                        search:false,
                        loadingType:'overlay',
                        pageSize:10,
                        actionsColumnIndex:-1,
                        filtering: false
                    }}
                
                />
            {modal && currentDataset &&  
                <ModalDefault title={`Modelos de  ${currentDataset?.dataset_display_name}`}  open={modal} setModal={setModal} >
                    <div className="administration__content-modal-list-detail">
                        <TableModels projectId={projectId}  dataset={currentDataset} loading={loading} />
                    </div>
                </ModalDefault>
            }
        </>
    )
}
