interface Props{
    width?: string;
    height?: number;
    margin?: number;
    circle?:boolean;
}
export const SkeletonShape = ({height,margin,width,circle=false}:Props) => {
    return (
        <div 
            style={{
                ...(Boolean(height) && {height: `${height}px`}),
                ...(Boolean(width) && {width: `${width}`}),
                ...(Boolean(margin) && {margin: `${margin}px`}),
                ...(circle && {borderRadius: `50%`}),
            }}
            className="skeleton skeleton__shape"
        /> 

    )
}
