import { Button, Checkbox, FormControlLabel } from '@mui/material';
import { Form, Formik } from 'formik';
import { FormMetadata } from './FormMetadata';
import { MyAutoCompleteUsersField, MyCheckBoxField, MyTextFieldMultiline } from '../../../../../components/fields';
import { PreviewDocument } from '../../../../../components/commons/PreviewDocument';
import { RootState } from '../../../../../state/reducers/index';
import {  useParams, useNavigate } from 'react-router';
import { startLoadingDetailDocument, startLoadingMetadataFields, cleanCurrentDocument, startCheckingDocumentPending } from '../../../../../state/actions-creators/document';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState, ChangeEvent } from 'react';
import * as Yup from 'yup';
import { startAddTask } from '../../../../../state/actions-creators/task';
import {  ITaskForm } from '../../../../../interfaces/tasks/ITask';
import { MyDeriveDateField } from '../../../../../components/fields/custom-fields/MyDeriveDateField';
import { Card } from '../../../../../components/cards';
import { DetailFeatures } from './DetailFeatures';

export const DetailDocumentView = () => {
    const dispatch = useDispatch()
    const [derive, setDerive] = useState(false);
    const { document,checkingPending } = useSelector((state:RootState) => state.document.currentDocument)
    const  navigate = useNavigate()
    const { loadingDocument,loadingMetadata,loading } = useSelector((state:RootState) => state.ui)

    const params = useParams()
    const id = params.id as string
    
    useEffect(() => {

        dispatch(startLoadingDetailDocument(id))
        dispatch(startLoadingMetadataFields(id))
        dispatch(startCheckingDocumentPending(id))
        return () =>{
            dispatch(cleanCurrentDocument())
        }
    }, [dispatch,id])
    

    const handleDerive = (e:ChangeEvent<HTMLInputElement>) => {
        setDerive(e.target.checked);
    }

        return (
            <div className="my-documents__container-details-document"> 
                <div className="my-documents__details-title">
                    <h3 id="my-documents__title">Detalle documento {document?.name}</h3>
                </div>
                <DetailFeatures 
                    document={document}
                />
                <Card className="my-documents__details-document-container">            
                {  (loadingDocument === false)?  <PreviewDocument zoom="80" urlFile={`${process.env.REACT_APP_URL_CONTENT}documents/${document?.uuid}/download`} /> : <div>Cargando...</div> }
                </Card>
                <div className="my-documents__details-document-metadata">
                    <FormMetadata  id={id} loading={loadingMetadata}  document={document} /> 
                    <Card title="Flujo" flexDirection="column" styles={{marginTop:10}}>
                        <Formik
                            initialValues={{
                                user_id_to:"",
                                required_date:"",
                                comment:"",
                                namespace:"document",
                                primary_key:id,
                                request_signature:false
                
                            }}
                            validationSchema={Yup.object({
                                user_id_to: Yup.string().required("El usuario es requerido"),
                                required_date: Yup.string().required("La fecha es requerida"),
                                comment: Yup.string().required("El comentario es requerido"),
                            })}
                            onSubmit={(values,{resetForm}) => {
            
                                const resetAllForm = () =>{
                                    setDerive(false)
                                    resetForm()
                                }
                                dispatch(startAddTask(values as ITaskForm,resetAllForm,navigate))
                            }}
                        >
                            {
                            (formik) =>(
                                <Form>
    
                                    <div className="my-documents__container-flow">
                                        <div className="my-documents__container-flow-check">
                                    {checkingPending === false ?
                                    <>
                                        <FormControlLabel
                                            control={<Checkbox checked={derive} onChange={handleDerive} />}
                                            label="Derivar a:"
                                            />
                                        <MyCheckBoxField label="Firmar?" name="request_signature" />
                                    </>
                                    :
                                    "Este documento tiene tareas pendientes"
                                    }
                                        
                                        </div>
                                    <div className="my-documents__container-flow-inputs">
                                            <div className="my-documents__container-flow-input">
                                                <div className="my-documents__flow-input">
    
                                                    <MyAutoCompleteUsersField 
                                                        size="small"
                                                        disabled={!derive}
                                                        label="Usuario" 
                                                        name="user_id_to"
                                                    />
                                                </div>
                                                <div className="my-documents__flow-input">
                                                    <MyDeriveDateField
                                                        disabled={!derive}
                                                        label="Vencimiento" 
                                                        name="required_date"
                                                    />
                                                </div>
                                            </div>
                                            <div className="my-documents__container-flow-input">
                                            <MyTextFieldMultiline disabled={!derive} label="Observación" name="comment" />
                                            </div>
                                            
                                    </div>
                                    <Button id="my-documents__flow-button"  disabled={!derive || loading} size="large"  variant="outlined" color="primary" type="submit">Derivar</Button>
                                    </div>
                                </Form>
                            ) 
                            }
                        </Formik>
                    </Card>
                </div>
            </div>
        )
}
