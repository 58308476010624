import { useSelector } from 'react-redux';
import { RootState } from '../../../../state/reducers';
import { TableOrganizations } from './TableOrganizations';

export const OrganizationsView = () => {
    const {loadingList} = useSelector((state:RootState) => state.ui)
    return (
        <div>
            <TableOrganizations loading={loadingList} />
        </div>
    )
}
