import { Backdrop, Button, Fade, Modal } from '@mui/material';
import { PreviewDocument } from '../../commons/PreviewDocument';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {  cleanCurrentDocument } from '../../../state/actions-creators/document';
import { Form, Formik } from 'formik';
import { MyPasswordField } from '../../fields/MyPasswordField';
import * as Yup from 'yup'
import { startSignDocument } from '../../../state/actions-creators/task';
import { ISignatureForm } from '../../../interfaces/documents/ISignature';
interface PropsModalPreivewDocument {
    open:boolean;
    setModal:(modal:boolean) => void;
    children?:JSX.Element | JSX.Element[],
    urlFile:string | undefined,
    uuidDocument:string;
    taskId:number;
}

export const ModalSignature = ({open,setModal,uuidDocument,urlFile,taskId}:PropsModalPreivewDocument) => {
    const dispatch = useDispatch()
    
    const handleClose = () => {
        setModal(false);
    };
    useEffect(() => {

        return () => {
            dispatch(cleanCurrentDocument())
        }
    }, [dispatch,uuidDocument])
    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className="components-modal__modal-signature-document "
                open={open}
                disableEscapeKeyDown={true}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
            <Fade in={open}>
            <div className="components-modal__modal-signature-document-container ">
                <span onClick={handleClose} className="components-modal__modal-signature-document-close">X</span>
                <div className="components-modal__modal-signature-document-title">
                    <h4>Firma de documento</h4>
                </div>
                <div className="components-modal__modal-signature-content">

                    <div className="components-modal__modal-signature-document-item">
                        <PreviewDocument  zoom='75' urlFile={urlFile} />
                    </div>
                    <div className="components-modal__modal-signature-document-item">
                            <div className="components-modal__modal-signature-document-title">
                                <h3>Por favor firme el documento:</h3>
                                <hr  style={{width:"90%"}} className='base__hr-ease' />
                            </div>
                
                            <Formik
                                initialValues={{
                                    security_key:'',
                                    task_id:taskId,
                                    uuidDocument:uuidDocument,
                                }}
                                validationSchema={Yup.object({
                                    security_key: Yup.string().required('Este campo es requerido'),
                                })}
                                onSubmit={(values:ISignatureForm,{resetForm}) =>{
                                    dispatch(startSignDocument(values,resetForm,setModal))
                                }}

                            >
                                {
                                    (formik) =>(
                                        <Form
                                            className='components-modal__modal-signature-document-item-form'
                                        >
                                            <div className="components-modal_modal-signature-document-item-form-input">

                                                <MyPasswordField 
                                                    size="medium"
                                                    label="Firma"
                                                    name="security_key"
                                                />
                                            </div>
                                            <div className="components-modal_modal-signature-document-item-form-input">

                                                <Button type='submit' variant='contained'>Firmar</Button>
                                            </div>
                                        </Form>
                                    )
                                }
                            </Formik>
                    </div>
                </div>
                <div className="components-modal__modal-signature-document-buttons" >
                <Button onClick={handleClose}   color="primary" type="button"  >
                    Cerrar
                </Button>
                </div>
                {/* <p id="transition-modal-description">react-transition-group animates me.</p> */}        
            </div>
            </Fade>
        </Modal>
        </div>
    )
}
