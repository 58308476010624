import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolderOpen, faFolderPlus, faFolder } from '@fortawesome/free-solid-svg-icons';
import { IAdministrationState } from '../../../../state/reducers/administrationReducer';
import { RootState } from '../../../../state/reducers/index';
import { useSelector, useDispatch } from 'react-redux';
import { SyntheticEvent } from 'react';
import { TreeView } from '@mui/lab';
import { MyCustomTreeItem } from './components/MyCustomTreeItem';
import { startLoadingFolders } from '../../../../state/actions-creators/administration';
interface Props{
    handleSelect:(event: SyntheticEvent, nodeIds: string) => void;
    folderId?:number | null | undefined | string ;
    selected:string;
}
export const FolderTree = ({selected,handleSelect,folderId}:Props) => {
    const { folders }:IAdministrationState = useSelector((state:RootState) => state.administration)
    const dispatch = useDispatch()
    const [expanded, setExpanded] = useState<string[]>([]);
    const handleToggle = (event: SyntheticEvent, nodeIds: string[]) => {
        setExpanded(nodeIds);
    };   
    useEffect(() => {
        if(folderId){
            const folderParsed = typeof folderId === 'string' ? parseInt(folderId) : folderId
            dispatch(startLoadingFolders(folderParsed))
        }else{
            dispatch(startLoadingFolders())
        }
    }, [dispatch,folderId])
    return (
        <TreeView
            aria-label="file system navigator"
            defaultCollapseIcon={<FontAwesomeIcon icon={faFolderOpen} />}
            defaultExpandIcon={<FontAwesomeIcon icon={faFolderPlus} />}
            defaultEndIcon={<FontAwesomeIcon icon={faFolder} />}
            expanded={expanded}
            selected={selected}
            onNodeToggle={handleToggle}
            onNodeSelect={handleSelect}
            className="components-field__folder-tree"
            // sx={{ height: 240, flexGrow: 1, maxWidth: 400, overflowY: 'auto', overflowX: 'hidden' }}
            >
            { 

            folders.map(folder =>(
                        <MyCustomTreeItem 
                            key={folder.id}
                            disabled={false}
                            folder={folder} 
                        />
                    ))
                }
        </TreeView>
    )
}
