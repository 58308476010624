
import { TabsDocumentTypes } from './components/TabsDocumentTypes';

export const TypesDocumentsView = () => {

    return (
        <div>
            <TabsDocumentTypes /> 
        </div>
    )
}
