import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { startLoadingProfileInformation } from '../../../state/actions-creators/auth'

import { RootState } from '../../../state/reducers'
import { ProfileInformation } from './components/ProfileInformation'

export const ProfileView = () => {
    const dispatch = useDispatch()
    const { tokenInfo } = useSelector((state: RootState) => state.auth)
    const { profile } = useSelector((state: RootState) => state.auth)

    useEffect(() => {
        dispatch(startLoadingProfileInformation(tokenInfo?.sub))

    }, [dispatch, tokenInfo?.sub])

    return (
        <>
            <ProfileInformation  profile={profile}/>
        </>
    )
}
