import { MenuItem, Button } from '@mui/material';
import { Form, Formik } from 'formik';
import { MyCheckBoxField, MySelectField } from '../../../../../components/fields';
import { MyTextField } from '../../../../../components/fields/MyTextField';
import { IDataset } from '../../../../../interfaces/entity/IDataset';
import { IModel } from '../../../../../interfaces/entity/IModel';
import * as Yup from 'yup';
import { IDocumentType } from '../../../../../interfaces/maintainers/IDocumentType';
import { useEntityManager } from '../../../../../hooks/useEntityManager';
import { startAddDocumentType } from '../../../../../state/actions-creators';
import { useDispatch, useSelector } from 'react-redux';
import { startUpdateDocumentType, startDeleteDocumentType } from '../../../../../state/actions-creators/administration';
import { useNavigate } from 'react-router';
import { RootState } from '../../../../../state/reducers/index';

interface PropsFormDocumentType {
    documentType?:IDocumentType | null;
}

export const FormDocumentType = ({documentType}:PropsFormDocumentType) => {
    const dispatch = useDispatch()

    const {loading} = useSelector((state:RootState) => state.ui)
    const {datasets,models,getDatasets,getModels,loadingEntity} = useEntityManager(documentType)
    const navigate = useNavigate()
    const initialState = {
        name:'',
        project_id:'',
        model_id:'',
        dataset_id:'',
        display_name:'',
        active:false,
        service_ml:'',
        
    }

    return (
        <Formik
            initialValues={documentType !== undefined && documentType !== null ? documentType : initialState}
            validationSchema={Yup.object({
                name: Yup.string().required('El nombre es requerido'),
                project_id: Yup.string().required('El proyecto es requerido'),
                service_ml: Yup.string().required('El campo servicio es requerido'),
                dataset_id: Yup.string().when(
                    'service_ml',{
                        is:"AutoML",
                        then:Yup.string().required('El campo dataset es requerido'),
                    }
                ),
                model_id: Yup.string().when(
                    'service_ml',{
                        is:"AutoML",
                        then:Yup.string().required('El campo modelo es requerido'),
                    }
                ),
                display_name: Yup.string().required('El campo es requerido'),
            })}
            // key_list: Yup.string().when('type', {
            //     is:"select",
            //     then:Yup.string().required("Campo key_list requerido"),
            // }),
            onSubmit={(values) => {
                if(documentType !== undefined && documentType !== null){
                    dispatch(startUpdateDocumentType(values as IDocumentType))
                }else{

                    dispatch(startAddDocumentType(values as IDocumentType,navigate))
                }
            }}
            > 
            {
                ({values,isValid}) => (
                    <Form className="document-type__form">
                        <div className="document-type__form-container-inputs">
                            <div className="document-type__form-container-input">
                                <MyTextField
                                    fullWidth
                                    size="small"
                                    name="name"
                                    label="Nombre tipo de documento"
                                    autoComplete="never"
                                />
                            </div>
                            <div className="document-type__form-container-input">
                                <MyTextField
                                    fullWidth
                                    size="small"
                                    name="display_name"
                                    label="Nombre a mostrar"
                                    autoComplete="never"
                                />
                            </div>
                    
                        </div>
                        <div className="document-type__form-container-inputs">
                            <div className="document-type__form-container-input">
                                <MyTextField
                                    fullWidth
                                    size="small"
                                    name="project_id"
                                    label="ID Proyecto"
                                    autoComplete="never"
                                    handleOnBlur={async (e)=>{
                                        await getDatasets(e.target.value)
                                        
                                    }}
                                />
                            </div>
                            <div className="document-type__form-container-input">
                                <MySelectField
                                        label="Servicio"
                                        name="service_ml"

                                    >
                                        <MenuItem   value="">
                                        <em>Seleccionar</em>
                                        </MenuItem>
                                        <MenuItem   value="AutoML">
                                        AutoML
                                        </MenuItem>
                                        <MenuItem   value="DocumentAI">
                                        DocumentAI
                                        </MenuItem>
                                    </MySelectField>
                            </div>
                            {values.service_ml ==="AutoML" &&
                            <div className="document-type__form-container-input">
                                <MySelectField
                                    label="Dataset"
                                    name="dataset_id"
                                    disabled={loadingEntity}
                                    handleOnBlur={async (e)=>{
                                        await getModels(values.project_id,e.target.value)
                                        
                                    }}
                                >
                                <MenuItem selected value="">
                                    <em>Seleccionar</em>
                                </MenuItem>
                                { datasets.length !== 0 ?
                                    datasets.map((dataset:IDataset)=>(
                                        <MenuItem key={dataset.dataset_id} value={dataset.dataset_id}>{dataset.dataset_display_name}</MenuItem>
                                    ))
                                    :
                                    <MenuItem selected disabled value={documentType !== null && documentType !== undefined ?  `${documentType.dataset_id}` : ``} >
                                        <em>Verifique el proyecto</em>
                                    </MenuItem>
                                }
                                
                                </MySelectField>

                            </div>
                            
                            }
                            <div className="document-type__form-container-input">
                                {values.service_ml ==="DocumentAI"?
                                <MyTextField  
                                    fullWidth
                                    size="small"
                                    name="model_id"
                                    label="Modelo"
                                    autoComplete="never"
                                />
                                :
                                <MySelectField
                                    label="Modelo"
                                    name="model_id"
                                    disabled={loadingEntity}
                                >
                                    <MenuItem selected  value="">
                                    <em>Seleccionar</em>
                                    </MenuItem>
                                    {
                                        models.length !== 0 ?
                                        models.map((model:IModel)=>(
                                            <MenuItem key={model.model_id} value={model.model_id}>{model.model_display_name}</MenuItem>
                                        ))
                                        :
                                        <MenuItem selected disabled value={documentType !== null && documentType !== undefined ?  `${documentType.model_id}` : ``}>
                                        <em>Verifique el proyecto o dataset</em>
                                        </MenuItem>
                                    }
                                </MySelectField>}
                            </div>
                            <div className="document-type__form-container-input">
                                <MyCheckBoxField label="Activo" name="active" />
                            </div>
                        
                        </div>
                        <div className="document-type__form-buttons-input">
                        {(documentType !== undefined && documentType !== null) &&
                            <Button color="error" type="button" onClick={()=>{
                                dispatch(startDeleteDocumentType(documentType,navigate))
                            }}  variant="contained">
                                Eliminar
                            </Button>
                            }
                            <Button disabled={isValid===false || loading} type="submit" variant="contained">
                                {(documentType !== undefined && documentType !== null) 
                                ?'Guardar'
                                :'Crear'
                                }
                            </Button>
                        </div>
                    </Form>
                )
            }
            </Formik>
    )
}
