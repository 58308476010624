

import { IMetadataField } from '../../interfaces/metadata/IMetadataField';
import { ActionsSearch } from '../actions-interfaces';
import { ActionTypesSearch } from '../action-types';

export interface ISearchState {
    searchFields: IMetadataField[];
}
const initialState:ISearchState = {
    searchFields:[],
}


const searchReducer = (state:ISearchState=initialState,action:ActionsSearch):ISearchState => {
    switch (action.type) {
        case ActionTypesSearch.LOAD_METADATA_FIELDS_SEARCH:
            return {
                ...state,
                searchFields:[...action.payload.metadata]
            }
        case ActionTypesSearch.CLEAN_SEARCH:
            return {
                ...state,
                searchFields:initialState.searchFields,

            }
        case ActionTypesSearch.UPDATE_METADTATA_FIELDS_SEARCH:
            return {
                ...state,
                searchFields:action.payload.metadata.map(metadata =>{

                    for (let [key, value] of Object.entries(action.payload.metadataObject)) {
                        if(metadata.name === key){
                            metadata.value = value
                        }
                    }
                
                    return metadata
                })
            }
        default:
            return state;
    }
}


export default searchReducer;