import { IMetadataField } from '../interfaces/metadata/IMetadataField';
import * as Yup from 'yup'

interface Props{
    metadataFields:IMetadataField[],
    extraFields?:{[x: string]: any};
}
export const useGenerateFields = ({metadataFields,extraFields}:Props) =>{
    const initialValues: { [key: string]: any } = {};
    const requiredFields: { [key: string]: any } = {}
    if(metadataFields.length !== 0){

        for (const input of metadataFields) {
            initialValues[ input.name ] = input.value;
        
            // if ( !input.validations ) continue;
            //     let schema = Yup.string()
                
            //     for (const rule of input.validations ) {
                    
            //         if ( rule.type === 'required' ) {
            //             schema = schema.required('Este campo es requerido');
            //         }
            
            //         if ( rule.type === 'minLength' ) {
            //             schema = schema.min( (rule as any).value || 2, `Mínimo de ${ (rule as any).value || 2 } caracteres`);
            //         }
            
            //         if ( rule.type === 'email' ) {
            //             schema = schema.email( `Revise el formato del email`);
            //         }
            
            //         // ... otras reglas
            // }
        
            // requiredFields[input.name] = schema;
        }
    }

    const validationSchema = Yup.object({ ...requiredFields, ...extraFields });
    return {initialValues,validationSchema}
}