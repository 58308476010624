import { getDataSetsService } from "../services/entity/datasetsServicest";
import { getModelsService } from "../services/entity/modelsServices";
import { useState, useEffect } from 'react';
import { IDataset } from '../interfaces/entity/IDataset';
import { IModel } from '../interfaces/entity/IModel';
import { IDocumentType } from '../interfaces/maintainers/IDocumentType';

export const useEntityManager = (documentType:IDocumentType | undefined | null) => {
    const [datasets, setDatasets] = useState<IDataset[]>([]);
    const [models, setModels] = useState<IModel[]>([]);
    const [loadingEntity, setLoadingEntity] = useState(false)

    const getDatasets = async (project_id:string) => {
        try {
            setLoadingEntity(true)
            const resp = await getDataSetsService(project_id)
            setLoadingEntity(false)
            if(resp.status === 200){
                setDatasets(resp.data)
            }else{
                setDatasets([])            
            }
        } catch (error) {
            setDatasets([])
            console.log(error);
            setLoadingEntity(false)
        }
    }
    const getModels = async (project_id:string,dataset_id:string) => {
        try {
            setLoadingEntity(true)
            const resp = await getModelsService(project_id,dataset_id)
            setLoadingEntity(false)
            if(resp.status === 200){
                setModels(resp.data)
            }else{
                setModels([])
            }
        } catch (error) {
            setModels([])
            console.log(error);
            setLoadingEntity(false)
        }
    }
    useEffect(() => {
        const getDataSetsAndModels = async (documentType:IDocumentType) => {
            await getDatasets(documentType.project_id)
            await getModels(documentType.project_id,documentType.dataset_id)
        }
        if(documentType !== null && documentType !== undefined){
            getDataSetsAndModels(documentType)
        }
        return () => {
            setModels([])
            setDatasets([])
        }
    }, [documentType])

    return {datasets,models,getDatasets,getModels,loadingEntity}
}