import { stratLoadingList, finishLoadingList } from './ui';
import { ActionsComment } from '../actions-interfaces/IActionsComment';
import { ActionsUi } from '../actions-interfaces/IActionsUi';
import { Dispatch } from 'redux';
import { getCommentsByNamspacePrimaryKey } from '../../services/comments/commentsServices';
import { ActionTypesComment } from '../action-types/commentTypes';
import { Toast } from '../../utils/sweetAlertMix';
export const startLoadingCommentsByNpPk= (idTask:string) =>  async (dispatch:Dispatch<ActionsComment | ActionsUi>) =>{
    try {
    
        dispatch(stratLoadingList())
        const resp = await getCommentsByNamspacePrimaryKey(idTask);
        dispatch(finishLoadingList())
        if(resp.status === 200){
            dispatch({
                type:ActionTypesComment.LOAD_COMMENTS,
                payload:resp.data
            })
        }
    } catch (error) {
        Toast.fire({icon:"error",title:`Algo salió mal`})
        dispatch(finishLoadingList())
        console.log(error)
    }
}
export const cleanComments = () => ({
    type:ActionTypesComment.CLEAN_COMMENTS
})