import { faEye, faFilePdf, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { CircularProgress, Tooltip } from "@mui/material"

interface PropsFileCharged{
    removeFile:(acceptedFile: File) => void,
    file:File,
    setModal?:(modal:boolean) => void;
    preview?:boolean;
    loading?:boolean;
}

export const FileCharged = ({removeFile,file,setModal,preview=true,loading = false}:PropsFileCharged) => {
    const onShowPreview = () =>{
        if(setModal){

            setModal(true)
        }
}
    return (

            <div  className="files__container-file-charged">
                <div className="files__file-charged-item">
                    {loading ?
                    <CircularProgress />
                    :
                    <FontAwesomeIcon size={'2x'} icon={faFilePdf} />
                    }
                    
                </div>
                <div className="files__file-charged-item">
                    <p>{file.name}</p>
                </div>
            
                <div className="files__file-charged-item">
                  { preview&& <Tooltip onClick={onShowPreview} title="Revisar"  placement="bottom" arrow>     
                            <div className="files__file-charged-item-icon">
                                <FontAwesomeIcon size="1x"  icon={faEye} />
                            </div>
                    </Tooltip>}
                    <Tooltip onClick={() => removeFile(file)}  title="Remover"  placement="bottom" arrow>
                
                        <div className="files__file-charged-item-icon">
                            <FontAwesomeIcon size="1x" icon={faTrash} />

                        </div>
                    </Tooltip>
                </div>
            </div>

    )
}
