import { TableBatchs } from "./components/TableBatchs"
import { useSelector } from 'react-redux';
import { RootState } from '../../../state/reducers/index';

export const BatchView = () => {
    const {loadingList} = useSelector((state:RootState) => state.ui)
    return (
        <div>
            <TableBatchs loading={loadingList} />
        </div>
    )
}
