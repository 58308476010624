import React from 'react'
import { useState, useEffect } from 'react';
import { RootState } from '../../../../state/reducers/index';
import { useSelector, useDispatch } from 'react-redux';
import { Tabs, Tab } from '@mui/material';
import { FormBatch } from './FormBatch';
import { TableDetailBatch } from './TableDetailBatch';

import { cleanCurrentBatch, startLoadingDetailBatch } from '../../../../state/actions-creators/batch';
import { IBatchState } from '../../../../state/reducers/batchReducer';
import { useParams } from 'react-router';
const a11yProps = (index:number) => {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,

    };
}

export const TabsBatch = () => {
    const params = useParams()
    const dispatch = useDispatch()
    const {loading} = useSelector((state:RootState) => state.ui)
    const {currentBatch:{batch}}:IBatchState = useSelector((state:RootState) => state.batch)
    const [tabValue, setTabValue] = useState<number>(0)
    const handleChangeTab = (_:React.ChangeEvent<{}>, newValue:any) => {
        setTabValue(newValue);
    };
    const id = params.id as string
    useEffect(() => {
        dispatch(startLoadingDetailBatch(parseInt(id)))
        return () => {
            dispatch(cleanCurrentBatch())
        }
    }, [dispatch,id])
    return (
        <>
        <div className="base__container-tabs ">
            <Tabs disabled={loading} indicatorColor="primary" variant="scrollable" onChange={handleChangeTab} scrollButtons="auto" value={tabValue} aria-label="simple tabs example">
                    <Tab className="base__tab" label="Lote" {...a11yProps(0)} />
                    <Tab className="base__tab" label="Detalle" {...a11yProps(1)} />
            </Tabs>
        </div>
        {
            tabValue === 0 && batch !== null ?
                <FormBatch id={id} isDetail={true} batchDetail={batch}  />
            :
            tabValue  === 1 && batch !== null ? 
                <TableDetailBatch id={id} />
            :
            <h5>loading</h5>
        }
        </>
    )
}
