
import {  FormControlLabel, Checkbox, FormControl, FormHelperText } from '@mui/material';
import { useEffect, useRef } from 'react';
import { useFieldFast } from '../../../hooks/useFastField';
interface Props  {
    label: string;
    name: string;
    type?: 'text' | 'email' | 'password';
    placeholder?: string;
    labelPlacement?: 'top' | 'bottom' | 'start' | 'end';
    searchParams:URLSearchParams;
    [x: string]: any;

}
export const MyCheckBoxSearchField = ({ label,labelPlacement,searchParams, ...props }: Props ) => {
    const [ field,metaProps,helpers] = useFieldFast(props)

    const { error,touched} = metaProps;
    const {setValue} = helpers;
    const signedExists = Boolean(searchParams.getAll("last_signed_date"))
    const refSigned = useRef(signedExists)
    useEffect(() => {
        if(signedExists && refSigned.current === signedExists){

            if(searchParams.get("last_signed_date") !== null){
                setValue(searchParams.get("last_signed_date") === 'true' ? true : false )    
            }
        }
    },[signedExists,searchParams,setValue])
    return (
        <FormControl>

            <FormControlLabel  labelPlacement={labelPlacement} label={label} control={
                <Checkbox 
                    {...field}
                    checked={field.value as boolean}
                    {...props}
                    color="primary" 
                    />}     />
                    {Boolean(error) && touched && <FormHelperText error={Boolean(error) && touched} >{error}</FormHelperText>}
        </FormControl>
    )
}
