import { axiosInstance } from "../../utils/axiosInstance";
import { IOrganization } from '../../interfaces/maintainers/IOrganization';

// TODO: Agregar token cuando exista auth
const getAllOrganizationsService = () =>{
    return axiosInstance.get(`${process.env.REACT_APP_URL_COMPANY}organizations`)
}
const getOrganizationsByCompanyService = (idCompany:number) =>{
    return axiosInstance.get(`${process.env.REACT_APP_URL_COMPANY}company/${idCompany}/organizations`)
}
const addOrganizationService = (data:IOrganization) =>{
    return axiosInstance.post(`${process.env.REACT_APP_URL_COMPANY}organizations`,data)
}
const updateOrganizationService = (idOrganization:number,data:IOrganization) => {
    return axiosInstance.put(`${process.env.REACT_APP_URL_COMPANY}organizations/${idOrganization}`,data)
}
const deleteOrganizationService = (idOrganization:number) => {
    return axiosInstance.delete(`${process.env.REACT_APP_URL_COMPANY}organizations/${idOrganization}`)
}
export {
    getAllOrganizationsService,
    addOrganizationService,
    updateOrganizationService,
    deleteOrganizationService,
    getOrganizationsByCompanyService
}