import React from 'react'
import { useState } from 'react';
import { Tabs, Tab } from '@mui/material';
import { Card } from '../../../../components/cards/Card';

import { RequestTab } from './RequestTab/RequestTab';
import { HistoryTab } from './HistoryTab/HistoryTab';
import { CommentsTab } from './CommentsTab/CommentsTab';
import { useParams } from 'react-router';
import { SignatureHistory } from '../../MyTasks/components/SignaturesHistory/SignatureHistory';

const a11yProps = (index:number) => {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,

    };
}
export const DetailRequestTabs = () => {
    // const {
    //     params:{id}
    // } = match;
    const params = useParams()
    const id = params.id as string;
    const [tabValue, setTabValue] = useState<number>(0)
    
    const handleChangeTab = (_:React.ChangeEvent<{}>, newValue:any) => {
        setTabValue(newValue);
    };
    
  
    
    return (
        <>
        <div className="task__container-tabs ">
            <Tabs disabled indicatorColor="primary" variant="scrollable" onChange={handleChangeTab} scrollButtons="auto" value={tabValue} aria-label="simple tabs example">
                    <Tab className="task__tab" label="Solicitud" {...a11yProps(0)} />
                    <Tab className="task__tab" label="Historia" {...a11yProps(1)} />
                    <Tab className="task__tab" label="Firmas" {...a11yProps(2)} />
                    <Tab className="task__tab" label="Comentarios" {...a11yProps(3)} />
            </Tabs>
        </div>
        {
            tabValue === 0 ?
            <Card className="task__tab-task-container" flexDirection="column">
                <RequestTab  id={id} />
                
            </Card>
            : tabValue ===1 ?
            <HistoryTab id={id} />
            : tabValue === 2 ?
            <SignatureHistory id={id} />
            : tabValue ===3 ?
            <CommentsTab id={id} />
            :
            // FIXME: insertar un loading decente
            <h5>loading</h5>
        }
        </>
    )
}
