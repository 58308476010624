import React from 'react'
import { TaskComment } from '../../../../../components/commons/tabs-tasks/TaskComment';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { cleanComments, startLoadingCommentsByNpPk } from '../../../../../state/actions-creators/comments';
import { RootState } from '../../../../../state/reducers/index';
interface Props {
    id:string
}
export const CommentsTab = ({id}:Props) => {
    const dispatch = useDispatch()
    const {loadingList} = useSelector((state:RootState) => state.ui)
    const {comments} = useSelector((state:RootState) => state.comment)
    useEffect(() => {
        dispatch(startLoadingCommentsByNpPk(id))
        return () => {
            dispatch(cleanComments())
        }
    }, [dispatch,id])
    return (
        <TaskComment loading={loadingList} comments={comments} />
    )
}
