import { axiosInstance } from "../../utils/axiosInstance";
import {  IDocument } from '../../interfaces/documents/IDocument';
import { ICardInfo } from '../../interfaces/dashboard/ICardInfo';
import { IBarsCount } from '../../interfaces/dashboard/IBarsCount';


const getLastDocumentsService= () =>{
    return axiosInstance.get<IDocument[]>(`${process.env.REACT_APP_URL_CONTENT}documents/dashboard/by_users`)
}
const getRecentActivitesService = () =>{
    return axiosInstance.get<IDocument[]>(`${process.env.REACT_APP_URL_CONTENT}documents/dashboard`)
}
const getCardInfoService = () =>{
    return axiosInstance.get<ICardInfo>(`${process.env.REACT_APP_URL_CONTENT}documents/dashboard/counts`)
}
const getBarsCountService = () =>{
    return axiosInstance.get<IBarsCount[]>(`${process.env.REACT_APP_URL_CONTENT}documents/dashboard/bars`)
}
const getMostViewedService = () =>{
    return axiosInstance.get<IDocument[]>(`${process.env.REACT_APP_URL_CONTENT}documents/dashboard/mostViewed`)
}


export {
    getLastDocumentsService,
    getRecentActivitesService,
    getCardInfoService,
    getBarsCountService,
    getMostViewedService
}