import React from 'react'
import { TabsSignatureHistory } from '../../../../../components/commons/tabs-tasks/TabsSignatureHistory';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../../../state/reducers/index';
import { useEffect } from 'react';
import { startLoadingSignatures } from '../../../../../state/actions-creators/document';
interface Props {
    id: string;
}
export const SignatureHistory = ({id}:Props) => {
    const {document:{currentDocument:{signatures}}}  = useSelector((state:RootState) => state)
    const dispatch = useDispatch()
    useEffect(() => {
        // ID:
        // namespace:primarykey:id
        dispatch(startLoadingSignatures(id.split(":")[1]))
        return () => {
            
        }
    }, [dispatch,id])
    return (
        <TabsSignatureHistory signatures={signatures} />
    )
}
