



export const FormRequest = () => {

    return (
        <form className="requests__tab-request-check-section">
            <div className="requests__tab-request-check-title">
                <h5>Solicitud de revisión:</h5>
                <p>Solicitaste revisar este documento</p>
            </div>
           
        </form> 
    )
}
