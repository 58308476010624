
import MaterialTable, { Column } from '@material-table/core';
import { IDetailList, IList } from '../../../../../interfaces/maintainers/ILists';
import { localizationMaterialTable } from '../../../../../constants/materialTableConfig';
import { useDispatch, useSelector } from 'react-redux';
import { stratLoadingDetailList, startDeleteDetailList, startAddDetailList, startUpdateDetailList } from '../../../../../state/actions-creators/administration';
import { RootState } from '../../../../../state/reducers/index';
import { IAdministrationState } from '../../../../../state/reducers/administrationReducer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { useEffect } from 'react';
interface PropsTableDetailList{
    loading:boolean,
    list:IList
}
export const TableDetailList = ({list,loading}:PropsTableDetailList) => {
    const dispatch = useDispatch()
    const { detailList}:IAdministrationState = useSelector((state:RootState) => state.administration)
    const columns:Column<IDetailList>[]  = [
        {
            title:"ID",
            field:"id",
            type:"numeric",
            align:"left",
            editable:"never",
        
        },
        {
            title:"Codigo",
            field:"code"   
        },
        {
            title:"Valor",
            field:"value"   
        }
      
    ]
    const handleAdd = (newData:IDetailList)=>{
        return new Promise<void>((resolve,reject) => {
            newData.list_id= list.id
            dispatch(startAddDetailList(newData))
            resolve();
        })
    }
    const handleUpdate = (newData:IDetailList) => {
        return new Promise<void>((resolve,reject) => {
            dispatch(startUpdateDetailList(newData))
            resolve();

        })
    }
    useEffect(() => {
       dispatch(stratLoadingDetailList(list.id))
    }, [dispatch,list.id])
    return (
        <MaterialTable 
            title=""
            columns={columns}
            data={detailList}
            style={{minWidth:"100%"}}
            localization={localizationMaterialTable}
            components={{
                Container: props => <div {...props} className="administration__table-styles-container " />,
            }}
            editable={{
                onRowAdd:handleAdd,
                onRowUpdate:handleUpdate
            }
            }
            actions={[
                {
                    icon: 'refresh',
                    tooltip: `Refrescar`,
                    isFreeAction: true,
                    onClick:()=> {list &&dispatch(stratLoadingDetailList(list.id))}
                },
                rowData =>({
                    tooltip:'Eliminar',
                    icon:() =>(  <FontAwesomeIcon color="#C60B1E" icon={faTrash} />),
                    onClick:(e) => dispatch(startDeleteDetailList(rowData)) 
                    
                })
            ]}

            isLoading={loading}
            options={{
                pageSizeOptions:[],
                search:false,
                loadingType:'overlay',
                pageSize:5,
                actionsColumnIndex:-1,
                filtering: false
            }}
    
        />
    )
}
