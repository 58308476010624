
import { IProfile } from '../../interfaces/auth/IProfile';
import { IToken } from '../../interfaces/auth/IToken';
import { ActionTypeAuth } from '../action-types';
import { ActionsAuth } from '../actions-interfaces/IActionsAuth';

export interface IAuthState {
    tokenInfo: IToken | null
    profile: IProfile | null
    security_key: boolean
}
const initialState: IAuthState = {
    tokenInfo: null,
    profile: null,
    security_key: false,
}
// TODO: cambiar las action ui por load user
const authReducer = (state: IAuthState = initialState, action: ActionsAuth) => {
    switch (action.type) {
        case ActionTypeAuth.LOAD_USER_TOKEN:
            return {
                ...state,
                tokenInfo: action.payload
            }
        case ActionTypeAuth.LOGOUT:
            return {
                ...state,
                tokenInfo: null
            }
        case ActionTypeAuth.LOAD_USER_INFORMATION:
            return {
                ...state,
                profile: action.payload,
                security_key: Boolean(action.payload.attributes?.security_key)
            }
        case ActionTypeAuth.EXISTED_SECURITY_KEY:
            return {
                ...state,
                security_key: true
            }

        default:
            return state;
    }
}
export default authReducer;