
import { FormRequest } from './FormRequest';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { startLoadingDetailTask, cleanCurrentTask } from '../../../../../state/actions-creators/task';
import { RootState } from '../../../../../state/reducers/index';
import { TaskTable } from '../../../../../components/commons/tabs-tasks/TaskTable';

export const RequestTab = ({id}:{id:string}) => {
    const {task} = useSelector((state:RootState) => state.task.currentTask)
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(startLoadingDetailTask(id))
        return () => {
            dispatch(cleanCurrentTask())
        }
    }, [dispatch,id])
    return (
        <>
                <TaskTable inRequests={true} task={task} />
                <FormRequest />
        </>
    )
}
