import { TableRow, TableCell } from "@mui/material";
import moment from 'moment-timezone';
import { TableDetail } from '../../tables/table-detail/TableDetail';
import { ITask } from '../../../interfaces/tasks/ITask';
import { formatStatusTasks } from '../../../utils/formatStatusTasks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faEye, faFileSignature } from "@fortawesome/free-solid-svg-icons";
import { useState } from 'react';
import { ModalPreviewMetadata } from '../../modals/modal-preview-metadata/ModalPreviewMetadata';
import { downloadDocument } from '../../../utils/downloadDocument';
import { getDayLimits } from "../../../utils/getDayLimits";
import { ModalSignature } from '../../modals/modal-signature/ModalSignature';
import { taskStatus } from '../../../constants/taskStatus';
interface PropsTableTask {
    task: ITask | null
}
interface PropsTableTask{
    task:ITask | null,
    inRequests?:boolean,
}
// const renderSignatureCell = (task:ITask) =>{
//     return ()
// } 
export const TaskTable = ({task,inRequests=false}:PropsTableTask) => {
    const [modal, setModal] = useState(false)
    const [modalSignature, setModalSignature] = useState(false)
   
    const myColumns = () =>{
        if(inRequests){
            return ['Nombre documento','Estado','Creador','Creación','Vencimiento','Descargar','Ver']
        }else{
            if(task?.request_signature && !task?.signed as boolean){
                
                return ['Nombre documento','Estado','Creador','Creación','Vencimiento','Descargar','Ver','Firmar']	
            }else{
                return ['Nombre documento','Estado','Creador','Creación','Vencimiento','Descargar','Ver']
            }
        }
        
    
    }
    return (
        <>
        <TableDetail  columnNames={myColumns()}>     
                <TableRow
                
                    >
                        <TableCell component="th" scope="row">
                            {task?.document_name}
                        </TableCell>
                        <TableCell align="right">{formatStatusTasks(task?.status)}</TableCell>
                        <TableCell align="right">{task?.user_name_from}</TableCell>
                        <TableCell align="right">{moment(new Date(task?.create_date+"+00:00")).tz("America/Santiago").format("DD/MM/YYYY HH:mm")}</TableCell>
                        <TableCell align="left">
                        { task?.status === taskStatus.APPROVED || task?.status === taskStatus.REJECTED || task?.status === taskStatus.FINALIZED ?
                            <span>{task?.required_date}</span>
                            :
                            <div className={getDayLimits(task?.required_date) > 1 ? 'task__required-date-extra-time' : getDayLimits(task?.required_date) === 1 ? 'task__required-date-low-time' : 'task__required-date-out-time'}>
                                {task?.required_date}
                            </div>
                        }
                        </TableCell>
                        <TableCell  align="right" >
                            <FontAwesomeIcon style={{cursor:"pointer"}} onClick={ async () => {await downloadDocument(`${process.env.REACT_APP_URL_CONTENT}documents/${task?.primary_key}/download`,task?.document_name as string)}} size="lg" icon={faDownload} />
                        </TableCell>
                        <TableCell  align="right" ><FontAwesomeIcon style={{cursor:"pointer"}} onClick={() => setModal(true)} size="lg" icon={faEye} /> </TableCell>
                    { !inRequests &&
                    ( task?.request_signature as boolean && !task?.signed as boolean && 
                            <TableCell  align="right" >
                                <FontAwesomeIcon style={{cursor:"pointer"}} onClick={() => setModalSignature(true)} size="lg" icon={faFileSignature} /> 
                            </TableCell>)
                    }
                </TableRow>
        </TableDetail >
        { modal && task !== null &&  
            <ModalPreviewMetadata 
                open={modal} 
                setModal={setModal} 
                urlFile={`${process.env.REACT_APP_URL_CONTENT}documents/${task?.primary_key}/download`}
                uuidDocument={task.primary_key}
            />
        }
        { modalSignature && task !== null &&  
            <ModalSignature 
                open={modalSignature} 
                setModal={setModalSignature} 
                taskId={task.id}
                urlFile={`${process.env.REACT_APP_URL_CONTENT}documents/${task?.primary_key}/download`} 
                uuidDocument={task.primary_key}
            />
        }
        </>
    )
}
