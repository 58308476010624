import { IComment } from '../../interfaces/comment/IComment';
import { ActionsComment } from '../actions-interfaces/IActionsComment';
import { ActionTypesComment } from '../action-types/commentTypes';

export interface ICommentState {
    comments: IComment[];
}
const initialState:ICommentState = {
    comments:[]
}
// TODO: cambiar las action ui por load user
const commentReducer = (state:ICommentState=initialState,action:ActionsComment) => {
    switch (action.type) {
        case ActionTypesComment.LOAD_COMMENTS:
            return {
                ...state,
                comments:[...action.payload]
            }
        case ActionTypesComment.CLEAN_COMMENTS:
            return {
                ...state,
                comments:[]
            }
        
        default:
            return state;
    }
}
export default commentReducer;