import { Button } from '@mui/material';
import { IProfile, IRespAttributes } from '../../../../interfaces/auth/IProfile'
import profileImg from '../../../../assets/img/profile.png'
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Card } from '../../../../components/cards/Card';
import { MyTextField } from '../../../../components/fields/MyTextField';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../state/reducers';
import { startAddSecurityKey } from '../../../../state/actions-creators/auth';
import { MyPasswordField } from '../../../../components/fields/MyPasswordField';

interface Props {
    profile: IProfile | null;
}

const SignupSchema = Yup.object().shape({
    security_key: Yup.string()
        .typeError('price must be a number')
        .matches(/^.*(?=.{6,})((?=.*[0123456789]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/, 'Debe contener al menos 6 caracteres, una letra mayúscula, una letra minúscula, un numero')
        .max(20, 'No debe contener mas de 20 caracteres!')
        .required('Campo requerido!'),

    confirmation_security_key: Yup.string()
        .oneOf([Yup.ref("security_key"), null], 'Las constraseñas no coinciden')
        .required('Campo requerido!'),

});

export const ProfileInformation = ({profile}: Props) => {

    const dispatch = useDispatch()
    const { security_key } = useSelector((state: RootState) => state.auth)
    const { tokenInfo } = useSelector((state: RootState) => state.auth)

    return (
        <Card title="Perfil del usuario" flexDirection="column">
            <div className='component-profile__profile-container'>
                <Formik
                    initialValues={{
                        action: 'update',
                        security_key: '',
                        confirmation_security_key: '',
                    }}
                    validationSchema={SignupSchema}
                    onSubmit={(values, {resetForm}) => {
                        dispatch(startAddSecurityKey(values as unknown as IRespAttributes, tokenInfo?.sub as string, security_key as boolean ))
                        resetForm();
                    }}
                >
                    <Form className="profile__container">
                        <div className="profile__container-image-profile">
                            <img src={profileImg} alt="foto de perfil" />
                        </div>
                        <div className="container">
                            <div className="profile__container-inputs-panel">
                                <div className="profile__container-inputs">
                                    <div className="profile__container-input-item">
                                        <label htmlFor="firstName">Nombre</label>
                                        <MyTextField
                                            disabled
                                            label={profile?.firstName}
                                            name="firstName"
                                        />
                                    </div>
                                    <div className="profile__container-input-item">
                                        <label htmlFor="lastName">Apellido</label>
                                        <MyTextField
                                            disabled
                                            label={profile?.lastName}
                                            name="lastName"
                                        />
                                    </div>

                                    <div className="profile__container-input-item">
                                        <label htmlFor="email">Email</label>
                                        <MyTextField

                                            disabled
                                            label={profile?.email}
                                            name="email"
                                        />

                                    </div>
                                </div>
                            </div>
                            <div className="security__container-inputs-panel">
                                <div className="security__container-inputs">
                                    <h3>{security_key ? 'Actualizar Clave' : 'Crear clave'}</h3>
                                    <div className="security__container-input-item">
                                        <MyPasswordField
                                            label="Clave de seguridad"
                                            name="security_key"
                                            type="password"
                                            className="security__input"
                                        />
                                    </div>

                                    <div className="security__container-input-item">
                                        <MyPasswordField
                                            label="Confirmar clave de seguridad"
                                            name="confirmation_security_key"
                                            type="password"
                                            className="security__input"
                                        />
                                    </div>

                                    <Button variant="outlined" type="submit">{security_key? 'ACTUALIZAR' : 'CREAR'}</Button>
                                </div>
                            </div>
                        </div>
                    </Form>
                </Formik>
            </div>
        </Card>
    )
}
