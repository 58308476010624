import { combineReducers } from "redux"
import authReducer from "./authReducer";
import uiReducer from "./uiReducer";
import administrationReducer from './administrationReducer';
import tasksReducer from './tasksReducer';
import { batchReducer } from './batchReducer';
import documentReducer from "./documentsReducer";
import commentReducer from './commentReducer';
import dashboardReducer from './dashboardReducer';
import searchReducer from './searchReducer';
import parameterReducer from './parameterReducer';

const reducers = combineReducers({
    ui:uiReducer,
    auth:authReducer,
    administration:administrationReducer,
    task:tasksReducer,
    batch:batchReducer,
    document:documentReducer,
    comment:commentReducer,
    dashboard:dashboardReducer,
    search:searchReducer,
    parameter:parameterReducer
})
// Se utiliza para usar useSelector correctamente con typescript
export type RootState = ReturnType<typeof reducers>

export default reducers;