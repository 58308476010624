export enum ActionTypesBatch {
    LOAD_BATCHS = "[Batch] Load batchs",
    UPLOAD_BATCH = "[Batch] Upload batch",
    SET_CURRENT_BATCH = "[Batch] Set current batch",
    CLEAN_CURRENT_BATCH = "[Batch] Clean current batch",
    LOAD_FILES_BATCH = "[Batch] Load files of batch", 
    LOAD_STATUS_DATA = "[Batch] Load status data",
    DELETE_BATCH = "[Batch] Delete batch",
    SET_CHECK_FILES_BATCH = "[Batch] Set Check files of batch",
    UPLOAD_UNITARY_BATCH = "[Batch] Upload unitary batch",

}
