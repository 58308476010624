export const formatStatusTasks = (status:number | string | undefined):string => {
    
    switch (status) {
        case 1:
            return "Pendiente"
        case 2:
            return "Aprobado"
        case 3:
            return "Rechazado"
        case 4:
            return "Finalizado"
        default:
            return "Pendiente"
    }
}