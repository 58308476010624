import { CircularProgress } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileUpload } from '@fortawesome/free-solid-svg-icons';
import { DragAndDropState } from '../../hooks/useDragAndDrop';

interface PropsDragAndDropZone{
    dropZoneState:DragAndDropState,
    loading:boolean;
    

}
export const DragAndDropZone = ({dropZoneState,loading}:PropsDragAndDropZone) => {
    const {dropZoneState:{getRootProps, getInputProps,isDragActive,isDragReject,acceptedFiles},maxFiles} = dropZoneState
    return (
        <div {...getRootProps()} className="files__dragdrop-zone">
                            <input  disabled={loading} name="document"  {...getInputProps()}  />
                            { loading && <CircularProgress />}
                            { loading === false &&  acceptedFiles.length ===maxFiles && <p>Cambiar archivo</p>}
                            { acceptedFiles.length !== maxFiles  && loading !== true &&  <FontAwesomeIcon className="files__icons-files" icon={faFileUpload} />}
                            {!isDragActive && loading !== true && acceptedFiles.length !== maxFiles  && <p>Arrastra y deja aquí, o haga click para seleccionar</p>}
                            {isDragActive && loading !== true &&  !isDragReject &&  <p>Deja archivos aquí</p>}
                            { loading !== true &&  isDragReject && <p>Tipo de archivo no permitido</p>}

        </div>
    )
}
