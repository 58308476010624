import { Dispatch, SetStateAction, useCallback, useState } from "react"
import { DropzoneState, useDropzone } from "react-dropzone"
import { Toast } from "../utils/sweetAlertMix"

export interface DragAndDropState{
    dropZoneState:DropzoneState,
    myFiles:File[],
    maxFiles:number
    setMyFiles: Dispatch<SetStateAction<File[]>>,
    resetFiles:() => void;
    removeFile:(acceptedFile: File) => void



}

// TODO: Seguir modificando esto para que acepte mas pdfs
export const useDragAndDrop = (maxFiles:number):DragAndDropState => {
    // SECCION DRAGANDDROP
    const [myFiles, setMyFiles] = useState<File[]>([])

    const onDrop = useCallback((acceptedFiles) => {

    if(myFiles.length === maxFiles){
        return Toast.fire({icon:"error",title:`Solo se permite ${maxFiles} archivos`})
    }else{

        setMyFiles([...myFiles, ...acceptedFiles]);
    }
    }, [myFiles,maxFiles])
    
    const dropZoneState = useDropzone({
        onDrop,
        maxFiles:maxFiles,
        accept:"application/pdf",
        
    })

    const resetFiles = () =>{

        setMyFiles([])

    }
    const removeFile = (acceptedFile:File) =>{
        setMyFiles(myFiles.filter( file => file.name !== acceptedFile.name))
        dropZoneState.acceptedFiles.splice(dropZoneState.acceptedFiles.indexOf(acceptedFile), 1)
    }
    return {dropZoneState,myFiles,setMyFiles,resetFiles,maxFiles,removeFile}
}
