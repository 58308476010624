import { ActionTypesBatch } from '../action-types/batchTypes';
import { IBatch, IUnitaryBatch } from '../../interfaces/batch/Ibatch';
import { ActionsUi } from '../actions-interfaces';
import { Dispatch } from 'redux';
import { finishLoading, startLoading, stratLoadingList, finishLoadingList } from './ui';
import { checkBatchFilesService, deleteBatchService, fillBatchService, getAllBatchsService, getDetailBatchService, getFilesOfBatchService, getStatusBatchService, reProcessBatchService, startProcessBatchService, uploadBatchFilesService, uploadUnitaryBatchService } from '../../services/files/batchServices';
import Swal from 'sweetalert2';
import { Toast } from '../../utils/sweetAlertMix';
import { ActionsBatch } from '../actions-interfaces/IActionsBatch';
import { NavigateFunction } from 'react-router-dom';
import { ActionsDocument } from '../actions-interfaces/IActionsDocument';
import { ActionTypeDocument } from '../action-types/documentTypes';


export const startUploadBatch = (batch:IBatch,navigate:any) => async (dispatch:Dispatch<ActionsUi>) =>{
    try {
        Swal.fire({
            title: 'Cargando...',
            timerProgressBar: true,
            text: 'Por favor espere',
            showConfirmButton:false,
            allowOutsideClick: false,
            heightAuto: false,
            didOpen:() => Swal.showLoading()
            
        });
        const resp = await uploadBatchFilesService(batch)
        Swal.close()
        if(resp.status === 201){
            navigate(`/admin/lotes/detalle-lote/${resp.data.id}`)
            Toast.fire({icon:"success",title:`Lote ${resp.data.name} creado`,timer:2000})
        }
    
    } catch (error) {
        Swal.close()
        Swal.fire({title:"Error",icon:"error",text:`La operación no se pudo realizar, consulte el estado en unos minutos`})
        console.log(error)
    }
}
export const startLoadingBatchs = (page:number) => async (dispatch:Dispatch<ActionsUi | ActionsBatch>) =>{
    try {
        dispatch(startLoading())
        const resp = await getAllBatchsService(page)
        dispatch(finishLoading())
        if(resp.status === 200){
            dispatch({
                type:ActionTypesBatch.LOAD_BATCHS,
                payload:{
                    batchs:resp.data.batchs,
                    count:resp.data.count,
                    from:resp.data.from,
                    to:resp.data.to,
                    page:page
                }
            })
        }
    } catch (error) {
        Toast.fire({icon:"error",title:`Algo salió mal`})
        dispatch(finishLoading())
        console.log(error)
    }
}
export const startLoadingDetailBatch = (id:number) => async (dispatch:Dispatch<ActionsUi | ActionsBatch>) =>{
    try {
        dispatch(startLoading())
        const resp = await getDetailBatchService(id)
        dispatch(finishLoading())
        if(resp.status === 200){
            dispatch({
                type:ActionTypesBatch.SET_CURRENT_BATCH,
                payload:{...resp.data}
            })
        }
    } catch (error) {
        Toast.fire({icon:"error",title:`Algo salió mal`})
        dispatch(finishLoading())
        console.log(error)
    }
}
export const startCheckFilesBatch = (id:number) => async (dispatch:Dispatch<ActionsUi | ActionsBatch>) =>{
    try {
        dispatch(startLoading())
        const resp = await checkBatchFilesService(id)
        dispatch(finishLoading())
        if(resp.status === 200){
            dispatch({
                type:ActionTypesBatch.SET_CHECK_FILES_BATCH,
                payload:resp.data.count
            })
        }
    } catch (error) {
        Toast.fire({icon:"error",title:`Algo salió mal`})
        dispatch(finishLoading())
        console.log(error)
    }
}
export const startFillBatch = (id:number) => async (dispatch:Dispatch<ActionsUi | ActionsBatch>) =>{
    try {
     
        Swal.fire({
            title: 'Cargando...',
            timerProgressBar: true,
            text: 'Esto podria tardar varios minutos',
            showConfirmButton:false,
            allowOutsideClick: false,
            heightAuto: false,
            didOpen:() => Swal.showLoading()
            
        });
        const resp = await fillBatchService(id)
        
        if(resp.status === 200){
            dispatch({
                type:ActionTypesBatch.SET_CHECK_FILES_BATCH,
                payload:resp.data.count
            })
            console.log(resp.data)
            Swal.fire({title:"Exito!",icon:"success",text:`Lote llenado con ${resp.data.count} archivos`})
        }
        
    } catch (error) {
        Swal.fire({title:"Error",icon:"error",text:`La operación no se pudo realizar, consulte el estado en unos minutos`})
        console.log(error)
    }finally{
        Swal.close()
    }
}
export const startProcessBatch = (id:number) => async (dispatch:Dispatch<ActionsUi | ActionsBatch>) =>{
    try {
        dispatch(startLoading())
        const resp = await startProcessBatchService(id)
        dispatch(finishLoading())
        if(resp.status === 200){
            dispatch({
                type:ActionTypesBatch.SET_CURRENT_BATCH,
                payload:{...resp.data}
            })

            Toast.fire({icon:"success",title:`Proceso iniciado`})
        }
    } catch (error) {
        Swal.fire({title:"Error",icon:"error",text:`La operación no se pudo realizar, consulte el estado en unos minutos`})
        dispatch(finishLoading())
        console.log(error)
    }
}
export const startReProcessBatch = (batch:IBatch) => async (dispatch:Dispatch<ActionsUi | ActionsBatch>) =>{
    try {
        
        Swal.fire({
            icon:"warning",
            title:"¿Quieres reprocesar el batch?",
            text:`${batch.name}`,
            input:"checkbox",
            inputValidator: (result):any =>{
                return !result && 'Necesita marcar la casilla'
            },
            inputPlaceholder:"Si estoy deacuerdo",
            confirmButtonColor:"#4da851",
            confirmButtonText:"Reprocesar",
            showCancelButton:true,
            cancelButtonText:"Cancelar",
            cancelButtonColor:"#3085d6"
            
        }).then( async (result) =>{
            if(result.isConfirmed){
                dispatch(startLoading())
                const resp = await reProcessBatchService(batch.id)
                dispatch(finishLoading())
    
                if(resp.status === 200){
                    dispatch({
                        type:ActionTypesBatch.SET_CURRENT_BATCH,
                        payload:resp.data
                    })

                    Toast.fire({icon:"success",title:`Reprocesando ${batch.name} lote `})
                }
                    
            }
        }).catch((error)=>{
            dispatch(finishLoading())
            Toast.fire({icon:"error",title:`Algo salió mal`})
            console.log(error)
            
        })
            
    } catch (error) {
        dispatch(finishLoading())
        console.log(error)
        Toast.fire({icon:"error",title:`Algo salió mal`})
    }
}

export const startDeleteBatch = (batch:IBatch,navigate:any) => async (dispatch:Dispatch<ActionsUi | ActionsBatch>) =>{
    try {
        
        Swal.fire({
            icon:"warning",
            title:"¿Quieres eliminar el batch?",
            text:`${batch.name}`,
            input:"checkbox",
            inputValidator: (result):any =>{
                return !result && 'Necesita marcar la casilla'
            },
            inputPlaceholder:"Si estoy deacuerdo",
            confirmButtonColor:"#d33",
            confirmButtonText:"Eliminar",
            showCancelButton:true,
            cancelButtonText:"Cancelar",
            cancelButtonColor:"#3085d6"
            
        }).then( async (result) =>{
            if(result.isConfirmed){
                dispatch(stratLoadingList())
                const resp = await deleteBatchService(batch.id)
                dispatch(finishLoadingList())
                if(resp.status === 204){
                    dispatch({
                        type:ActionTypesBatch.DELETE_BATCH,
                        payload:batch.id
                    })
                    if(navigate){
                        navigate(`/admin/lotes`)
                    }
                    Toast.fire({icon:"success",title:`Lote ${batch.name} eliminado `})
                }
                    
            }
        })
            
    } catch (error) {
        dispatch(finishLoadingList())
        console.log(error)
        Toast.fire({icon:"error",title:`Algo salió mal`})
    }
}





export const startLoadingStatusDataBatch = (idBatch:number | string) => async (dispatch:Dispatch<ActionsUi | ActionsBatch>) =>{
    try {
        dispatch(startLoading())
        const resp = await getStatusBatchService(idBatch)
        dispatch(finishLoading())
        if(resp.status === 200){
            dispatch({
                type:ActionTypesBatch.LOAD_STATUS_DATA,
                payload:{
                    batchStatus:resp.data.batchStatus,
                    filesStatus:resp.data.filesStatus
                }
            })
      
        }
    } catch (error) {
        Toast.fire({icon:"error",title:`Algo salió mal`})
        dispatch(finishLoading())
        console.log(error)
    }
}
export const startLoadingFilesBatch = (id:number,page:number) => async (dispatch:Dispatch<ActionsUi | ActionsBatch>) =>{
    try {
        dispatch(startLoading())
        const resp = await getFilesOfBatchService(id,page)
        dispatch(finishLoading())

        if(resp.status === 200){
            dispatch({
                type:ActionTypesBatch.LOAD_FILES_BATCH,
                payload:{
                    files:resp.data.files,
                    count:resp.data.count,
                    from:resp.data.from,
                    to:resp.data.to,
                    page:page
                }
            })
        }
    } catch (error) {
        Toast.fire({icon:"error",title:`Algo salió mal`})
        dispatch(finishLoading())
        console.log(error)
    }
}
export const startUploadUnitaryBatch = (batch:IUnitaryBatch,navigate:NavigateFunction) => async (dispatch:Dispatch<ActionsUi | ActionsDocument | ActionsBatch>) =>{
    try {
        dispatch(startLoading())
        const resp = await uploadUnitaryBatchService(batch)
        dispatch(finishLoading())

        if(resp.status === 201){
            dispatch({
                type:ActionTypesBatch.UPLOAD_UNITARY_BATCH,
                payload:batch
            })
            dispatch({
                type:ActionTypeDocument.CLEAN_CURRENT_DOCUMENT
            })
            dispatch({
                type:ActionTypesBatch.SET_CURRENT_BATCH,
                payload:resp.data
            })
            
            navigate(`/admin/lotes/detalle-lote/${resp.data.id}`)
            Toast.fire({icon:"success",title:`Documento cargado con éxito`})
        }
    } catch (error) {
        Toast.fire({icon:"error",title:`Algo salió mal`})
        dispatch(finishLoading())
        console.log(error)
    }
}
export const cleanCurrentBatch = () => ({
    type:ActionTypesBatch.CLEAN_CURRENT_BATCH
})
export const setCurrentBatch = (batch:IBatch) =>({
    type:ActionTypesBatch.SET_CURRENT_BATCH,
    payload:{...batch}
})