import { axiosInstance } from "../../utils/axiosInstance";

import { IMetadataField } from '../../interfaces/metadata/IMetadataField';
import { IDocumentType } from '../../interfaces/maintainers/IDocumentType';
import { IResponseDocument } from '../../interfaces/documents/IDocument';

const getSearchGeneralService = (value:string) =>{
    // return axiosInstance.get<IResponseDocument>(`${process.env.REACT_APP_URL_CONTENT_SEARCH_TMP}search/documents?size=30&page=1&freetext=${value}`)
    return axiosInstance.get<IResponseDocument>(`${process.env.REACT_APP_URL_CONTENT_SEARCH}search/documents?size=30&page=1&freetext=${value}`)
}

const getSearchMetadataFieldsService = (idDocumentType:string) =>{
    return axiosInstance.get<IMetadataField[]>(`${process.env.REACT_APP_URL_DATASET}metadata/company/${idDocumentType}`)
}
const getActiveDocumentTypesService = () =>{
    return axiosInstance.get<IDocumentType[]>(`${process.env.REACT_APP_URL_DATASET}document_types/active`)
}
const getSearchMetadataService = (page:number,urlParams:string) =>{
    
    // return axiosInstance.get<IResponseDocument>(`${process.env.REACT_APP_URL_CONTENT_SEARCH_TMP}search/documents?size=10&page=${page}&${urlParams}`)
    return axiosInstance.get<IResponseDocument>(`${process.env.REACT_APP_URL_CONTENT_SEARCH}search/documents?size=10&page=${page}&${urlParams}`)
}
export {
    getSearchGeneralService,
    getSearchMetadataFieldsService,
    getActiveDocumentTypesService,
    getSearchMetadataService
}