import { Tooltip } from '@mui/material';
import moment from 'moment-timezone';
import { IDocument } from '../../../../../interfaces/documents/IDocument';
import { faCalendarAlt, faFileSignature, faFile, faClipboardList, faArchive } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { formatTypeDocument } from '../../../../../utils/formatTypeDocument';
import { formatStatusDocument } from '../../../../../utils/formatStatusDocument';
interface Props{
    document:IDocument | null;	
}
export const DetailFeatures = ({document}:Props) => {
    return (
        <div className="my-documents__details-features">
                    <Tooltip title="Fecha Creación" placement='bottom' arrow >
                        <div className='my-documents__details-card'>
                            <p>{moment(new Date(document?.create_date+"+00:00")).tz("America/Santiago").format("DD/MM/YYYY HH:mm")}</p>
                            <FontAwesomeIcon icon={faCalendarAlt}  size='5x'/>
                        </div>
                    </Tooltip>
                    <Tooltip title="Firmado" placement='bottom' arrow >
                    <div  className='my-documents__details-card'>
                        <p>{ document?.last_signed_user_name !== "" &&  document?.last_signed_user_name !== "null"  && document?.last_signed_user_name !== undefined
                            ?  document?.last_signed_user_name.toLocaleUpperCase() 
                            : 'Sin firmar'}</p>
                        <FontAwesomeIcon icon={faFileSignature} style={{left:20}} size='5x'/>
                    </div>
                    </Tooltip>
                    <Tooltip title="Tipo" placement='bottom' arrow >
                    <div className='my-documents__details-card'>
                        <p>{ Boolean(document?.type) ? formatTypeDocument(document?.type || "").toLocaleUpperCase() : ''}</p>
                        <FontAwesomeIcon icon={faFile}  size='5x'/>
                    </div>
                    </Tooltip>
                    <Tooltip title="Estado" placement='bottom' arrow >
                    <div  className='my-documents__details-card'>
                        <p>{formatStatusDocument(document?.status|| "").toLocaleUpperCase()}</p>
                        <FontAwesomeIcon icon={faClipboardList}  size='5x'/>
                    </div>
                    </Tooltip>
                    <Tooltip title="Origen" placement='bottom' arrow >
                    <div  className='my-documents__details-card'>
                        <p>{
                            document?.source !== undefined 
                            ? document?.source.toLocaleUpperCase()
                            : ""
                            }</p>
                        <FontAwesomeIcon icon={faArchive}  size='5x'/>
                    </div>
                    </Tooltip>
                </div>
    )
};
