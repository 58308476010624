import { IBatch, IFiles } from '../../interfaces/batch/Ibatch';
import { ActionTypesBatch } from '../action-types';
import { ActionsBatch } from '../actions-interfaces/IActionsBatch';
import { IStatus } from '../../interfaces/batch/IStatusData';

export  interface IBatchState {
    batch:{
        batchs:IBatch[];
        count:number;
        from:number;
        page:number;
        to:number;
    }
    currentBatch:{
        batch: IBatch | null,
        status:IStatus,
        filesFilled:number,
    
    },
    currentFiles:{
        files:IFiles[]
        count:number,
        from:number,
        page:number,
        to:number,
    }
}


const initialState:IBatchState =  {
    batch:{
        batchs: [],
        count: 0,
        from: 0,
        page: 1,
        to: 0
    },
    currentBatch:{
        batch:null,
        status:{
            batchStatus:null,
            filesStatus:[],
        },
        filesFilled:0
    },
    currentFiles:{
        files:[],
        count:0,
        from:0,
        page:1,
        to:0
    }
}
export const batchReducer = (state:IBatchState=initialState,action:ActionsBatch):IBatchState =>{
    switch (action.type) {
        case ActionTypesBatch.LOAD_BATCHS:
            return {
                ...state,
                batch:{
                    ...state.batch,
                    batchs:[...action.payload.batchs],
                    count:action.payload.count,
                    from:action.payload.from,
                    page:action.payload.page,
                    to:action.payload.to
                }
            }
        case ActionTypesBatch.SET_CURRENT_BATCH:
            return {
                ...state,
                currentBatch:{
                    ...state.currentBatch,
                    batch:{...action.payload}
                }
        }
        case ActionTypesBatch.SET_CHECK_FILES_BATCH:
            return {
                ...state,
                currentBatch:{
                    ...state.currentBatch,
                    filesFilled:action.payload
                }
        }
        case ActionTypesBatch.DELETE_BATCH:
            return {
                ...state,
                batch:{
                    ...state.batch,
                    batchs:state.batch.batchs.filter(batch=>batch.id!==action.payload)
                }
        }
        case ActionTypesBatch.LOAD_STATUS_DATA:
            return {
                ...state,
                currentBatch:{
                    ...state.currentBatch,
                    status:{
                        batchStatus:action.payload.batchStatus,
                        filesStatus:[...action.payload.filesStatus]
                    }
                }
            }
        case ActionTypesBatch.CLEAN_CURRENT_BATCH:
            return {
                ...state,
                currentBatch:initialState.currentBatch
            }
        case ActionTypesBatch.LOAD_FILES_BATCH:
            return {
                ...state,
                currentFiles:{
                    ...state.currentFiles,
                    files:[...action.payload.files],
                    count:action.payload.count,
                    from:action.payload.from,
                    to:action.payload.to,
                    page:action.payload.page
                }
            }
    
        default:
            return state;
    }
}