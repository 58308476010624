
export const downloadDocument = async (url:string,filename:string) => {
    fetch(url)
    .then(res => res.blob()) // Gets the response and returns it as a blob
    .then(blob => {
        const objectUrl = URL.createObjectURL(blob);
        const anchor = document.createElement("a");
        document.body.appendChild(anchor);
        anchor.href = objectUrl;
        anchor.download = filename;
        anchor.click();
        window.URL.revokeObjectURL(objectUrl);
    });
}
