import { Column } from '@material-table/core'
import MaterialTable from '@material-table/core';
import { useNavigate } from 'react-router';
import { IDocumentType } from '../../../../../interfaces/maintainers/IDocumentType';
import { localizationMaterialTable } from '../../../../../constants/materialTableConfig';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../../../state/reducers/index';
import { useEffect, useState } from 'react';
import { startLoadingDocumentTypes, cleanDocumentType } from '../../../../../state/actions-creators/administration';
import { TablePagination } from '@mui/material';


export const TableTypesDocuments = ({loading}:{loading:boolean}) => {
    const {list,count } = useSelector((state:RootState) => state.administration.typesDocuments)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const columns:Column<IDocumentType>[] = [{
            title:"ID",
            field:"document_type_id",
            type:"numeric",
            align:"left",
            editable:"never"
        },
        {
            title:"Proyecto",
            field:"project_id", 
            type:"string"
        },
        {
            title:"Dataset",
            field:"dataset_id", 
            type:"string"
        },
        {
            title:"Modelo",
            field:"model_id", 
            type:"string"
        },
        {
            title:"Nombre",
            field:"name", 
            type:"string"
        },
        {
            title:"Nombre a mostrar",
            field:"display_name", 
            type:"string"
        },
        {
            title:"Activo",
            field:"active",
            type:"boolean",
        }
    ]
    
    useEffect(() => {
        dispatch(startLoadingDocumentTypes(1))    
        return () => {
            dispatch(cleanDocumentType())
        }
    }, [dispatch])
    const handleChangePage = (event:any,newPage:number) => {
        dispatch(startLoadingDocumentTypes(1+ newPage))   
        setPage(newPage)
    }
    const handleChangeRowsPerPage = (event:any | null) => {
        setRowsPerPage(parseInt(event.target.value));

    };
    return (
        <MaterialTable 
                title={"Tipo de documento"}
                columns={columns}
                style={{minWidth:"100%"}}
                data={list}
                localization={localizationMaterialTable}
                actions={[
                    {
                        icon: 'add',
                        onClick: (event: any, _) => {
                            navigate("/admin/administracion/tipos-documentos/crear")
                        },
                        isFreeAction: true,
                        tooltip: 'Añadir',
                    }
        
                ]}
                onRowClick={(_,rowData) => { 
                    if(rowData){
                        navigate(`/admin/administracion/tipos-documentos/${rowData.document_type_id}`)
                    }
                }}
                isLoading={loading}
                components={{
                    Pagination: props => (
                                <TablePagination
                                    {...props}
                                    rowsPerPageOptions={[10]}
                                    count={count}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
        
                                />
                    ),
                }}
                options={{
                    pageSizeOptions:[10],
                    search:false,
                    loadingType:'overlay',
                    pageSize:10,
                    actionsColumnIndex:-1,
                    filtering: false
                }}
            
            />
    )
}
