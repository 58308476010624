import {  Backdrop, Button, Fade, Modal } from "@mui/material";
import { RootState } from '../../../../../state/reducers/index';
import { SyntheticEvent, useState } from 'react';
import {  useSelector } from 'react-redux';
import { FormCreateFolder } from './FormCreateFolder';
import { FolderTree } from '../../../../commons/folder/folder-tree/FolderTree';

interface PropsModalDefault {
    open:boolean;
    disabled?:boolean;
    className?:string;
    folderId?:number | null;
    setModal:(modal:boolean) => void;
    children?:JSX.Element | JSX.Element[],
    setFieldValue:(field: string, value: any, shouldValidate?: boolean | undefined) => void
}

// Modal para mostrar el arbol de carpetas
export const ModalFolder = ({open,setModal,className,folderId,setFieldValue,disabled=false}:PropsModalDefault) => {

    const [selected, setSelected] = useState<string>("");
    const {currentBatch} = useSelector((state:RootState) => state.batch)
    const handleClose = () => {
        setModal(false);
    }; 
    
    const handleSelect = (event: SyntheticEvent, nodeIds: string) => {
        // nodeIds: folder_id:folder_name
        setSelected(nodeIds);
        setFieldValue("folder_id",parseInt(nodeIds.split(":")[0]))
        // parent_path corresponde a el nombre de la carpeta
        setFieldValue("parent_path",nodeIds.split(":")[1])
    };

    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className="components-field__modal-folder "
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
            <Fade in={open}>
                <div className={`${Boolean(className) && className}  components-field__container-folder-modal`}>
                        <div className="components-field__folder-title">
                            { currentBatch.batch?
                                <p>Carpeta: {currentBatch.batch.parent_path}</p>
                            :
                                <p>Seleccionar carpeta</p>
                            }
                        </div>
                    <div className="components-field__folder-container ">
                            <div className="components-field__folder-content">
                                <FormCreateFolder selected={selected} />
                                <FolderTree 
                                    folderId={folderId}
                                    handleSelect={handleSelect}
                                    selected={selected}
                                /> 
                                </div>
                            <div className="components-field__modal-default-buttons" >
                                <Button  onClick={handleClose} color="primary"  variant="contained">
                                    Cerrar
                                </Button>
                            </div>
                    </div>
                </div>
            </Fade>
        </Modal>
        </div>
    )
}
