
import { IProfile, IRespAttributes } from '../../interfaces/auth/IProfile';
import { IUsers } from '../../interfaces/auth/IUsers';
import { axiosInstance } from '../../utils/axiosInstance';
const  https = require("https")
const agent = new https.Agent({
    rejectUnauthorized: false,
});

const getUsers = () =>{
    // At instance level
   
    // return axios({method:"get",baseURL:`${process.env.REACT_APP_URL_USERS}/users`})
    return axiosInstance.get<IUsers>(`${process.env.REACT_APP_URL_USERS}users`,{httpAgent:agent})
}

const getInformationProfile = (sub:string | undefined ) =>{
    return axiosInstance.get<IProfile>(`${process.env.REACT_APP_URL_USERS}users/${sub}`)
}

const addSecurityKey = (security_key:IRespAttributes, sub:string | undefined  ) =>{
    return axiosInstance.post(`${process.env.REACT_APP_URL_USERS}users/${sub}/sign`,security_key)
}

export {
    getUsers,
    getInformationProfile,
    addSecurityKey,
}