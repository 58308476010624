export const formatTypeDocument = (type:string | undefined) =>{
    try{

        if(typeof type  === 'string'){
            const myNewTypeList = []
            for(  const p in type.split("_")){
                myNewTypeList.push(`${type.split('_')[p][0].toUpperCase() + type.split('_')[p].slice(1).toLocaleLowerCase() } `)  
            }
            return myNewTypeList.join(' ')
        }else{
            return ""
        }
    }catch(error){
        console.log(error)
        return "" 
    }
}