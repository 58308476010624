export enum ActionTypeDocument {
    LOAD_DOCUMENTS = "[Document] Load documents",
    SET_CURRENT_DOCUMENT = "[Document] Set current document",
    CLEAN_CURRENT_DOCUMENT = "[Document] Clean current document",
    CLEAN_DOCUMENTS = "[Document] Clean documents",
    LOAD_METADATA_FIELDS = "[Document] Load metadata fields",
    LOAD_FILL_METADATA_FIELDS = "[Document] Load fill metadata fields",
    CHECKING_DOCUMENT_PENDING = "[Document] Checking document pending",
    LOAD_SIGNATURES = "[Document] Load signatures",
    CLEAN_METADATAS = "[Document] Clean metadatas",

}