import { downloadDocument } from '../../../../utils/downloadDocument';
import { faEye, faFileAlt, faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { formatStatusFile } from '../../../../utils/formatStatusFile';
import { IBatchState } from '../../../../state/reducers/batchReducer';
import { IFiles } from '../../../../interfaces/batch/Ibatch';
import { localizationMaterialTable } from '../../../../constants/materialTableConfig';
import { ModalPreviewMetadata } from '../../../../components/modals/modal-preview-metadata/ModalPreviewMetadata';
import { RootState } from '../../../../state/reducers/index';
import { startLoadingFilesBatch } from '../../../../state/actions-creators/batch';
import { TablePagination } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useState } from 'react';
import MaterialTable, { Column } from '@material-table/core';

export const TableDetailBatch = ({id}:{id:string}) => {
    const dispatch = useDispatch()
    const {currentFiles:{files,count}} = useSelector((state:RootState):IBatchState => state.batch)
    const {loading} = useSelector((state:RootState) => state.ui)
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [attrs, setAttrs] = useState({
        uuid:'',
        urlFile:''
    })
    const [modal, setModal] = useState(false)
    const navigate = useNavigate()
    const columns:Column<IFiles>[]  = [
        {
            title:"ID",
            field:"id",
            type:"numeric",
            align:"left",
            editable:"never",
            filtering: false
        },
        {
            title:"Nombre",
            field:"name", 
            type:"string",
            filtering: false
        },
        {
            title:"Estado",
            field:"status", 
            type:"string",
            lookup:{ 
                "Pendiente": "Pendiente",
                "Borrador": "Borrador",
                "Clasiificado": "Clasificado",
                "No Clasificado": "No Clasificado",
                "Procesando": "Procesando",
                "Procesado": "Procesado",
                "Indexado": "Indexado",
                "Aprobado": "Aprobado",
                "Error": "Error",
            },
        },
        {
            title:"Tipo",
            field:"type", 
            type:"string",
            filtering: false
        },
        {
            title:"Manual",
            field:"manual", 
            type:"boolean",
            filtering: false
        }
    ]

    useEffect(() => {
        dispatch(startLoadingFilesBatch(parseInt(id),1))
    }, [dispatch,id])
    const handleChangePage = (event:any,newPage:number) => {
        dispatch(startLoadingFilesBatch(parseInt(id),1+ newPage))   
        setPage(newPage)
    }
    const handleChangeRowsPerPage = (event:any | null) => {
        setRowsPerPage(parseInt(event.target.value));
    };
    return (
        <div>
            <MaterialTable 
                title={"Documentos"}
                columns={columns}
                style={{minWidth:"100%"}}
                data={files.map(file => {
                    if(typeof file.status === 'string'){
                        file.status = formatStatusFile(parseInt(file.status))
                    }else{
                        file.status = formatStatusFile(file.status)
                    }
                    return file
                })}
                localization={localizationMaterialTable}
                actions={[
                    rowData =>({
                        tooltip:'Previsualizar',
                        icon:() =>(  <FontAwesomeIcon  size="sm" icon={faEye} />),
                        disabled:rowData.status === "Indexado" ? false : true,
                        onClick:() => {
                            setAttrs({
                                ...attrs,
                                urlFile:`${process.env.REACT_APP_URL_CONTENT}documents/${rowData.uuid}/download`,
                                uuid:rowData.uuid
                            })
                            setModal(true)
                        }
                    }),
                    {
                        icon: 'refresh',
                        tooltip: `Refrescar`,
                        isFreeAction: true,
                        onClick:()=> dispatch(startLoadingFilesBatch(parseInt(id),1))
                    },
                    rowData =>({
                        tooltip:'Descargar',
                        disabled:rowData.status === "Indexado" || rowData.status === "No clasificado"  || rowData.status === "Error"  ? false : true,
                        icon:() =>( <FontAwesomeIcon  size="sm" icon={faDownload} /> ),
                        onClick:() => {
                        
                            downloadDocument(`${process.env.REACT_APP_URL_CONTENT}documents/${rowData.uuid}/download`,rowData.name)
                        }
                    }),
                    rowData =>({
                        tooltip:'Editar',
                        icon:() =>(  <FontAwesomeIcon  size="sm" icon={faFileAlt} />),
                        disabled: rowData.status === "Indexado" || rowData.status === "No clasificado"  || rowData.status === "Error"  ? false : true,
                        onClick:() => {
                            navigate(`/admin/mis-documentos/detalle-documento/${rowData.uuid}`)
                        }
                    }),
                
                    
                ]}
                components={{
                    Pagination: props => (
                                <TablePagination
                                    {...props}
                                    rowsPerPageOptions={[10]}
                                    count={count}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
        
                                />
                    ),
                }}
                isLoading={loading}
                options={{
                    pageSizeOptions:[10],
                    search:false,
                    loadingType:'overlay',
                    pageSize:10,
                    actionsColumnIndex:-1,
                    filtering: true
                }}
            
            />
        { modal &&
            <ModalPreviewMetadata  
                open={modal}
                setModal={setModal}
                urlFile={attrs.urlFile}
                uuidDocument={attrs.uuid}
            />
        }
        </div>
    )
}
