
import { Tab, Tabs } from '@mui/material';
import { IUiState } from '../../../../state/reducers/uiReducer';
import { RootState } from '../../../../state/reducers/index';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Card } from '../../../../components/cards/Card';

import { TaskTab } from './TaskTab/TaskTab';
import { HistoryTab } from './HistroyTab/HistoryTab';
import { CommentsTab } from "./CommentsTab/CommentsTab";
import { useParams } from 'react-router';
import { SignatureHistory } from './SignaturesHistory/SignatureHistory';

const a11yProps = (index:number) => {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,

    };
}
export const DetailTaskTabs = () => {
    const params = useParams()
    const id = params.id as string;
    const [tabValue, setTabValue] = useState<number>(0)
    const {loading}:IUiState = useSelector((state:RootState) => state.ui)
    const handleChangeTab = (_:React.ChangeEvent<{}>, newValue:any) => {
        setTabValue(newValue);
    };
    return (
        <>
        <div className="task__container-tabs ">
            <Tabs disabled={loading} indicatorColor="primary" variant="scrollable" onChange={handleChangeTab} scrollButtons="auto" value={tabValue} aria-label="simple tabs example">
                    <Tab className="task__tab" label="Tarea" {...a11yProps(0)} />
                    <Tab className="task__tab" label="Historial" {...a11yProps(1)} />
                    <Tab className="task__tab" label="Firmas" {...a11yProps(2)} />
                    <Tab className="task__tab" label="Comentarios" {...a11yProps(3)} />
            </Tabs>
        </div>
        {
            tabValue === 0 ?
            <Card className="task__tab-task-container" flexDirection="column">
                <TaskTab id={id} /> 
            </Card>
            :  tabValue === 1 ? 
            <HistoryTab id={id} />
            : tabValue === 2 ?
             <SignatureHistory  id={id} />
            :  tabValue === 3 ? 
            <CommentsTab id={id} />
            :
            <h5>loading</h5>
        }
        </>
    )
}
