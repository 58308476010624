
import { Checkbox, FormControlLabel, Button } from '@mui/material';
import { useState, ChangeEvent } from 'react';
import { ITask, ITaskForm } from '../../../../../interfaces/tasks/ITask';
import { Formik, Form } from 'formik';
import { MyAutoCompleteUsersField } from '../../../../../components/fields/custom-fields/MyAutoCompleteUserField';
import { useDispatch } from 'react-redux';
import { startAddTask, startChangeStatusTask } from '../../../../../state/actions-creators/task';
import { MyTextFieldMultiline } from '../../../../../components/fields/MyTextFieldMultiline';
import * as Yup from 'yup'
import { useNavigate } from 'react-router';
import { MyCheckBoxField } from '../../../../../components/fields/MyCheckBoxField';
import { MyDeriveDateField } from '../../../../../components/fields/custom-fields/MyDeriveDateField';
interface PropsFromTask{
    task:ITask | null,
    id:string
}
export const FormTask = ({task,id}:PropsFromTask) => {
    const [dispatchTo, setDispatchTo] = useState(false)
    const dispatch = useDispatch()
    const handleDispatchTo = (e:ChangeEvent<HTMLInputElement>) =>{
        setDispatchTo(e.target.checked)
    }
    const handleCancel = ( ) =>{
        setDispatchTo(false)

    }
    // namespace:primarkey:id
    const idSplited = id.split(':')
    const navigate = useNavigate()
    const requireSign = Boolean(!task?.signed && task?.request_signature)
    return (
        <Formik
            initialValues={{
                user_id_to:"",
                required_date:"",
                comment:"",
                namespace:"document",
                primary_key:idSplited[1],
                request_signature:false,
            }}
            validationSchema={Yup.object({
                user_id_to: Yup.string().required("El usuario es requerido"),
                required_date: Yup.string().required("La fecha es requerida"),
                comment: Yup.string().required("El comentario es requerido"),
            })}
            onSubmit={(values) =>{
                dispatch(startAddTask(values as ITaskForm,undefined,navigate))
            }}
        >
            {
                (formik) =>(
                    <Form className="task__tab-task-check-section">
                        <div className="task__tab-task-check-title">
                            <h5>Solicitud de revisión:</h5>
                            <p>{task?.user_name_from} te {(task?.status === 1) ?`ha solicitado `: "solicitó "}revisar este documento</p>
                        </div>
                        {( task?.status === 1) && 
                        <>
                        <div className="task__tab-task-check-inputs-section">
                            <div className="task__tab-task-check-input-item">
                            <FormControlLabel control={<Checkbox checked={dispatchTo} onChange={handleDispatchTo}   color="primary" />} label="Derivar a:" />
                            <MyCheckBoxField label="Firmar?" name="request_signature" />
                            </div>
                    
                            <div className="task__tab-task-check-input-item">
                                <MyAutoCompleteUsersField 
                                    label="Usuario"
                                    name="user_id_to"
                                    size="small"
                                    disabled={!dispatchTo}	
                                />
                            </div>
                            <div className="task__tab-task-check-input-item">
                                <MyDeriveDateField
                                    disabled={!dispatchTo}
                                    label="Vencimiento" 
                                    name="required_date"
                                />
                            </div>
                            
                        </div>
                        <div className="task__tab-task-check-comment-section">
                            <MyTextFieldMultiline
                                disabled={!dispatchTo} 
                                name="comment"
                                label="Observacion"
                            />   
                        </div>
                        <div className="task__tab-task-check-buttons-section">
                        <div className="task__tab-task-check-button-item">
                            <Button  
                                size="large" 
                                variant="outlined" 
                                color="error" 
                                onClick={handleCancel}
                                type="button">Cancelar</Button>
                        </div>
                            <div className="task__tab-task-check-button-item">
                                <Button 
                                    disabled={requireSign || (dispatchTo  && Boolean(task))} 
                                    size="large" 
                                    variant="outlined" 
                                    color="secondary" 
                                    onClick={() => {
                                        if(task){
                                            dispatch(startChangeStatusTask(task,3))
                                        }
                                    }}
                                    type="button">Rechazar</Button>
                            </div>
                            <div className="task__tab-task-check-button-item">
                                <Button 
                                    disabled={requireSign || (dispatchTo   && Boolean(task))} 
                                    onClick={() => {
                                        if(task){
                                            dispatch(startChangeStatusTask(task,2))
                                        }
                                    }}
                                    size="large" 
                                    variant="outlined" 
                                    color="primary" 
                                    type="button">Aprobar</Button>
                            </div>
                            <div className="task__tab-task-check-button-item">
                                
                                <Button disabled={!dispatchTo || requireSign}  size="large" variant="outlined" color="success" type="submit">Derivar</Button>
                            </div>
                        </div>
                        </>
                        }
                    </Form>
                )
            }
        </Formik>
    
    )
}
