import { TableCompanies } from './components/TableCompanies';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../state/reducers/index';
import { IUiState } from '../../../../state/reducers/uiReducer';

export const CompaniesView = () => {
    const {loadingList}:IUiState = useSelector((state:RootState) => state.ui)
    return (
        <div>
            <TableCompanies loading={loadingList} />
        </div>
    )
}
