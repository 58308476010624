
import { MyPieChart } from './MyPieChart';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../../state/reducers/index';
import { IBatchState } from '../../../../state/reducers/batchReducer';
import { useEffect } from 'react';
import { startLoadingStatusDataBatch } from '../../../../state/actions-creators/batch';
import { IBatch } from '../../../../interfaces/batch/Ibatch';
import moment from 'moment';
import { formatStatusBatch } from '../../../../utils/formatStatusBatch';
import { formatStatusFile } from '../../../../utils/formatStatusFile';


export const PanelStatus = ({batch}:{batch:IBatch}) => {
    const {currentBatch:{status}}:IBatchState = useSelector((state:RootState) => state.batch)
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(startLoadingStatusDataBatch(batch.id))
        const interval = setInterval(() => dispatch(startLoadingStatusDataBatch(batch.id)),5000)
        return () => clearInterval(interval)
    }, [batch,dispatch])
    
    return (
    <div className="batch__container-panel">
        <div className="batch__panel-status">
            <div className="batch__panel-status-container-items">
                    <div className="batch__panel-status-status"><p>Estado: {formatStatusBatch(status.batchStatus?.status as number)}</p></div>
                    <div className="batch__panel-status-id"><p>Lote: {batch.id}</p></div>
                    <div className="batch__panel-status-user"><p>Usuario: {batch.user_id}</p></div>
                    <div className="batch__panel-status-date"><p>Creación: {moment(new Date(batch.create_date as string)).format("DD-MM-YYYY")}</p></div>
                    <div className="batch__panel-status-symbology">
                        {
                            status.filesStatus?.map((statusFile,index) => (
                                <div>
                                    <h5>{formatStatusFile(statusFile.status)}</h5>
                                    <span
                                        style={{background:statusFile.color}}
                                    >
                                        <span></span>
                                    </span>
                                </div>
                        ))
                        }
                    </div>
                <div className="batch__panel-status-item">
                    <MyPieChart  data={status} />

                </div>
            </div>
        </div>
    </div>
    )
}
