
import { IMetadataField } from '../../interfaces/metadata/IMetadataField';
import { MyDateField, MyTextField } from '../fields';
import { MySelectFieldList } from '../fields/custom-fields/MySelectFieldList';
import { TextField } from '@mui/material';
interface Props{
    metadataFields:IMetadataField[];
    preview?:boolean;
    smallForm?:boolean;
}

export const GenerateFields = ({metadataFields,smallForm=false,preview=false}:Props) => {
    
    if(metadataFields.length !== 0){

        return (
                <div className="metadata__container-metadata">
                    {
                        metadataFields.map(({type,name,label,key_list},index) =>{
                            if ( type === 'input'  || type === 'number'  ||  type === 'password' || type === 'email' || type === 'text' ) {
                                return  (<div key={ index } className={smallForm ?   `metadata__input-metadata-small-form`: `metadata__input-metadata`}>
                                            <MyTextField 
                                                disabled={preview}
                                                type={(type as any)}
                                                name={ name } 
                                                label={ label } 
                                                />
                                        </div>)
    
                            }else if(type === 'date'){
                                return  (<div key={ index } className={smallForm ?`metadata__input-metadata-small-form`: `metadata__input-metadata`}>
                                            <MyDateField 
                                                disabled={preview}
                                                type={(type as any)}
                                                name={ name } 
                                                label={ label } 
                                                />
                                        </div>)
                            } else if ( type === 'select' ) {
                                return (
                                    <div key={ index }  className={smallForm ?   `metadata__input-metadata-small-form`: `metadata__input-metadata`}>
                                        <MySelectFieldList 
                                            disabled={preview}
                                            key={ name }
                                            label={ label }
                                            name={ name }
                                            keyList={key_list}
                                        
                                        />
                                        
                                    </div>
                                )
                            }else{
                                return  (
                                    <div key={ index }  className={smallForm ?   `metadata__input-metadata-small-form`: `metadata__input-metadata`}>
                                        <TextField size="small" fullWidth label={`Tipo ${type} No soportado`} disabled />
                                    </div>
                                )
                            }
    
                            // throw new Error(`El type: ${ type }, no es soportado`);
                        })
                    }
                </div>
                
        )
    }else{
        return null
    }
}
