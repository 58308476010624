

import { useEffect } from 'react';
import { startLoadingHistoryTask } from '../../../../../state/actions-creators/task';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../state/reducers/index';
import { TaskHistory } from '../../../../../components/commons/tabs-tasks/TaskHistory';
interface Props{
    id:string
}
export const HistoryTab = ({id}:Props) => {
    const dispatch = useDispatch()
    const {historyTask} = useSelector((state:RootState) => state.task.currentTask)
    useEffect(() => {
        dispatch(startLoadingHistoryTask(id))
        return () => {
            
        }
    }, [id,dispatch])
    return (
        <TaskHistory tasksHistory={historyTask} />
    )
}
