import { IList, IDetailList } from '../../interfaces/maintainers/ILists';
import { ICompany } from '../../interfaces/maintainers/IComapny';

import { ActionsAdministration } from '../actions-interfaces/IActionsAdministration';
import { ActionTypesAdministration } from '../action-types';
import { IOrganization } from '../../interfaces/maintainers/IOrganization';
import { IParameter } from '../../interfaces/maintainers/IParameter';
import { IDocumentType, IMetadata } from '../../interfaces/maintainers/IDocumentType';
import { IFolder } from '../../interfaces/documents/IFolders';
import { IModel } from '../../interfaces/entity/IModel';
import { IDataset } from '../../interfaces/entity/IDataset';

export interface IAdministrationState{
    lists:          IList[],
    detailList:     IDetailList[],
    companies:      ICompany[] ,
    organizations:  IOrganization[],
    parameters:     IParameter[],
    folders:        IFolder[],
    typesDocuments: {
        list:IDocumentType[],
        models:IModel[],
        datasets:IDataset[],
        count:number,
        from:number,
        to:number,
        page:number,
        currentDocumentType:IDocumentType | null,
        currentMetadata:IMetadata[]
    },
}
const initialState:IAdministrationState ={
    lists:[],
    detailList:[],
    companies:[],
    organizations:[],
    parameters:[],
    folders:[],
    typesDocuments:{
        list:[],
        models:[],
        datasets:[],
        currentDocumentType:null,
        currentMetadata:[],
        count:0,
        from:0,
        to:0,
        page:1

    }

}
const administrationReducer = (state:IAdministrationState=initialState,action:ActionsAdministration):IAdministrationState =>{
    switch (action.type) {
        // Seccion lists
        case ActionTypesAdministration.LOAD_LISTS:
            return {
                ...state,
                lists:[...action.payload]
            }
        case ActionTypesAdministration.ADD_LIST:
            return {
                ...state,
                lists:[...state.lists,action.payload]
            }
        case ActionTypesAdministration.UPDATE_LIST:
            return {
                ...state,
                lists:state.lists.map(list => 
                    list.id === action.payload.id ?
                    action.payload
                    :
                    list
                    )
            }
        case ActionTypesAdministration.DELETE_LIST:
            return {
                ...state,
                lists:state.lists.filter(list => ( list.id !== action.payload ))
            }


        case ActionTypesAdministration.LOAD_DETAIL_LIST:
            return {
                ...state,
                detailList:[...action.payload]
            }
        case ActionTypesAdministration.ADD_DETAIL_LIST:
            return {
                ...state,
                detailList:[...state.detailList,action.payload]
            }
        case ActionTypesAdministration.UPDATE_DETAIL_LIST:
            return {
                ...state,
                detailList:state.detailList.map(list => 
                    list.id === action.payload.id ?
                    action.payload
                    :
                    list
                    )
            }
        case ActionTypesAdministration.DELETE_DETAIL_LIST:
            return {
                ...state,
                detailList:state.detailList.filter(list => ( list.id !== action.payload ))
            }
        // Fin seccion lists
        // Seccion parameters
        case ActionTypesAdministration.LOAD_PARAMETERS:
            return {
                ...state,
                parameters:[...action.payload]
            }
        case ActionTypesAdministration.ADD_PARAMETER:
            return {
                ...state,
                parameters:[...state.parameters,action.payload]
            }
        case ActionTypesAdministration.UPDATE_PARAMETER:
            return {
                ...state,
                parameters:state.parameters.map(parameter => 
                    parameter.id === action.payload.id ?
                    action.payload
                    :
                    parameter
                    )
            }
        case ActionTypesAdministration.DELETE_PARAMETER:
            return {
                ...state,
                parameters:state.parameters.filter(parameter => ( parameter.id !== action.payload ))
            }

        // Fin seccion parameters
        // Seccion comapnies
        case ActionTypesAdministration.LOAD_COMPANIES:
            return {
                ...state,
                companies:[...action.payload]
            }
        case ActionTypesAdministration.ADD_COMPANY:
            return {
                ...state,
                companies:[...state.companies,action.payload]
            }
        case ActionTypesAdministration.UPDATE_COMPANY:
            return {
                ...state,
                companies:state.companies.map(company => 
                    company.id === action.payload.id ?
                    action.payload
                    :
                    company
                    )
            }
        case ActionTypesAdministration.DELETE_COMPANY:
            return {
                ...state,
                companies:state.companies.filter(company => ( company.id !== action.payload ))
            }
        // Fin seccion comapnies
        // Seccion organization
        case ActionTypesAdministration.LOAD_ORGANIZATIONS:
            return {
                ...state,
                organizations:[...action.payload]
            }
        case ActionTypesAdministration.ADD_ORGANIZATION:
            return {
                ...state,
                organizations:[...state.organizations,action.payload]
            }
        case ActionTypesAdministration.UPDATE_ORGANIZATION:
            return {
                ...state,
                organizations:state.organizations.map(organization => 
                    organization.id === action.payload.id ?
                    action.payload
                    :
                    organization
                    )
            }
        case ActionTypesAdministration.DELETE_ORGANIZATION:
            return {
                ...state,
                organizations:state.organizations.filter(organization => ( organization.id !== action.payload ))
            }
        // Fin seccion organization
        // Seccion type document
        case ActionTypesAdministration.LOAD_TYPES_DOCUMENTS:
            return {
                ...state,
                typesDocuments:{
                    ...state.typesDocuments,
                    list:[...action.payload.document_types],
                    count:action.payload.count,
                    from:action.payload.from,
                    to:action.payload.to,
                    page:action.payload.page
                }
            }
        case ActionTypesAdministration.LOAD_MODELS:
            return {
                ...state,
                typesDocuments:{
                    ...state.typesDocuments,
                    models:[...action.payload],
                }
            }
        case ActionTypesAdministration.UPDATE_MODEL:
            return {
                ...state,
                typesDocuments:{
                    ...state.typesDocuments,
                    models:state.typesDocuments.models.map(model => 
                        model.model_id === action.payload.model_id ?
                        action.payload
                        :
                        model
                        )
                }
            }
        case ActionTypesAdministration.LOAD_DATASETS:
            return {
                ...state,
                typesDocuments:{
                    ...state.typesDocuments,
                    datasets:[...action.payload],
                }
            }
        
        
        
        case ActionTypesAdministration.LOAD_ACTIVE_DOUCUMENT_TYPES:
                return {
                    ...state,
                    typesDocuments:{
                        ...state.typesDocuments,
                        list:[...action.payload],
                    }
                }
        case ActionTypesAdministration.ADD_TYPE_DOCUMENT:
            return {
                ...state,
                typesDocuments:{
                    ...state.typesDocuments,
                    list:[...state.typesDocuments.list,action.payload]
                }
            }
        case ActionTypesAdministration.UPDATE_TYPE_DOCUMENT:
            return {
                ...state,
                typesDocuments:{
                    ...state.typesDocuments,
                    list:state.typesDocuments.list.map(typeDocument => 
                        typeDocument.document_type_id === action.payload.document_type_id ?
                        action.payload
                        :
                        typeDocument
                        )
                }
            }
        case ActionTypesAdministration.DELETE_TYPE_DOCUMENT:
            return {
                ...state,
                typesDocuments:{
                    ...state.typesDocuments,
                    list:state.typesDocuments.list.filter(typeDocument => ( typeDocument.document_type_id !== action.payload ))
                }
            }
        case ActionTypesAdministration.SET_CURRENT_DOCUMENT_TYPE:
            return {
                ...state,
                typesDocuments:{
                    ...state.typesDocuments,
                    currentDocumentType:{...action.payload}
                }
            }
        case ActionTypesAdministration.CLEAN_DOCUMENT_TYPES:
            return {
                ...state,
                typesDocuments:initialState.typesDocuments
            }
        // Fin seccion type document
        // Seccion metadata
        case ActionTypesAdministration.LOAD_METADATA:
            return {
                ...state,
                typesDocuments:{
                    ...state.typesDocuments,
                    currentMetadata:[...action.payload]
                }
            }
        case ActionTypesAdministration.ADD_METADATA:
            return {
                ...state,
                typesDocuments:{
                    ...state.typesDocuments,
                    currentMetadata:[...state.typesDocuments.currentMetadata,action.payload]
                }
            }
        case ActionTypesAdministration.UPDATE_METADATA:
            return {
                ...state,
                typesDocuments:{
                    ...state.typesDocuments,
                    currentMetadata:state.typesDocuments.currentMetadata.map(metadata => 
                        metadata.metadata_id === action.payload.metadata_id ?
                        action.payload
                        :
                        metadata
                        )
                }
            }
        case ActionTypesAdministration.DELETE_METADATA:
            return {
                ...state,
                typesDocuments:{
                    ...state.typesDocuments,
                    currentMetadata:state.typesDocuments.currentMetadata.filter(metadata => ( metadata.metadata_id !== action.payload ))
                }
            }
        // Fin seccion metadata
        // Seccion folders
        case ActionTypesAdministration.LOAD_FOLDERS:
            return {
                ...state,
                folders:[...action.payload]
            }
        // Fin seccion folders
        default:
            return state;
    }
}
export default administrationReducer;