import MaterialTable, { Column } from '@material-table/core';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { RootState } from '../../../../../state/reducers';
import { IParameter } from '../../../../../interfaces/maintainers/IParameter';
import { startAddParameter, startDeleteParameter, startLoadingParameters, startUpdateParameter } from '../../../../../state/actions-creators/administration';
import { localizationMaterialTable } from '../../../../../constants/materialTableConfig';

export const TableParameters = ({loading}:{loading:boolean}) => {
    const dispatch = useDispatch()
    const {parameters} = useSelector((state:RootState) => state.administration)
    const columns:Column<IParameter>[] = [
        {
            title:"ID",
            field:"id",
            type:"numeric",
            align:"left",
            editable:"never"
        },
        {
            title:"Namespace",
            field:"namespace",
            type:"string",
            editable:"onAdd"
        },
        {
            title:"Primary key",
            field:"primary_key",
            type:"string",
            editable:"onAdd"
        },
        {
            title:"Key",
            field:"key",
            type:"string",
            editable:"onAdd"
        },
        {
            title:"Valor",
            field:"value",
            type:"string"
        },
        {
            title:"Label",
            field:"label",
            type:"string"
        }
    ]
    useEffect(() => {
        dispatch(startLoadingParameters())
    }, [dispatch])
    const handleAdd = (newData:IParameter)=>{
        return new Promise<void>((resolve,reject) => {
            dispatch(startAddParameter(newData))
            resolve();
        })
    }
    const handleUpdate = (newData:IParameter) => {
        return new Promise<void>((resolve,reject) => {
            dispatch(startUpdateParameter(newData))
            resolve();

        })
    }
    return (
        <MaterialTable 
            title="Parámetros"
            columns={columns}
            data={parameters}
            style={{minWidth:"100%"}}
            localization={localizationMaterialTable}
            editable={{
                onRowAdd:handleAdd,
                onRowUpdate:handleUpdate
            }
            }
            actions={[
                {
                    icon: 'refresh',
                    tooltip: `Refrescar`,
                    isFreeAction: true,
                    onClick:()=> dispatch(startLoadingParameters())
                },
                rowData =>({
                    tooltip:'Eliminar',
                    icon:() =>(  <FontAwesomeIcon color="#C60B1E" icon={faTrash} />),
                    onClick:(e) => dispatch(startDeleteParameter(rowData)) 
                    
                })
            ]}

            isLoading={loading}
            options={{
                pageSizeOptions:[10,20,30],
                search:false,
                loadingType:'overlay',
                pageSize:10,
                actionsColumnIndex:-1,
                filtering: false
            }}
    
        />
    )
}
