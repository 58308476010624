import { ActionsDocument } from '../actions-interfaces/IActionsDocument';
import { ActionsUi } from '../actions-interfaces';
import { ActionTypeDocument } from '../action-types/documentTypes';
import { Dispatch } from 'redux';
import { getAllDocumentsService, getAllSignautresService, getDetailDocumentService, updateDocumentTypeMetadataService, updateMetadataDocumentService } from '../../services/documents/documentServices';
import { getMetadataFieldsService } from '../../services/documents/metadataServices';
import { startLoadingDocument, finishLoadingDocument, stratLoadingList, finishLoadingList, startLoadingMetadata, finishLoadingMetadata, startLoading, finishLoading } from './ui';
import { Toast } from '../../utils/sweetAlertMix';
import { getPendingDocumentsService } from '../../services/tasks/tasksServices';
import { RootState } from '../reducers/index';
import { IMetadataField } from '../../interfaces/metadata/IMetadataField';
import { IRequestDocumentTypeUpdate } from '../../interfaces/documents/IDocument';


export const startLoadingAllDocuments = (page:number) => async (dispatch:Dispatch<ActionsUi | ActionsDocument>) =>{
    try {
        dispatch(stratLoadingList())
        const resp = await getAllDocumentsService(page)
        dispatch(finishLoadingList())
        if(resp.status === 200){
            dispatch({
                type:ActionTypeDocument.LOAD_DOCUMENTS,
                payload:{
                    page:page,
                    documents:resp.data.documents,
                    count:resp.data.count,
                    to:resp.data.to,
                    from:resp.data.from,
                    searchUrl:null,
                }
            })
        }
    } catch (error) {
        Toast.fire({icon:"error",title:`Algo salió mal`})
        dispatch(finishLoadingList())
        console.log(error)
    }
}

export const startLoadingSignatures = (uuidDocument:string) => async (dispatch:Dispatch<ActionsUi | ActionsDocument>) =>{
    try {
        dispatch(stratLoadingList())
        const resp = await getAllSignautresService(uuidDocument)
        dispatch(finishLoadingList())
        if(resp.status === 200){
            dispatch({
                type:ActionTypeDocument.LOAD_SIGNATURES,
                payload:resp.data
            })
        }
    } catch (error) {
        Toast.fire({icon:"error",title:`Algo salió mal`})
        dispatch(finishLoadingList())
        console.log(error)
    }
}
export const startUpdateDocumentMetadata = (uuid:string,data:any) => async (dispatch:Dispatch<ActionsUi | ActionsDocument>) =>{
    try {
        dispatch(startLoadingMetadata())
        const resp = await updateMetadataDocumentService(uuid,data)
        // Esto lo hago para que el actualizar
        // no se vea tan grotesco ya que es muy rapido
        setTimeout(() => {
            dispatch(finishLoadingMetadata())
        }, 1000);
        if(resp.status === 200){
            dispatch({
                type:ActionTypeDocument.LOAD_METADATA_FIELDS,
                payload:resp.data
            })
            Toast.fire({icon:"success",title:`Metadatos actualizada`})
        }
    } catch (error) {
        Toast.fire({icon:"error",title:`Algo salió mal`})
        dispatch(finishLoadingMetadata())
        console.log(error)
    }
}
export const startCheckingDocumentPending = (idTask:string) => async (dispatch:Dispatch<ActionsUi | ActionsDocument>) =>{
    try {
        dispatch(startLoading())
        const resp = await getPendingDocumentsService(idTask)
        dispatch(finishLoading())
        const validation = resp.data.length > 0
        if(resp.status === 200){
            dispatch({
                type:ActionTypeDocument.CHECKING_DOCUMENT_PENDING,
                payload:validation
            })
        }
    } catch (error) {
        Toast.fire({icon:"error",title:`Algo salió mal`})
        dispatch(finishLoading())
        console.log(error)
    }
}

export const startLoadingDetailDocument = (id:string) => async (dispatch:Dispatch<ActionsUi | ActionsDocument>) =>{
    try {
        dispatch(startLoadingDocument())
        const resp = await getDetailDocumentService(id)
        dispatch(finishLoadingDocument())
        if(resp.status === 200){
            dispatch({
                type:ActionTypeDocument.SET_CURRENT_DOCUMENT,
                payload:resp.data
            })
        }
    } catch (error) {
        Toast.fire({icon:"error",title:`Algo salió mal`})
        dispatch(finishLoadingDocument())
        console.log(error)
    }
}


export const cleanCurrentDocument = () => ({
    type:ActionTypeDocument.CLEAN_CURRENT_DOCUMENT
})
export const cleanDocuments = () => ({
    type:ActionTypeDocument.CLEAN_DOCUMENTS
})



export const startLoadingMetadataFields = (uuid:string) =>  async (dispatch:Dispatch<ActionsDocument | ActionsUi >) =>{
    try {
        dispatch(startLoadingMetadata())
        const resp = await getMetadataFieldsService(uuid);
        dispatch(finishLoadingMetadata())
        if(resp.status === 200){
            dispatch({
                type:ActionTypeDocument.LOAD_METADATA_FIELDS,
                payload:resp.data
            })
        }
    } catch (error) {
        Toast.fire({icon:"error",title:`Algo salio mal `})
        dispatch(finishLoadingMetadata())
        console.log(error)
    }
}
export const loadMetadataFields=  (metadataFields:IMetadataField[]) => ({
    type:ActionTypeDocument.LOAD_METADATA_FIELDS,
    payload:metadataFields
})
export const loadFillMetadataFields=  (metadataFields:IMetadataField[]) => ({
    type:ActionTypeDocument.LOAD_FILL_METADATA_FIELDS,
    payload:metadataFields
})
export const cleanMetadatas = () => ({
    type:ActionTypeDocument.CLEAN_METADATAS,
})
export const startUpdateDocumentTypeMetadata = (uuid:string,data:IRequestDocumentTypeUpdate) =>  async (dispatch:Dispatch<ActionsDocument | ActionsUi >,getState:() => RootState) =>{
    try {
        dispatch(startLoadingMetadata())
        const document = getState().document.currentDocument.document
        const resp = await updateDocumentTypeMetadataService(document?.uuid  as string,data);
        if(resp.status === 200){
            dispatch({
                type:ActionTypeDocument.CLEAN_METADATAS
            })
            dispatch({
                type:ActionTypeDocument.LOAD_METADATA_FIELDS,
                payload:resp.data.metadata
            })
            dispatch({
                type:ActionTypeDocument.SET_CURRENT_DOCUMENT,
                payload:resp.data.document
            })
            Toast.fire({icon:"success",title:`Tipo de documento actualizado `})
        }
    } catch (error) {
        Toast.fire({icon:"error",title:`Algo salio mal `})
        console.log(error)
    }finally{
        dispatch(finishLoadingMetadata())
    }
}
