import { Toast } from '../../utils/sweetAlertMix';
import { Dispatch } from 'redux';
import { ActionsAuth } from '../actions-interfaces/IActionsAuth';
import { IRespAttributes } from '../../interfaces/auth/IProfile';
import { ActionTypeAuth } from "../action-types";
import { IToken } from '../../interfaces/auth/IToken';
import jwt_decode from 'jwt-decode';
import { addSecurityKey, getInformationProfile } from '../../services/users/userServices';



export const loadTokenUser = (token:string) => {

    const tokenInfo = jwt_decode<IToken>(token)
    // console.log(token)
    localStorage.setItem("token",token)

    return {
        type:ActionTypeAuth.LOAD_USER_TOKEN,
        payload:tokenInfo
    }
}
export const logoutUser = () => ({
    type:ActionTypeAuth.LOGOUT
})

export const startLoadingProfileInformation = (sub: string | undefined) => async (dispatch: Dispatch<ActionsAuth | ActionsAuth>) => {
    try {

        const resp = await getInformationProfile(sub)
        if (resp.status === 200) {
            dispatch(
                {
                    type: ActionTypeAuth.LOAD_USER_INFORMATION,
                    payload: resp.data
                }
            )
        }
    } catch (error) {
        Toast.fire({ icon: "error", title: `Algo salió mal` })
        console.log(error)
    }
}


export const startAddSecurityKey = (SecurityKey: IRespAttributes, sub: string, existed: boolean) => async (dispatch: Dispatch<ActionsAuth | ActionsAuth>) => {

    try {
        const resp = await addSecurityKey(SecurityKey, sub);
        if (resp.status === 200) {
            const preview = resp.data.results?.code
            preview === 0 && dispatch({ type: ActionTypeAuth.EXISTED_SECURITY_KEY })

            Toast.fire({ icon: "success", title: existed ? 'Security Key Actualizado con exito' : 'Security Key Creado con exito' })
        }

    } catch (error) {

        Toast.fire({ icon: "error", title: `Algo salió mal` })
        console.log(error)
    }
}