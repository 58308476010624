import { axiosInstance } from "../../utils/axiosInstance";
import {  IDocument, IRequestDocumentTypeUpdate, IResponseDocument, IResponseDocumentTypeUpdate } from '../../interfaces/documents/IDocument';
import { IMetadataField } from '../../interfaces/metadata/IMetadataField';
import { ISignature } from '../../interfaces/documents/ISignature';

// const searchDocumentService = (letter:string) =>{
//     // Temporlmente lucira asi 
//     return axiosInstance.get<Search>(`https://www.omdbapi.com/?s=${letter}&apikey=eb4b6532`) 
// }
const getAllDocumentsService = (page:number) =>{
    return axiosInstance.get<IResponseDocument>(`${process.env.REACT_APP_URL_CONTENT}documents?size=10&page=${page}`)
}
const getDetailDocumentService = (id:string) =>{
    return axiosInstance.get<IDocument>(`${process.env.REACT_APP_URL_CONTENT}documents/${id}`)
}
const updateMetadataDocumentService = (uuid:string,data:any) =>{
    
    return axiosInstance.put<IMetadataField[]>(`${process.env.REACT_APP_URL_CONTENT}documents/${uuid}/metadata`,data)
}

const updateDocumentTypeMetadataService = (uuid:string,data:IRequestDocumentTypeUpdate) =>{
    return axiosInstance.put<IResponseDocumentTypeUpdate>(`${process.env.REACT_APP_URL_CONTENT}documents/${uuid}/update_type`,data)
}
const getAllSignautresService = (uuidDocument:string) =>{
    return axiosInstance.get<ISignature[]>(`${process.env.REACT_APP_URL_CONTENT}documents/${uuidDocument}/signature`)
}
export {
    getDetailDocumentService,
    getAllDocumentsService,
    updateMetadataDocumentService,
    updateDocumentTypeMetadataService,
    getAllSignautresService
}