
import { Card } from '../../../../components/cards/Card';
import {  FormControlLabel, Radio, Button, FormControl, FormLabel, RadioGroup } from '@mui/material';
import { DragAndDropZone } from '../../../../components/files/DragAndDropZone';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../../state/reducers/index';
import { useDragAndDrop } from '../../../../hooks/useDragAndDrop';
import { FileCharged } from '../../../../components/files/FileCharged';
import { useState, useEffect } from 'react';
import { PanelStatus } from './PanelStatus';
import Swal from 'sweetalert2';
import { startUploadBatch, startProcessBatch, startDeleteBatch, startFillBatch, startCheckFilesBatch } from '../../../../state/actions-creators/batch';
import { IBatch } from '../../../../interfaces/batch/Ibatch';
import { useNavigate } from 'react-router';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { MyTextField } from '../../../../components/fields';
import { MyFolderField } from '../../../../components/fields/custom-fields/folder-field/MyFolderField';
import { BatchStatus } from '../../../../constants/batchStatus';

interface FormBatchData extends IBatch{
    parent_path:string ,
    name:string ,
    type_load:string ,
    remote_bucket:string ,
    remote_path:string ,
    files:File[] 
}



interface PropsFormBatch   {
    id?:string;
    isDetail?:boolean;
    batchDetail?:IBatch ;
}



const initialForm:FormBatchData = {
    id:0,
    name:"",
    parent_path:"",
    type_load:"carga_local",
    files:[],
    remote_bucket:"",
    remote_path:"",
    status:3,
    folder_id:undefined,
    

}

export const FormBatch = ({isDetail=false,batchDetail}:PropsFormBatch) => {
    const dispatch = useDispatch()
    const [showDropZone, setShowDropZone] = useState<boolean>(true)
    const {currentBatch:{status:{batchStatus},filesFilled}} = useSelector((state:RootState) => state.batch)
    const navigate = useNavigate();
    const dropZoneState = useDragAndDrop(100)
    const {myFiles,removeFile,resetFiles} = dropZoneState;

    const {loading} = useSelector((state:RootState) => state.ui) 

    const onSubmit =(data:IBatch)=>{
        if(data.type_load === "carga_local"){
            if(myFiles.length === 0){
                Swal.fire("Info","Al menos adjunte 1 archivo","info")
            }else{
                data.files = myFiles;
                dispatch(startUploadBatch(data,navigate))
            }
        }else{
            delete data.files;
            console.log(data)
            dispatch(startUploadBatch(data,navigate))
        }

            
        
    }
    useEffect(() => {
        if(batchDetail){
            dispatch(startCheckFilesBatch(batchDetail.id))
        }
    
    }, [batchDetail,dispatch])
    return (
        <Card title="Carga masiva"  flexDirection="column">
            <Formik
                initialValues={isDetail && batchDetail !== undefined ?  batchDetail : initialForm }
                onSubmit={onSubmit}

                validationSchema={Yup.object({
                    parent_path:Yup.string()
                                    .required("Campo requerido"),
                    name:Yup.string()
                            .required("Campo requerido"),
                    type_load:Yup.string()
                })}
            >
            {({values,setFieldValue}) =>(
                
                <Form noValidate className="batch__container">      
            
                    <div className="batch__container-inputs-panel">
                        <div className="batch__container-inputs">
                            <div className="batch__workspace-input-item">
                                <MyFolderField
                                    disabled={isDetail}
                                    disabledFolder={isDetail}
                                    label="Espacio de trabajo" 
                                    name="parent_path" 
                                    folderId={batchDetail?.folder_id || null}
                                    inputProps={
                                        { readOnly: true, }
                                    }
                                    setFieldValue={setFieldValue}
                                />
                                
                            </div>
                            <div className="batch__workspace-input-item">
                                <h5>Información Lote</h5>
                            </div>
                            <div className="batch__workspace-input-item">
                                <MyTextField
                                    disabled={isDetail} 
                                    label="Nombre lote"
                                    name="name"
                                />
                            </div>
                            <div className="batch__workspace-input-item">
                            <FormControl component="fieldset">
                                <FormLabel component="legend">Tipo de carga</FormLabel>
                                    <RadioGroup 
                                        name="type_load"
                                        value={values.type_load} 
                                        onChange={(e) => {
                                            setFieldValue("type_load", e.currentTarget.value)
                                            if(e.target.value==="carga_local"){
                                                setShowDropZone(true)
                                            }else{
                                                setShowDropZone(false)
                                                resetFiles()
                                            }
                                        }}>
                                        <FormControlLabel  disabled={isDetail} value="carga_local" control={<Radio />} label="Carga local" />
                                        <FormControlLabel disabled={isDetail} value="carga_remota" control={<Radio />} label="Carga remota" />
                                    </RadioGroup>
                                </FormControl>
                            </div>
                        {(showDropZone === false || batchDetail?.type_load === 'carga_remota') &&
                        <>
                            <div className="batch__workspace-input-item">
                                <MyTextField 
                                    disabled={isDetail} 
                                    name="remote_bucket"
                                    label="Bucket remoto" 
                                />
                            </div>
                            <div className="batch__workspace-input-item">
                                <MyTextField
                                    disabled={isDetail}  
                                    name="remote_path"
                                    label="Path remoto"
                                />
                            </div>
                        </>
                        }
                        </div>
                    
                        {
                        isDetail  && batchDetail !== undefined && batchStatus !== null  && batchStatus.status === BatchStatus.PENDIENTE &&
                            <div className="batch__container-start-process">
                                <Button type="button" onClick={() => dispatch(startDeleteBatch(batchDetail,navigate))} color="error" variant="contained" >Eliminar</Button>
                            </div>
                        }
                        {
                        isDetail  && batchDetail !== undefined   && batchStatus !== null && batchStatus.status === BatchStatus.PENDIENTE  &&
                            <div className="batch__container-start-process">
                                <Button type="button" disabled={!Boolean(filesFilled !== 0)} onClick={() => dispatch(startProcessBatch(batchDetail?.id))} variant="contained" >Iniciar proceso</Button>
                            </div>
                        }
                        {
                        isDetail  && batchDetail !== undefined && batchStatus !== null  && batchStatus.status === BatchStatus.PENDIENTE && batchDetail.type_load ==='carga_remota'  && filesFilled === 0 &&
                            <div className="batch__container-start-process">
                                <Button type="button" onClick={() => dispatch(startFillBatch(batchDetail.id))} color="warning" variant="contained" >Cargar lote</Button>
                            </div>
                        }
                        {
                        isDetail  && batchDetail !== undefined && batchStatus !== null && batchStatus.status === BatchStatus.PARCIAL &&
                            <div className="batch__container-start-process">
                                <Button type="button" onClick={() => dispatch(startProcessBatch(batchDetail?.id))} color='warning' variant="contained" >Reprocesar</Button>
                            </div>
                        }
                    
                    </div>         
                        {
                        isDetail===true && batchDetail && 
                            <PanelStatus batch={batchDetail} />
                    
                        }
                    {  
                    //      Muestro esto solo si es para crear documento
                    isDetail===false  &&
                        <>
                        { showDropZone &&
                                <div className="batch__container-dropzone">
                                    <DragAndDropZone loading={loading} dropZoneState={dropZoneState} />
                                </div>
                        }
                                
                                {!!myFiles.length && 
                                <>
                                    <span className="batch__container-total-files">Total archivos: {myFiles.length}</span>
                                    <div className="batch__container-files">

                                        { 
                                        myFiles.map((file,index:number) => (
                                            <div  key={`${index}`} className="batch__item-file-charged">
                                            <FileCharged  preview={false}  file={file} removeFile={removeFile} />                 
                                            </div>
                                            ))
                                        }
                                    </div>
                                    
                                
                                </>
                            
                                }
                                
                                <div className="batch__container-button">

                                    <Button type="submit" size="large" color="primary" variant="contained" >Crear Lote</Button>
                                </div>
                            
                    </>
                    }

                </Form>
            )}
            </Formik>

        </Card>
    )
}
