
import { ActionTypesAdministration } from '../action-types/administrationTypes';
import { IList, IDetailList } from '../../interfaces/maintainers/ILists';
import { Dispatch } from 'redux';
import { ICompany } from '../../interfaces/maintainers/IComapny';
import {  addDetailListService, addListService, deleteDetailListService, deleteListService, getAllListsService, getDetailListService, updateDetailListService, updateListService } from '../../services/maintainers/listsServices';
import { Toast } from '../../utils/sweetAlertMix';
import Swal from "sweetalert2";
import { addCompanyService, deleteCompanyService, getAllComapniesService, updateCompanyService } from '../../services/maintainers/companiesServices';

import { finishLoadingList, stratLoadingList, startLoading, finishLoading } from './ui';
import { addOrganizationService, deleteOrganizationService, getAllOrganizationsService, getOrganizationsByCompanyService, updateOrganizationService } from '../../services/maintainers/organizationsServices';
import { IOrganization } from '../../interfaces/maintainers/IOrganization';
import { addParameterService, deleteParameter, getAllParametersService, updateParameterService } from '../../services/maintainers/parametersServices';
import { IParameter } from '../../interfaces/maintainers/IParameter';
import { ActionsAdministration, ActionsUi } from '../actions-interfaces';
import { addFolderService, getChildrenFolderService, getRootFoldersService } from '../../services/documents/foldersServices';
import { addDocumentTypeService, deleteDocumentTypeService, getAllActiveDocumentTypesService, getAllDocumentTypesService, getDetailDocumentTypeService, updateDocumentTypeService } from '../../services/maintainers/documentsTypesServices';
import { IDocumentType, IMetadata } from '../../interfaces/maintainers/IDocumentType';
import { addMetadataService, deleteMetadataService, getMetadataByDocumentTypeService, updateMetadataService } from '../../services/maintainers/metadataDocumentServices';
import { IFolder } from '../../interfaces/documents/IFolders';
import { getModelsService, updateStatusModelService } from '../../services/entity/modelsServices';

// Seccion Lists
export const stratLoadingAllLists = () =>  async (dispatch:Dispatch<ActionsAdministration | ActionsUi>) =>{
    try {
        dispatch(stratLoadingList())
        const resp = await getAllListsService();
        dispatch(finishLoadingList())
        if(resp.status === 200){
            dispatch({
                type:ActionTypesAdministration.LOAD_LISTS,
                payload:resp.data
            })
        }
    } catch (error) {
        dispatch(finishLoadingList())
        console.log(error)
    }
}
export const startAddList = (list:IList) => async(dispatch:Dispatch<ActionsAdministration | ActionsUi>) =>{
    try {
        dispatch(stratLoadingList())
        const resp = await addListService(list)
        dispatch(finishLoadingList())
        if(resp.status===200){
            dispatch({
                type:ActionTypesAdministration.ADD_LIST,
                payload:resp.data
            })
            Toast.fire({icon:"success",title:`Lista ${list.namespace} creada `})
        }else{
            Toast.fire({icon:"error",title:`Algo salió mal`})
        }
    } catch (error) {
        dispatch(finishLoadingList())
        console.log(error)
        Toast.fire({icon:"error",title:`Algo salió mal`})
    }
}
export const startUpdateList = (list:IList) => async(dispatch:Dispatch<ActionsAdministration | ActionsUi>) =>{
    try {
        dispatch(stratLoadingList())
        const resp = await updateListService(list.id,list)
        dispatch(finishLoadingList())
        if(resp.status === 200){
            dispatch({
                type:ActionTypesAdministration.UPDATE_LIST,
                payload:resp.data
            })
            Toast.fire({icon:"success",title:`Lista ${list.namespace} actualizada `})
        }
    } catch (error) {
        dispatch(finishLoadingList())
        console.log(error)
        Toast.fire({icon:"error",title:`Algo salió mal`})
    }
}
export const startDeleteList = (list:IList) => async(dispatch:Dispatch<ActionsAdministration | ActionsUi>) =>{
    try {
        
        Swal.fire({
            icon:"warning",
            title:"¿Quieres eliminar la lista?",
            text:`${list.namespace}`,
            input:"checkbox",
            inputValidator: (result):any =>{
                return !result && 'Necesita marcar la casilla'
            },
            inputPlaceholder:"Si estoy deacuerdo",
            confirmButtonColor:"#d33",
            confirmButtonText:"Eliminar",
            showCancelButton:true,
            cancelButtonText:"Cancelar",
            cancelButtonColor:"#3085d6"
            
        }).then( async (result) =>{
            if(result.isConfirmed){
                dispatch(stratLoadingList())
                const resp = await deleteListService(list)
                dispatch(finishLoadingList())
                if(resp.status === 204){
                    dispatch({
                        type:ActionTypesAdministration.DELETE_LIST,
                        payload:list.id
                    })
                    Toast.fire({icon:"success",title:`Lista ${list.namespace} eliminada `})
                }
                    
            }
        })
            
    } catch (error) {
        dispatch(finishLoadingList())
        console.log(error)
        Toast.fire({icon:"error",title:`Algo salió mal`})
    }
}
export const stratLoadingDetailList = (idDetaiList:number) =>  async (dispatch:Dispatch<ActionsAdministration | ActionsUi>) =>{
    try {
        dispatch(stratLoadingList())
        const resp = await getDetailListService(idDetaiList);
        dispatch(finishLoadingList())
        if(resp.status === 200){
            dispatch({
                type:ActionTypesAdministration.LOAD_DETAIL_LIST,
                payload:resp.data
            })
        }
    } catch (error) {
        dispatch(finishLoadingList())
    }
}
export const startAddDetailList = (detailList:IDetailList) => async(dispatch:Dispatch<ActionsAdministration | ActionsUi>) =>{
    try {
        dispatch(stratLoadingList())
        const resp = await addDetailListService(detailList)
        dispatch(finishLoadingList())
        if(resp.status===200){
            dispatch({
                type:ActionTypesAdministration.ADD_DETAIL_LIST,
                payload:resp.data
            })
            Toast.fire({icon:"success",title:`Detalle ${detailList.code} creado `})
        }else{
            Toast.fire({icon:"error",title:`Algo salió mal`})
        }
    } catch (error) {
        dispatch(finishLoadingList())
        console.log(error)
        Toast.fire({icon:"error",title:`Algo salió mal`})
    }
}
export const startUpdateDetailList = (detailList:IDetailList) => async(dispatch:Dispatch<ActionsAdministration | ActionsUi>) =>{
    try {
        dispatch(stratLoadingList())
        const resp = await updateDetailListService(detailList)
        dispatch(finishLoadingList())
        if(resp.status === 200){
            dispatch({
                type:ActionTypesAdministration.UPDATE_DETAIL_LIST,
                payload:resp.data
            })
            Toast.fire({icon:"success",title:`Detalle ${detailList.code} actualizado `})
        }
    } catch (error) {
        dispatch(finishLoadingList())
        console.log(error)
        Toast.fire({icon:"error",title:`Algo salió mal`})
    }
}
export const startDeleteDetailList = (detailList:IDetailList) => async(dispatch:Dispatch<ActionsAdministration | ActionsUi>) =>{
    try {
        
        Swal.fire({
            icon:"warning",
            title:"¿Quieres eliminar el detalle de lista?",
            text:`${detailList.code}`,
            input:"checkbox",
            inputValidator: (result):any =>{
                return !result && 'Necesita marcar la casilla'
            },
            inputPlaceholder:"Si estoy deacuerdo",
            confirmButtonColor:"#d33",
            confirmButtonText:"Eliminar",
            showCancelButton:true,
            cancelButtonText:"Cancelar",
            cancelButtonColor:"#3085d6"
            
        }).then( async (result) =>{
            if(result.isConfirmed){
                dispatch(stratLoadingList())
                const resp = await deleteDetailListService(detailList)
                dispatch(finishLoadingList())
                if(resp.status === 204){
                    dispatch({
                        type:ActionTypesAdministration.DELETE_DETAIL_LIST,
                        payload:detailList.id
                    })
                    Toast.fire({icon:"success",title:`Detalle ${detailList.code} eliminado `})
              
                }
                    
            }
        })
            
    } catch (error) {
        dispatch(finishLoadingList())
        console.log(error)
        Toast.fire({icon:"error",title:`Algo salió mal`})
    }
}
// Fin seccion lists
// Seccion Companies
export const stratLoadingCompanies = () =>  async (dispatch:Dispatch<ActionsAdministration | ActionsUi >) =>{
    try {
        dispatch(stratLoadingList())
        const resp = await getAllComapniesService();
        dispatch(finishLoadingList())
        if(resp.status === 200){
            dispatch({
                type:ActionTypesAdministration.LOAD_COMPANIES,
                payload:resp.data
            })
        }
    } catch (error) {
        dispatch(finishLoadingList())
        console.log(error)
    }
}

export const startAddCompany = (company:ICompany) => async (dispatch:Dispatch<ActionsAdministration | ActionsUi>) =>{
    try {
        dispatch(stratLoadingList())
        const resp = await addCompanyService(company);
        dispatch(finishLoadingList())
        if(resp.status === 200){
            dispatch({
                type:ActionTypesAdministration.ADD_COMPANY,
                payload:resp.data
            })
            Toast.fire({icon:"success",title:`Empresa ${company.company} creada `})
        }
    } catch (error) {
        dispatch(finishLoadingList())
        console.log(error)
    }
}
export const startUpdateCompany = (company:ICompany) => async(dispatch:Dispatch<ActionsAdministration | ActionsUi>) =>{
    try {
        dispatch(stratLoadingList())
        const resp = await updateCompanyService(company.id,company)
        dispatch(finishLoadingList())
        if(resp.status === 200){
            dispatch({
                type:ActionTypesAdministration.UPDATE_COMPANY,
                payload:resp.data
            })
            Toast.fire({icon:"success",title:`Empresa ${company.company} actualizada `})
        }
    } catch (error) {
        dispatch(finishLoadingList())
        console.log(error)
        Toast.fire({icon:"error",title:`Algo salió mal`})
    }
}
export const startDeleteCompany = (company:ICompany) => async(dispatch:Dispatch<ActionsAdministration | ActionsUi>) =>{
    try {
        
        Swal.fire({
            icon:"warning",
            title:"¿Quieres eliminar la empresa?",
            text:`${company.company}`,
            input:"checkbox",
            inputValidator: (result):any =>{
                return !result && 'Necesita marcar la casilla'
            },
            inputPlaceholder:"Si estoy deacuerdo",
            confirmButtonColor:"#d33",
            confirmButtonText:"Eliminar",
            showCancelButton:true,
            cancelButtonText:"Cancelar",
            cancelButtonColor:"#3085d6"
            
        }).then( async (result) =>{
            if(result.isConfirmed){
                dispatch(stratLoadingList())
                const resp = await deleteCompanyService(company.id)
                dispatch(finishLoadingList())
                if(resp.status === 204){
                    dispatch({
                        type:ActionTypesAdministration.DELETE_COMPANY,
                        payload:company.id
                    })
                    Toast.fire({icon:"success",title:`Empresa ${company.company} eliminada `})
                }
                    
            }
        })
            
    } catch (error) {
        dispatch(finishLoadingList())
        console.log(error)
        Toast.fire({icon:"error",title:`Algo salió mal`})
    }
}
// Fin seccion companies
// Seccion organizations
export const stratLoadingOrganizationsByCompany = (idCompany:number) =>  async (dispatch:Dispatch<ActionsAdministration | ActionsUi >) =>{
    try {
        dispatch(stratLoadingList())
        const resp = await getOrganizationsByCompanyService(idCompany);
        dispatch(finishLoadingList())
        if(resp.status === 200){
            dispatch({
                type:ActionTypesAdministration.LOAD_ORGANIZATIONS,
                payload:resp.data
            })
        }
    } catch (error) {
        dispatch(finishLoadingList())
        console.log(error)
    }
}
export const stratLoadingOrganizations = () =>  async (dispatch:Dispatch<ActionsAdministration | ActionsUi >) =>{
    try {
        dispatch(stratLoadingList())
        const resp = await getAllOrganizationsService();
        dispatch(finishLoadingList())
        if(resp.status === 200){
            dispatch({
                type:ActionTypesAdministration.LOAD_ORGANIZATIONS,
                payload:resp.data
            })
        }
    } catch (error) {
        dispatch(finishLoadingList())
        console.log(error)
    }
}

export const startAddOrganization = (organization:IOrganization) => async (dispatch:Dispatch<ActionsAdministration | ActionsUi>) =>{
    try {
        dispatch(stratLoadingList())
        const resp = await addOrganizationService(organization);
        dispatch(finishLoadingList())
        if(resp.status === 200){
            dispatch({
                type:ActionTypesAdministration.ADD_ORGANIZATION,
                payload:resp.data
            })
            Toast.fire({icon:"success",title:`Organización ${organization.name} creada `})
        }
    } catch (error) {
        dispatch(finishLoadingList())
        console.log(error)
    }
}
export const startUpdateOrganization = (organization:IOrganization) => async(dispatch:Dispatch<ActionsAdministration | ActionsUi>) =>{
    try {
        dispatch(stratLoadingList())
        const resp = await updateOrganizationService(organization.id,organization)
        dispatch(finishLoadingList())
        if(resp.status === 200){
            dispatch({
                type:ActionTypesAdministration.UPDATE_ORGANIZATION,
                payload:resp.data
            })
            Toast.fire({icon:"success",title:`Organización ${organization.name} actualizada `})
        }
    } catch (error) {
        dispatch(finishLoadingList())
        console.log(error)
        Toast.fire({icon:"error",title:`Algo salió mal`})
    }
}
export const startDeleteOrganization = (organization:IOrganization) => async(dispatch:Dispatch<ActionsAdministration | ActionsUi>) =>{
    try {
        
        Swal.fire({
            icon:"warning",
            title:"¿Quieres eliminar la organización?",
            text:`${organization.name}`,
            input:"checkbox",
            inputValidator: (result):any =>{
                return !result && 'Necesita marcar la casilla'
            },
            inputPlaceholder:"Si estoy deacuerdo",
            confirmButtonColor:"#d33",
            confirmButtonText:"Eliminar",
            showCancelButton:true,
            cancelButtonText:"Cancelar",
            cancelButtonColor:"#3085d6"
            
        }).then( async (result) =>{
            if(result.isConfirmed){
                dispatch(stratLoadingList())
                const resp = await deleteOrganizationService(organization.id)
                dispatch(finishLoadingList())
                if(resp.status === 204){
                    dispatch({
                        type:ActionTypesAdministration.DELETE_ORGANIZATION,
                        payload:organization.id
                    })
                    Toast.fire({icon:"success",title:`Organización ${organization.name} eliminada `})
                }
                    
            }
        })
            
    } catch (error) {
        dispatch(finishLoadingList())
        console.log(error)
        Toast.fire({icon:"error",title:`Algo salió mal`})
    }
}
// Fin seccion organizations
// Seccion parameters
export const startLoadingParameters = () =>  async (dispatch:Dispatch<ActionsAdministration | ActionsUi >) =>{
    try {
        dispatch(stratLoadingList())
        const resp = await getAllParametersService();
        dispatch(finishLoadingList())
        if(resp.status === 200){
            dispatch({
                type:ActionTypesAdministration.LOAD_PARAMETERS,
                payload:resp.data
            })
        }
    } catch (error) {
        dispatch(finishLoadingList())
        console.log(error)
    }
}

export const startAddParameter = (parameter:IParameter) => async (dispatch:Dispatch<ActionsAdministration | ActionsUi>) =>{
    try {
        dispatch(stratLoadingList())
        console.log("PARAMETER ",parameter)
        const resp = await addParameterService(parameter);
        dispatch(finishLoadingList())
        if(resp.status === 200){
            dispatch({
                type:ActionTypesAdministration.ADD_PARAMETER,
                payload:resp.data
            })
            Toast.fire({icon:"success",title:`Parametro ${parameter.namespace} creado `})
        }
    } catch (error) {
        Toast.fire({icon:"error",title:`Algo salió mal`})
        dispatch(finishLoadingList())
        console.log(error)
    }
}
export const startUpdateParameter = (parameter:IParameter) => async(dispatch:Dispatch<ActionsAdministration | ActionsUi>) =>{
    try {
        dispatch(stratLoadingList())
        const resp = await updateParameterService(parameter)
        dispatch(finishLoadingList())
        if(resp.status === 200){
            dispatch({
                type:ActionTypesAdministration.UPDATE_PARAMETER,
                payload:resp.data
            })
            Toast.fire({icon:"success",title:`Parametro ${parameter.namespace} actualizado `})
        }
    } catch (error) {
        dispatch(finishLoadingList())
        console.log(error)
        Toast.fire({icon:"error",title:`Algo salió mal`})
    }
}
export const startDeleteParameter = (parameter:IParameter) => async(dispatch:Dispatch<ActionsAdministration | ActionsUi>) =>{
    try {
        
        Swal.fire({
            icon:"warning",
            title:"¿Quieres eliminar el parametro?",
            text:`${parameter.namespace}`,
            input:"checkbox",
            inputValidator: (result):any =>{
                return !result && 'Necesita marcar la casilla'
            },
            inputPlaceholder:"Si estoy deacuerdo",
            confirmButtonColor:"#d33",
            confirmButtonText:"Eliminar",
            showCancelButton:true,
            cancelButtonText:"Cancelar",
            cancelButtonColor:"#3085d6"
            
        }).then( async (result) =>{
            if(result.isConfirmed){
                dispatch(stratLoadingList())
                const resp = await deleteParameter(parameter)
                dispatch(finishLoadingList())
                if(resp.status === 204){
                    dispatch({
                        type:ActionTypesAdministration.DELETE_PARAMETER,
                        payload:parameter.id
                    })
                    Toast.fire({icon:"success",title:`Parametro ${parameter.namespace} eliminado`})
                }
                    
            }
        })
            
    } catch (error) {
        dispatch(finishLoadingList())
        console.log(error)
        Toast.fire({icon:"error",title:`Algo salió mal`})
    }
}
// Fin seccion parameters
// Seccion folders
export const startLoadingFolders = (idFolder?:number) =>  async (dispatch:Dispatch<ActionsAdministration | ActionsUi>) =>{
    try {
        dispatch(stratLoadingList())
        if (idFolder){
            const resp = await getChildrenFolderService(idFolder);
            if(resp.status === 200){
                dispatch({
                    type:ActionTypesAdministration.LOAD_FOLDERS,
                    payload:resp.data
                })
            }
        }else{
            const resp = await getRootFoldersService();
            if(resp.status === 200){
                dispatch({
                    type:ActionTypesAdministration.LOAD_FOLDERS,
                    payload:resp.data
                })
            }
        }
        dispatch(finishLoadingList())
    } catch (error) {
        dispatch(finishLoadingList())
        console.log(error)
    }
}
export const startAddFolder = (folder:IFolder) =>  async (dispatch:Dispatch< any | ActionsUi>) =>{
    try {
            dispatch(stratLoadingList())
    
            const resp = await addFolderService(folder);
            if(resp.status === 200){
                dispatch(startLoadingFolders(0))
                Toast.fire({icon:"success",title:`Carpeta ${folder.name} creada`})
            }

        dispatch(finishLoadingList())
    } catch (error) {
        dispatch(finishLoadingList())
        Toast.fire({icon:"error",title:`Algo salió mal`})
        console.log(error)
    }
}

// Fin seccion folders
// Seccion document types
export const startAddDocumentType = (documentType:IDocumentType,navigate:any) => async (dispatch:Dispatch<ActionsAdministration | ActionsUi>) =>{
    try {
        dispatch(stratLoadingList())
 
        const resp = await addDocumentTypeService(documentType)
        dispatch(finishLoadingList())
        if(resp.status === 200){
            navigate(`/admin/administracion/tipos-documentos/${resp.data.document_type_id}`)
            dispatch({
                type:ActionTypesAdministration.ADD_TYPE_DOCUMENT,
                payload:resp.data
            })
            Toast.fire({icon:"success",title:`Tipo de documento ${documentType.display_name} creado `})
        }
    } catch (error) {
        Toast.fire({icon:"error",title:`Algo salió mal`})
        dispatch(finishLoadingList())
        console.log(error)
    }
}
export const startUpdateDocumentType = (documentType:IDocumentType) => async (dispatch:Dispatch<ActionsAdministration | ActionsUi>) =>{
    try {
        dispatch(startLoading())
 
        const resp = await updateDocumentTypeService(documentType)
        dispatch(finishLoading())
        if(resp.status === 200){
            dispatch({
                type:ActionTypesAdministration.UPDATE_TYPE_DOCUMENT,
                payload:resp.data
            })
            dispatch({
                type:ActionTypesAdministration.SET_CURRENT_DOCUMENT_TYPE,
                payload:resp.data
            })
            
            Toast.fire({icon:"success",title:`Tipo de documento ${documentType.display_name} actualizado `})
        }
    } catch (error) {
        Toast.fire({icon:"error",title:`Algo salió mal`})
        console.log(error)
        dispatch(finishLoading())
    }
}
export const startLoadingModels = (project_id:string,dataset_id:string) =>  async (dispatch:Dispatch<ActionsAdministration | ActionsUi>) =>{
    try {
        dispatch(stratLoadingList())
        const resp = await getModelsService(project_id,dataset_id);
        dispatch(finishLoadingList())
        if(resp.status === 200){
            dispatch({
                type:ActionTypesAdministration.LOAD_MODELS,
                payload:resp.data
            })
        }
    } catch (error) {
        dispatch(finishLoadingList())
        console.log(error)
    }
}

export const startUpdateStatusModel = (project_id:string,model_id:string,status:string) => async(dispatch:Dispatch<ActionsAdministration | ActionsUi>) =>{
    try {
        const statusParsed = status === 'deployed' ? 'deploy' : 'undeploy'
        Swal.fire({
            icon:"warning",
            title:`¿Quieres hacer ${statusParsed} del modelo ${model_id}?`,
            text:`Este proceso puede tardar varios minutos`,
            input:"checkbox",
            inputValidator: (result):any =>{
                return !result && 'Necesita marcar la casilla'
            },
            inputPlaceholder:"Si estoy de acuerdo",
            confirmButtonColor:"#4da851",
            confirmButtonText:`${statusParsed}`,
            showCancelButton:true,
            cancelButtonText:"Cancelar",
            cancelButtonColor:"#3085d6"
            
        }).then( async (result) =>{
            if(result.isConfirmed){
                dispatch(stratLoadingList())
                const resp = await updateStatusModelService(project_id,model_id,statusParsed)
                dispatch(finishLoadingList())
                if(resp.status === 200){
                    Toast.fire({icon:"success",title:`Proceso de ${statusParsed} iniciado`})
                }
                    
            }
        })
            
    } catch (error) {
        dispatch(finishLoadingList())
        console.log(error)
        Toast.fire({icon:"error",title:`Algo salió mal`})
    }
}
export const cleanModels = () => ({
    type:ActionTypesAdministration.CLEAN_MODELS
})
export const startLoadingDocumentTypes = (page:number) =>  async (dispatch:Dispatch<ActionsAdministration | ActionsUi>) =>{
    try {
        dispatch(stratLoadingList())
        const resp = await getAllDocumentTypesService(page);
        dispatch(finishLoadingList())
        if(resp.status === 200){
            dispatch({
                type:ActionTypesAdministration.LOAD_TYPES_DOCUMENTS,
                payload:{
                    document_types:resp.data.document_types,
                    from:resp.data.from,
                    to:resp.data.to,
                    count:resp.data.count,
                    page:page,
                }
            })
        }
    } catch (error) {
        dispatch(finishLoadingList())
        console.log(error)
    }
}
export const startLoadingAllDocumentTypes = () =>  async (dispatch:Dispatch<ActionsAdministration | ActionsUi>) =>{
    try {
        dispatch(stratLoadingList())
        const resp = await getAllActiveDocumentTypesService();
        dispatch(finishLoadingList())
        if(resp.status === 200){
            dispatch({
                type:ActionTypesAdministration.LOAD_ACTIVE_DOUCUMENT_TYPES,
                payload:resp.data.document_types
            })
        }
    } catch (error) {
        dispatch(finishLoadingList())
        console.log(error)
    }
}
export const startLoadingDetailDocumentType = (documentTypeId:number) =>  async (dispatch:Dispatch<ActionsAdministration | ActionsUi>) =>{
    try {
        dispatch(startLoading())
        const resp = await getDetailDocumentTypeService(documentTypeId);
        dispatch(finishLoading())
        if(resp.status === 200){
            dispatch({
                type:ActionTypesAdministration.SET_CURRENT_DOCUMENT_TYPE,
                payload:resp.data
            })
        }
    } catch (error) {
        dispatch(finishLoading())
        console.log(error)
    }
}
export const startDeleteDocumentType = (documentType:IDocumentType,navigate:any) =>  async (dispatch:Dispatch<ActionsAdministration | ActionsUi>) =>{
    try {
        Swal.fire({
            icon:"warning",
            title:"¿Quieres eliminar el tipo de documento?",
            text:`${documentType.display_name}`,
            input:"checkbox",
            inputValidator: (result):any =>{
                return !result && 'Necesita marcar la casilla'
            },
            inputPlaceholder:"Si estoy deacuerdo",
            confirmButtonColor:"#d33",
            confirmButtonText:"Eliminar",
            showCancelButton:true,
            cancelButtonText:"Cancelar",
            cancelButtonColor:"#3085d6"
            
        }).then( async (result) =>{
            if(result.isConfirmed){
                dispatch(stratLoadingList())
                const resp = await deleteDocumentTypeService(documentType.document_type_id)
                dispatch(finishLoadingList())
                if(resp.status === 200){
                    dispatch({
                        type:ActionTypesAdministration.DELETE_TYPE_DOCUMENT,
                        payload:documentType.document_type_id
                    })
                    navigate("/admin/administracion/tipos-documentos")
                    Toast.fire({icon:"success",title:`Tipo de documento ${documentType.display_name} eliminado`})
                }
                    
            }
        })
    } catch (error) {
        dispatch(finishLoadingList())
        console.log(error)
    }
}
export const cleanDocumentType = () => ({
    type:ActionTypesAdministration.CLEAN_DOCUMENT_TYPES
})

export const startAddMetadata = (metadata:IMetadata,setModal:React.Dispatch<React.SetStateAction<boolean>>) => async (dispatch:Dispatch<ActionsAdministration | ActionsUi>) =>{
    try {
        dispatch(stratLoadingList())
        const resp = await addMetadataService(metadata)
        dispatch(finishLoadingList())
        if(resp.status === 200){
            dispatch({
                type:ActionTypesAdministration.ADD_METADATA,
                payload:resp.data
            })
            setModal(false)
            Toast.fire({icon:"success",title:`Metadata ${metadata.name} creada `})
        }
    } catch (error) {
        Toast.fire({icon:"error",title:`Algo salió mal`})
        dispatch(finishLoadingList())
        console.log(error)
    }
}
export const startUpdateMetadata = (metadata:IMetadata) => async (dispatch:Dispatch<ActionsAdministration | ActionsUi>) =>{
    try {
        dispatch(stratLoadingList())
 
        const resp = await updateMetadataService(metadata)
        dispatch(finishLoadingList())
        if(resp.status === 200){
            dispatch({
                type:ActionTypesAdministration.UPDATE_METADATA,
                payload:resp.data
            })
     
            Toast.fire({icon:"success",title:`Metadata ${metadata.name} actualizada `})
        }
    } catch (error) {
        Toast.fire({icon:"error",title:`Algo salió mal`})
        dispatch(finishLoadingList())
        console.log(error)
    }
}
export const startLoadingMetadataByDocumentType = (documentTypeId:number) =>  async (dispatch:Dispatch<ActionsAdministration | ActionsUi>) =>{
    try {
        dispatch(stratLoadingList())
        const resp = await getMetadataByDocumentTypeService(documentTypeId);
        dispatch(finishLoadingList())
        if(resp.status === 200){
            dispatch({
                type:ActionTypesAdministration.LOAD_METADATA,
                payload:resp.data
            })
        }
    } catch (error) {
        dispatch(finishLoadingList())
        console.log(error)
    }
}
export const startDeleteMeatadata = (metadata:IMetadata) =>  async (dispatch:Dispatch<ActionsAdministration | ActionsUi>) =>{
    try {
        Swal.fire({
            icon:"warning",
            title:"¿Quieres eliminar la metadata?",
            text:`${metadata.name}`,
            input:"checkbox",
            inputValidator: (result):any =>{
                return !result && 'Necesita marcar la casilla'
            },
            inputPlaceholder:"Si estoy deacuerdo",
            confirmButtonColor:"#d33",
            confirmButtonText:"Eliminar",
            showCancelButton:true,
            cancelButtonText:"Cancelar",
            cancelButtonColor:"#3085d6"
            
        }).then( async (result) =>{
            if(result.isConfirmed){
                dispatch(stratLoadingList())
                const resp = await deleteMetadataService(metadata.metadata_id)
                dispatch(finishLoadingList())
                if(resp.status === 200){
                    dispatch({
                        type:ActionTypesAdministration.DELETE_METADATA,
                        payload:metadata.metadata_id
                    })
                    Toast.fire({icon:"success",title:`Metadata ${metadata.name} eliminada`})
                }
                    
            }
        })
    } catch (error) {
        dispatch(finishLoadingList())
        console.log(error)
    }
}
export const setCurrentDocumentType = (documentType:IDocumentType) => (dispatch:Dispatch<ActionsAdministration | ActionsUi>) =>{
    dispatch({
        type:ActionTypesAdministration.SET_CURRENT_DOCUMENT_TYPE,
        payload:documentType
    })
}
// Fin seccion document types