import { Bar, BarChart, CartesianGrid, Cell, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { IBarsCount } from '../../../../interfaces/dashboard/IBarsCount';

interface Props{
  barsCount:IBarsCount[]
}

  const colors = [
    '#0088FE', 
    '#00C49F', 
    '#FFBB28', 
    '#f44336',
    '#e91e63',
    '#9c27b0',
    '#673ab7',
    '#3f51b5',
    '#2196f3',
    '#03a9f4',
    '#00bcd4',
    '#009688',
    '#4caf50',
    '#8bc34a',
    '#cddc39',
    '#ffeb3b',
    '#ffc107',
    '#ff9800',
    '#ff5722',];

export const BarChartDocuments = ({barsCount}:Props) => {
    return (
    <ResponsiveContainer width="100%" height={420}>
      <BarChart data={barsCount}
        margin={{ top: 0, right: -10, left: -10, bottom: 0 }}>
        <XAxis 
          dataKey="name" 
          height={160}  
          interval={0} 
          dx={20}
          dy={80}
          angle={70}
          // tick={<CustomizedAxisTick />}  
          />
      
        <YAxis yAxisId="right" orientation="left"  stroke="#E91E63" />
        <CartesianGrid strokeDasharray="3 3" />
        <Tooltip />
        <Bar yAxisId="right" dataKey="total" fill="#E91E63" name="Total">
          {barsCount.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={colors[index]} />
          ))}
        </Bar>
      
      </BarChart>
    </ResponsiveContainer>
    )
}
