
import { Dispatch } from 'redux';
import { addTaskContentService, getMyRequestsService, getMyTasksService, getTaskDetailService, getTaskHistoryService, signDocumentService, updateStatusTaskService } from '../../services/tasks/tasksServices';
import { ActionTypesTasks } from '../action-types';
import { ActionsUi } from '../actions-interfaces';
import { ActionsTasks } from '../actions-interfaces/IActionsTasks';
import { finishLoading, startLoading, stratLoadingList, finishLoadingList } from './ui';
import { ITaskForm, ITask } from '../../interfaces/tasks/ITask';
import { Toast } from '../../utils/sweetAlertMix';
import Swal from 'sweetalert2';
import { NavigateFunction } from 'react-router-dom';
import { ISignatureForm } from '../../interfaces/documents/ISignature';
import { FormikState } from 'formik';

export const startLoadingMyTasks= (page:number) =>  async (dispatch:Dispatch<ActionsTasks | ActionsUi>) =>{
    try {
    
        dispatch(stratLoadingList())
        const resp = await getMyTasksService(page);
        dispatch(finishLoadingList())
        if(resp.status === 200){
            dispatch({
                type:ActionTypesTasks.LOAD_TASKS,
                payload:{
                    tasks:resp.data.tasks,
                    from:resp.data.from,
                    to:resp.data.to,
                    count:resp.data.count,
                    page:page
                }
            })
        }
    } catch (error) {
        dispatch(finishLoadingList())
        console.log(error)
    }
}

export const startLoadingHistoryTask = (idTask:string) =>  async (dispatch:Dispatch<ActionsTasks | ActionsUi>) =>{
    try {
    
        dispatch(stratLoadingList())
        const resp = await getTaskHistoryService(idTask);
        dispatch(finishLoadingList())
        if(resp.status === 200){
            dispatch({
                type:ActionTypesTasks.LOAD_HISTORY_TASKS,
                payload:resp.data
            })
        }
    } catch (error) {
        dispatch(finishLoadingList())
        console.log(error)
    }
}
export const startLoadingMyRequests = (page:number) =>  async (dispatch:Dispatch<ActionsTasks | ActionsUi>) =>{
    try {
    
        dispatch(stratLoadingList())
        const resp = await getMyRequestsService(page);
        dispatch(finishLoadingList())
        if(resp.status === 200){
            dispatch({
                type:ActionTypesTasks.LOAD_TASKS,
                payload:{
                    tasks:resp.data.tasks,
                    from:resp.data.from,
                    to:resp.data.to,
                    count:resp.data.count,
                    page:page
                    
                }
            })
        }
    } catch (error) {
        dispatch(finishLoadingList())
        console.log(error)
    }
}
export const startLoadingDetailTask = (idTask:string) =>  async (dispatch:Dispatch<ActionsTasks | ActionsUi>) =>{
    try {
    
        dispatch(startLoading())
        const resp = await getTaskDetailService(idTask);
        dispatch(finishLoading())
     
        if(resp.status === 200){
            dispatch({
                type:ActionTypesTasks.SET_CURRENT_TASK,
                payload:resp.data
            })
        }
    } catch (error) {
        dispatch(finishLoading())
        console.log(error)
    }
}

export const startChangeStatusTask = (task:ITask,status:number) => async(dispatch:Dispatch<ActionsTasks | ActionsUi>) =>{
    try {
        const myTextStatus = status === 2 ? 'Aprobar' : 'Rechazar';
        Swal.fire({
            icon:"warning",
            title:`¿Quieres ${myTextStatus} la tarea?`,
            text:`${task.primary_key}`,
            input:"checkbox",
            inputValidator: (result):any =>{
                return !result && 'Necesita marcar la casilla'
            },
            inputPlaceholder:"Si estoy deacuerdo",
            confirmButtonColor: status === 2 ? '#28A745' : "#d33",
            confirmButtonText:myTextStatus,
            showCancelButton:true,
            cancelButtonText:"Cancelar",
            cancelButtonColor:"#3085d6"
            
        }).then( async (result) =>{
            if(result.isConfirmed){
                dispatch(stratLoadingList())
                const resp = await updateStatusTaskService(task,status);
                dispatch(finishLoadingList())
                if(resp.status === 200){
                    dispatch({
                        type:ActionTypesTasks.SET_CURRENT_TASK,
                        payload:resp.data
                    })
                    Toast.fire({icon:"success",title:`Tarea ${task.primary_key} Actualizada `})
                }
                    
            }
        })
            
    } catch (error) {
        dispatch(finishLoadingList())
        console.log(error)
        Toast.fire({icon:"error",title:`Algo salió mal`})
    }
}
export const startAddTask = (task:ITaskForm,resetForm?:() =>void,navigate?:NavigateFunction) => async(dispatch:Dispatch<ActionsTasks | ActionsUi>) =>{
    try {
        dispatch(startLoading())
        const resp = await addTaskContentService(task)
        dispatch(finishLoading())
        if(resp.status===200){
            dispatch({
                type:ActionTypesTasks.ADD_TASK,
                payload:resp.data
            })
            if(resetForm){
                resetForm()
            }
            if(navigate){
                navigate('/admin/mis-solicitudes')
            }
            Toast.fire({icon:"success",title:`Enviada`})
        }else{
            Toast.fire({icon:"error",title:`Algo salió mal`})
        }
    } catch (error) {
        dispatch(finishLoading())
        console.log(error)
        Toast.fire({icon:"error",title:`Algo salió mal`})
    }
}
export const startSignDocument = (
                                data:ISignatureForm,
                                resetForm:(nextState?: Partial<FormikState<any>>) => void,
                                setModal:(modal:boolean) => void
                                ) => async(dispatch:Dispatch<ActionsTasks | ActionsUi>) =>{
    dispatch(startLoading())
    const resp = await signDocumentService(data)
    dispatch(finishLoading())
    try { 
        console.log(resp.status)
        if(resp.status===200){
            dispatch({
                type:ActionTypesTasks.SET_CURRENT_TASK,
                payload:resp.data
            })
            resetForm()
            setModal(false)
            Toast.fire({icon:"success",title:`Documento firmado`})
        }else if (resp.status === 401){
            Toast.fire({icon:"error",title:`Firma incorrecta`})
        }else{
            Toast.fire({icon:"error",title:`Algo salió mal`})
        }
    

    } catch (error) {
        
        dispatch(finishLoading())
        console.log(error)

        Toast.fire({icon:"error",title:`Algo salió mal`})
    }
}

export const cleanCurrentTask = () => ({
    type:ActionTypesTasks.CLEAN_CURRENT_TASK,
})