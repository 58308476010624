import { Accordion, AccordionProps } from '@mui/material';
import React from 'react'
import { styled } from '@mui/material/styles';
// Componente custom para añadirle la linea al arbol de carpetas
export const CustomAccordion = styled((props: AccordionProps) => (
    <Accordion disableGutters elevation={0} square {...props} />
    ))(({ theme }) => ({
        width: '100%',
        border: `1px solid ${theme.palette.divider}`,
        // '&:not(:last-child)': {
        // borderBottom: 0,
        // },
        '&:before': {
        display: 'none',
        },
    }));
export const CustomAccordionWithoutBorder = styled((props: AccordionProps) => (
    <Accordion disableGutters elevation={0} square {...props} />
    ))(({ theme }) => ({
        width: '100%',
        border: `0px solid ${theme.palette.divider}`,
        // '&:not(:last-child)': {
        // borderBottom: 0,
        // },
        '&:before': {
        display: 'none',
        },
    }));
    