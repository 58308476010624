import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { IDocument } from '../../../../interfaces/documents/IDocument';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { CardWithListSkeleton } from './skeleton/CardWithListSkeleton';

interface PropsCardWithList {
    title:string,
    icon:IconDefinition,
    documents:IDocument[]
    loading:boolean;
    showName?:boolean;
}
export const CardWithList = ({title,icon,documents,loading,showName=true}:PropsCardWithList) => {
  
    return (
        <div className="dashboard__list-container">
            <div className="dashboard__list-section-title">
                <h4>{title}</h4>
                <hr className="base__hr-right-ease" />
            </div>
            <div className="dashboard__list-section-items">
                {
                    loading ===false
                    ? documents.map((document,index) => (
                        <div key={index}>
                        <div className="dashboard__list-item">
                            <div className="dashboard__list-name">
                                <FontAwesomeIcon icon={icon} />  <Link to={`/admin/mis-documentos/detalle-documento/${document.uuid}`}>{showName && document.user_name +' Subió'} {document.name}</Link>
                            </div>
                            <div className="dashboard__list-date">
                                <p>Creado el {moment(document.create_date+"+00:00").tz("America/Santiago").format("DD/MM/YYYY HH:mm")}</p>
                            </div>
                        </div>
                        <hr className="base__hr-right-left-ease" />
                        </div>
                ))
                :
                <CardWithListSkeleton icon={icon} rows={4} />
                }
            </div>
        </div>
    )
}
