
import { Card } from '../../../components/cards';
import { UploadDocument } from './components/UploadDocument';
import { useRef, useEffect } from 'react';
import { useDragAndDrop } from '../../../hooks/useDragAndDrop';
import { Form, Formik, FormikProps } from 'formik';
import { Button } from '@mui/material';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../state/reducers';
import { useGenerateFields } from '../../../hooks/useGenerateFields';
import { GenerateFields } from '../../../components/metadata/GenerateFields';
import { startUploadUnitaryBatch } from '../../../state/actions-creators/batch';
import { IUnitaryBatch } from '../../../interfaces/batch/Ibatch';
import { useNavigate } from 'react-router';
import { cleanCurrentDocument } from '../../../state/actions-creators/document';
;

export interface FormUnitaryLoadAuto{
    parent_path:string,
    folder_id:undefined,
    files:File | null,
    type_file:string,
    manual:boolean
}
const initialFormUnitaryLoadAuto:FormUnitaryLoadAuto = 
    {
        parent_path: '',
        folder_id: undefined,
        files:null,
        type_file:'',
        manual:false
    }

export const UploadDocumentView = () => {
    const dropZoneState = useDragAndDrop(1)
 
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {myFiles} = dropZoneState
    const { document:{currentDocument:{metadataFields}},
            ui:{loading},
            } = useSelector((state:RootState) => state)
    const {validationSchema,initialValues} = useGenerateFields({
        metadataFields:metadataFields
    })
    const refForm = useRef<FormikProps<FormUnitaryLoadAuto>>(null)
    useEffect(() => {
    
        return () => {
            //Limpio el documento acutal ya que contiene los metadatos actuales
            dispatch(cleanCurrentDocument())
            
        };
    }, [dispatch]);
    
    return (
        <>
        <Card title="Carga documento">
        <Formik
                    innerRef={refForm}
                    initialValues={initialFormUnitaryLoadAuto}
                    validationSchema={Yup.object({
                        parent_path: Yup.string().required('El campo es requerido'),
                        type_file: Yup.string().required('El campo es requerido'),
                        
                    })}
                    onSubmit={(values:FormUnitaryLoadAuto)=> {
                        if(myFiles.length === 0){
                            Swal.fire("Info","Adjunte un archivo","info")
                        }else{
                            values.files = myFiles[0];
                            dispatch(startUploadUnitaryBatch(values as unknown as IUnitaryBatch,navigate))
                            
                        }
                        

                    }}
                >
                    {
                        ({isValid,values}) =>(
                    <Form className='upload-documents__container-upload'>
                        <UploadDocument 
                            dropZoneStateProp={dropZoneState} 
                            />
                        {/* Validacion realizada ya que si es Auto quiere deicr que no necesitara renderizar metadatos a llenar  */}
                        { myFiles.length > 0 && values.type_file === "AUTO" &&
                        <div className="upload-documents__container-upload-submit">

                            <Button disabled={!isValid || loading} variant="contained" type="submit">Enviar</Button>
                        </div>
                        }
                    </Form>
                        )
                    }
        </Formik>
        <div className="upload-documents__container-form-metadata">
         
                {refForm.current !== null && metadataFields.length !== 0 && myFiles.length !==0 &&
                    <Formik
                        enableReinitialize
                        initialValues={{
                            ...initialFormUnitaryLoadAuto,
                            ...initialValues,
                
                        }}
                        validationSchema={validationSchema}
                        onSubmit={(values) => {
                            values.parent_path = refForm.current?.values.parent_path  as string
                            values.folder_id = refForm.current?.values.folder_id  
                            values.files = myFiles[0] as File
                            values.type_file = refForm.current?.values.type_file as string
                            values.manual  = refForm.current?.values.manual as boolean      
                            dispatch(startUploadUnitaryBatch(values as unknown as IUnitaryBatch,navigate))
                        }}
                        
                        >
                        {
                            (formik) =>(

                                <Form className='upload-documents__container-form-metadata-fields'>
                                        <GenerateFields  metadataFields={metadataFields} />
                                            <Button disabled={loading}  className='upload-documents__container-button-metadata'  variant='contained' type="submit">Enviar</Button>
                        
                                </Form>
                            )
                        }
                    </Formik>
                }
        </div>

        </Card>


        
        </>
    )
}
