import { axiosInstance } from "../../utils/axiosInstance";
import { IToken } from "../../interfaces/auth/IToken";
import { IBatch, IResponseBatch, IResponseFile, IUnitaryBatch } from '../../interfaces/batch/Ibatch';
import jwt_decode from 'jwt-decode';
import { IStatus } from '../../interfaces/batch/IStatusData';
import { AxiosResponse } from "axios";

const getAllBatchsService = (page:number) => {

    return axiosInstance.get<IResponseBatch>(`${process.env.REACT_APP_URL_BATCH}batchs?size=10&page=${page}`)
}
const getDetailBatchService = (id:number) =>{
    return axiosInstance.get<IBatch>(`${process.env.REACT_APP_URL_BATCH}batch/${id}`)
}
const uploadUnitaryBatchService = (batch:IUnitaryBatch) =>{
    const formData = new FormData() as any
  
    for (let [key, value] of Object.entries(batch)) {
        formData.append(key, value === undefined ? "" : value)
    }
    const params = {
        headers:{
            ContentType:"multipart/form-data"
        }
    }
    const resposne:Promise<AxiosResponse<IBatch>> = axiosInstance.post(`${process.env.REACT_APP_URL_BATCH}batchs/unitary`,formData as IUnitaryBatch,params)
    return resposne
}
const uploadBatchFilesService = (data:IBatch) =>{
    const formData = new FormData() as any
    formData.append("name",data.name  )
    formData.append("parent_path",data.parent_path)
    formData.append("type_load",data.type_load)
    formData.append("folder_id",data.folder_id)
    if(localStorage.getItem("token")  === null){
        throw new Error(`Error localstorage contacte a un administrador`);
    }
    const tokenInfo = jwt_decode<IToken>(localStorage.getItem("token") || "")
    formData.append("user_id",tokenInfo.sub)
    if(data.type_load === "carga_local"){

        data.files?.forEach(file =>{
            formData.append("files",file)
        })
    }else{
        formData.append("remote_bucket",data.remote_bucket)
        formData.append("remote_path",data.remote_path)
    }
    const params = {
        headers:{
            ContentType:"multipart/form-data"
        }
    }

    return axiosInstance.post(`${process.env.REACT_APP_URL_BATCH}batch-files`,formData as IBatch,params)
}
const getStatusBatchService = (idBatch:number | string) =>{
    return axiosInstance.get<IStatus>(`${process.env.REACT_APP_URL_BATCH}batch/${idBatch}/status`)
} 
const getFilesOfBatchService = (id:number,page:number) =>{
    return axiosInstance.get<IResponseFile>(`${process.env.REACT_APP_URL_BATCH}batch/${id}/files?size=10&page=${page}`)
}

const startProcessBatchService = (id:number) =>{
    return axiosInstance.post<IBatch>(`${process.env.REACT_APP_URL_BATCH}batch/${id}/process`)
}
const reProcessBatchService = (id:number) =>{
    return axiosInstance.post<IBatch>(`${process.env.REACT_APP_URL_BATCH}batch/${id}/reprocess`)
}

const deleteBatchService = (id:number) =>{
    return axiosInstance.delete(`${process.env.REACT_APP_URL_BATCH}batch/${id}`)
}
const fillBatchService = (idBatch:number) =>{
    return axiosInstance.post<IResponseFile>(`${process.env.REACT_APP_URL_BATCH}batch/${idBatch}/fill`)
}
const checkBatchFilesService = (idBatch:number) =>{
    return axiosInstance.get<IResponseFile>(`${process.env.REACT_APP_URL_BATCH}batch/${idBatch}/files?size=1&page=1`)
}
export {
    getAllBatchsService,
    uploadBatchFilesService,
    getDetailBatchService,
    getFilesOfBatchService,
    getStatusBatchService,
    startProcessBatchService,
    reProcessBatchService,
    deleteBatchService,
    checkBatchFilesService,
    fillBatchService,
    uploadUnitaryBatchService
}
