import { ITask, ITaskForm, IResponseTask } from '../../interfaces/tasks/ITask';
import { axiosInstance } from "../../utils/axiosInstance";
import {  AxiosResponse } from 'axios';
import { IDocument } from '../../interfaces/documents/IDocument';
import { IParameter } from '../../interfaces/parameters/IParameter';
import { ISignatureForm } from '../../interfaces/documents/ISignature';

const getAllTasksService = () =>{
    return axiosInstance.get(`${process.env.REACT_APP_URL_TASKS}tasks`)
}
const getMyRequestsService = (page:number) =>{
    return axiosInstance.get<IResponseTask>(`${process.env.REACT_APP_URL_TASKS}tasks/my_requests?size=10&page=${page}`)
}
const getMyTasksService = (page:number) =>{
    return axiosInstance.get<IResponseTask>(`${process.env.REACT_APP_URL_TASKS}tasks/my_tasks?size=10&page=${page}`)
}
const addTaskService = (data:ITaskForm) =>{
    const resposne:Promise<AxiosResponse<ITask>>  = axiosInstance.post(`${process.env.REACT_APP_URL_TASKS}tasks/${data.namespace}/${data.primary_key}`,data)
    return resposne
}
const addTaskContentService = (data:ITaskForm) =>{
    const resposne:Promise<AxiosResponse<ITask>>  = axiosInstance.post(`${process.env.REACT_APP_URL_CONTENT}documents/tasks/${data.namespace}/${data.primary_key}`,data)
    return resposne
}
const getTaskDetailService = (idTask:string) =>{
    // namespace:primarykey:id
    const idSplited = idTask.split(':')
    return axiosInstance.get<ITask>(`${process.env.REACT_APP_URL_TASKS}tasks/${idSplited[0]}/${idSplited[1]}/${idSplited[2]}`)
}

const getTaskHistoryService = (idTask:string) =>{
    // namespace:primarykey:id
    const idSplited = idTask.split(':')
    return axiosInstance.get<ITask[]>(`${process.env.REACT_APP_URL_TASKS}tasks/${idSplited[0]}/${idSplited[1]}`)    
}
const updateStatusTaskService = (task:ITask,status:number) =>{
    return axiosInstance.put<ITask>(`${process.env.REACT_APP_URL_TASKS}tasks/${task.namespace}/${task.primary_key}/${task.id}/${status}`)
}
const getPendingDocumentsService = (idTask:string) =>{

    return axiosInstance.get<IDocument[]>(`${process.env.REACT_APP_URL_TASKS}tasks/document/${idTask}/pending`)
}
const getDateSlaTaskService = () =>{
    return axiosInstance.get<IParameter>(`${process.env.REACT_APP_URL_PARAMETERS}parameters/company/1/sla_task`)
}
const signDocumentService = (data:ISignatureForm) =>{
    return axiosInstance.post<ITask>(`${process.env.REACT_APP_URL_CONTENT}documents/${data.uuidDocument}/signature`,data)
}
export {
    getAllTasksService,
    getMyRequestsService,
    addTaskService,
    getMyTasksService,
    getTaskDetailService,
    updateStatusTaskService,
    getTaskHistoryService,
    addTaskContentService,
    getPendingDocumentsService,
    getDateSlaTaskService,
    signDocumentService
}
