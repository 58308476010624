import { faBars, faSignOutAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useRef } from 'react'
import profileImg from '../../../assets/img/profile.png'
import { DropDownMenuItem } from './DropDownMenuItem'
import { MenuItem } from './MenuItem'
import { routesAdmin } from '../../../routers/routes';
import logo from '../../../assets/img/logo.png'
import { useLocation, Link } from 'react-router-dom';
import KeyCloakService from '../../../keycloak';
import { useSelector } from 'react-redux';
import { IAuthState } from '../../../state/reducers/authReducer';
import { RootState } from '../../../state/reducers/index';
import { checkGroupsUser } from '../../../utils/checkGroupsUser';
import { IUserToken } from '../../../interfaces/auth/IToken';
import React from 'react';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router';

//38 MIN
//https://www.youtube.com/watch?v=ES8vJcUqE7s
interface PropsSidebar {
    user: IUserToken
}
export const SideBar = React.memo(({ user }: PropsSidebar) => {
    const ref = useRef<HTMLDivElement | null>(null)
    const refNav = useRef<HTMLDivElement | null>(null)
    const { tokenInfo }: IAuthState = useSelector((state: RootState) => state.auth)

    const handleCloseSideBar = () => {
        ref.current?.classList.toggle("sidebar__close")
        refNav.current?.classList.toggle("sidebar__close")
    }
    const location = useLocation()
    const navigate = useNavigate()

    return (
        <>
            <div ref={ref} className="sidebar sidebar__close">
                <Link to="/admin/dashboard" className="sidebar__logo-details">
                    <div className="sidebar__logo-img">
                        <img src={logo} alt="Logo Apiux" />
                    </div>
                    <span className="sidebar__logo-name">
                        DOCUX
                    </span>
                </Link>
                <ul className="sidebar__nav-links">
                    {
                        routesAdmin.map((route, key) => {
                            if (checkGroupsUser(user, route.groups)) {
                                if (route.isMenuItem) {
                                    if (!route.isSubMenu) {
                                        return (
                                            <MenuItem key={key} {...route} />
                                        )
                                    } else {
                                        return (
                                            <DropDownMenuItem location={location} key={key} {...route} />
                                        )
                                    }

                                }
                            }
                            return null
                        })
                    }

                    <li>
                        <div
                            className="sidebar__profile-details"
                
                        >
                            <div className="sidebar__porfile-content "  onClick={() => navigate('/admin/administracion/perfil')}>
                                <img src={profileImg} alt="" />
                            </div>
                            <div className="sidebar__name-job"  onClick={() => navigate('/admin/administracion/perfil')}>
                                <div className="sidebar__profile-name">{`${tokenInfo?.user?.firstName} ${tokenInfo?.user?.lastName}`}</div>
                                <div className="sidebar__profile-job">{tokenInfo?.user?.email}</div>
                            </div>
                            <FontAwesomeIcon onClick={() => KeyCloakService.doLogout()} id="sidebar__icon-exit" icon={faSignOutAlt} />
                        </div>
                    </li>
                </ul>
            </div>
            <section ref={refNav} className="sidebar__home-section">
                <div className="sidebar__home-content">
                    <FontAwesomeIcon onClick={handleCloseSideBar} className="sidebar__bx-menu" icon={faBars} />
                    <span className="sidebar__text">
                        {location.key !== 'default' &&
                            <Button
                                onClick={() => navigate(-1)}
                                size='small'
                                style={{ color: "#FFF" }} > <span className='sidebar__icon-back'> {'<'}</span>VOLVER </Button>}
                    </span>
                </div>
                <div className="sidebar__home-section-logout">
                    <FontAwesomeIcon onClick={() => KeyCloakService.doLogout()} id="sidebar__icon-exit" icon={faSignOutAlt} />
                </div>
            </section>
        </>
    )

})
