import { Card } from '../../../../../components/cards/Card';

import { FormDocumentType } from './FormDocumentType';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../../../state/reducers/index';
import { TableMetadata } from './TableMetadata';
import { useEffect } from 'react';
import { startLoadingDetailDocumentType, cleanDocumentType } from '../../../../../state/actions-creators/administration';
import { useParams } from 'react-router';



export const DocumentType = () => {
    const { loadingList} = useSelector((state:RootState) => state.ui)
    const {currentDocumentType} = useSelector((state:RootState) => state.administration.typesDocuments)
    const dispatch = useDispatch()
    const  params =  useParams()
    const myId = params.id as string;
    const isDetail = Boolean(myId)
    useEffect(() => {
        dispatch(startLoadingDetailDocumentType(parseInt(myId)))
        return () => {
            dispatch(cleanDocumentType())
        }
    }, [dispatch,myId])
    return (
        <>
        <Card flexDirection="column" title={`${Boolean(currentDocumentType) && isDetail  ?'Acutalizar' : 'Crear'} Tipo de documento`} >
                {(isDetail === true && currentDocumentType !==null) ?
                <FormDocumentType  documentType={currentDocumentType} /> 
                :
                (isDetail ===false) ?
                <FormDocumentType  /> 
                :
                <span></span>

                }
            </Card>
            {isDetail && currentDocumentType !== null &&
            <div className="document-type__container-table-metadata">
                <TableMetadata documentType={currentDocumentType} loading={loadingList} />
            </div>
            }
        </>
    )
}
