import { Backdrop, Button, Fade, Modal } from '@mui/material';

import { PreviewDocument } from '../../commons/PreviewDocument';

interface PropsModalPreivewDocument {
    open:boolean;
    setModal:(modal:boolean) => void;
    children?:JSX.Element | JSX.Element[],
    file?:File;
    urlFile?:string;
}

export const ModalPreviewDocument = ({open,setModal,urlFile,file}:PropsModalPreivewDocument) => {
    // const {currentDocumentType} = useSelector((state:RootState) => state.metadata)

    const handleClose = () => {
        setModal(false);
    };

    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className="components-modal__modal-preview-document "
                open={open}
                disableEscapeKeyDown={true}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
            <Fade in={open}>
            <div className="components-modal__modal-preview-document-container ">
                <span onClick={handleClose} className="components-modal__modal-preview-document-close">X</span>
                <div className="components-modal__modal-preview-document-title">
                    <h4>Previsualización de documento</h4>
                </div>
                <div className="components-modal__modal-preview-content">

                <div className="components-modal__modal-preview-document-item">
                    {
                        urlFile
                        ? <PreviewDocument  
                            zoom='100' 
                            urlFile={urlFile} 
                            />
                        :
                        <PreviewDocument  
                            zoom='100' 
                            file={file} 
                            />
                        }
                </div>
                </div>
                <div className="components-modal__modal-preview-document-buttons" >
                <Button onClick={handleClose}   color="primary" type="button"  variant="contained">
                    Cerrar
                </Button>
                </div>
                {/* <p id="transition-modal-description">react-transition-group animates me.</p> */}        
            </div>
            </Fade>
        </Modal>
        </div>
    )
}
