import { Column } from '@material-table/core';
import { localizationMaterialTable } from '../../../constants/materialTableConfig';

import MaterialTable from '@material-table/core';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../state/reducers/index';
import { useNavigate } from 'react-router';
import { useEffect, useState, useCallback } from 'react';
import { startLoadingMyRequests } from '../../../state/actions-creators/task';
import { ITask } from '../../../interfaces/tasks/ITask';
import { formatStatusTasks } from '../../../utils/formatStatusTasks';
import { TablePagination } from '@mui/material';
import { getDayLimits } from '../../../utils/getDayLimits';
import moment from 'moment-timezone';
import { taskStatus } from '../../../constants/taskStatus';

export const MyRequestsView = () => {
    const {task:{tasks,count},ui:{loadingList}} = useSelector((state:RootState) => state)
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const renderRequestDate = useCallback(
        (task:ITask) => {
            return (
            <>
            {task?.status === taskStatus.PENDING ?
                <div className={getDayLimits(task?.required_date) > 1 ? 'task__required-date-extra-time' : getDayLimits(task?.required_date) === 1 ? 'task__required-date-low-time' : 'task__required-date-out-time'}>
                    {moment(new Date(task?.required_date)).tz("America/Santiago").format("DD/MM/YYYY")}
                </div>
                :
                <div className='task__fix-style-table'>  {moment(new Date(task?.required_date)).tz("America/Santiago").format("DD/MM/YYYY")}</div>
            }
            </>
            )
        },
        [],
    )
    const renderCreateDate = useCallback(
        (task:ITask) => {
            return (
                <span>
                    {moment(new Date(task?.create_date+"+00:00")).tz("America/Santiago").format("DD/MM/YYYY HH:mm")}
                </span>
            )
        },
        [],
    )
    const renderStatus = useCallback((task:ITask) => {
        return (
            <span>
                {formatStatusTasks(task?.status)}
            </span>
        )
    },[])
    const columns:Column<ITask>[] = [
        {
            title:"ID",
            field:"id",
            type:"numeric",
            align:"left",
            editable:"never",
        },
        {
            title:"Documento",
            field:"document_name",
            type:"string",
        },
        {
            title:"Estado",
            field:"status",
            type:"string",
            render:renderStatus
        },
        {
            title:"Creación",
            field:"create_date",
            type:"date",
            render:renderCreateDate,
        },
        {
            title:"Vencimiento",
            field:"required_date",
            type:"date",
            render:renderRequestDate,
            align:'center'
        },
        {
            title:"De usuario",
            field:"user_name_from",
            type:"string",
        },
        {
            title:"Para usuario",
            field:"user_name_to",
            type:"string",
        }
    ]
    const handleChangePage = (event:any,newPage:number) => {
        dispatch(startLoadingMyRequests(1+ newPage))   
        setPage(newPage)
    }
    const handleChangeRowsPerPage = (event:any | null) => {
        setRowsPerPage(parseInt(event.target.value));
    };
    useEffect(() => {
        dispatch(startLoadingMyRequests(1))
        return () => {
            
        }
    }, [dispatch])
    
    return (
        <MaterialTable 
            title="Mis solicitudes"
            columns={columns}
            data={tasks}
            style={{minWidth:"100%"}}
            localization={localizationMaterialTable}
            actions={[
                {
                    icon: 'refresh',
                    tooltip: `Refrescar`,
                    isFreeAction: true,
                    onClick:()=> {
                        dispatch(startLoadingMyRequests(1))
                    }
                }
            ]}
            onRowClick={(_,rowData) => { 
                if(rowData){
                    const myId = `${rowData.namespace}:${rowData.primary_key}:${rowData.id}`
                    navigate(`/admin/mis-solicitudes/${myId}`)
                
                }
            }}
            components={{
                Pagination: props => (
                            <TablePagination
                                {...props}
                                rowsPerPageOptions={[10]}
                                count={count}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
    
                            />
                ),
            }}
            isLoading={loadingList}
            options={{
                pageSizeOptions:[10],
                search:false,
                loadingType:'overlay',
                pageSize:10,
                actionsColumnIndex:-1,
                filtering: false
            }}

    />
    )
}
