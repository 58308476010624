import MaterialTable, { Column } from '@material-table/core'
import { localizationMaterialTable } from '../../../../constants/materialTableConfig'
import { useNavigate } from 'react-router';
import { IDocument } from '../../../../interfaces/documents/IDocument';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../../state/reducers/index';
import { useEffect, useState } from 'react';
import { startLoadingAllDocuments, cleanDocuments } from '../../../../state/actions-creators/document';
import moment from 'moment-timezone';
import { TablePagination } from '@mui/material';
import { startSearchMetadata } from '../../../../state/actions-creators/search';
import { ModalPreviewMetadata } from '../../../../components/modals/modal-preview-metadata/ModalPreviewMetadata';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';

export const TableDocuments = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const {
        document:{documents,count,search:{searchUrl}},
        ui:{loadingList}
    } = useSelector((state:RootState) => state)
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [attrs, setAttrs] = useState({
        uuid:'',
        urlFile:''
    })
    const [modal, setModal] = useState(false)
    const columns:Column<IDocument>[]  = [
        {
            title:"ID",
            field:"id",
            type:"numeric",
            align:"left",
            editable:"never"
        },
        {
            title:"Nombre",
            field:"name",
        },
        {
            title:"Tipo",
            field:"type",
        },
        {
            title:"Creación",
            field:"create_date",
        },
        {
            title:"Última actualización",
            field:"write_date",
        }
    ]
    useEffect(() => {
        dispatch(startLoadingAllDocuments(1))
        return () => {
            dispatch(cleanDocuments())
        }
    }, [dispatch])
    const handleChangePage = (event:any,newPage:number) => {

        if(searchUrl){
            // dispatch(startSearchMetadata(newPage+1,searchUrl))
            // connsole.log("POR AQUI PASO")
            dispatch(startSearchMetadata(newPage+1,searchUrl))
            // dispatch(startSearchMetadata(newPage+1,Object.fromEntries([...searchParams])))
        }else{
            dispatch(startLoadingAllDocuments(newPage+1))
        }
        setPage(newPage)
    }
    const handleChangeRowsPerPage = (event:any | null) => {
        setRowsPerPage(parseInt(event.target.value));

    };
    return (
    <>
        <MaterialTable 
        title={searchUrl === null? "Mis documentos":"Mi busqueda"}
        columns={columns}
        style={{minWidth:"100%"}}
        data={documents.map((document:IDocument) => {
            document.write_date = moment(new Date(document.create_date+"+00:00")).tz("America/Santiago").format("DD/MM/YYYY HH:mm")
            document.create_date = moment(new Date(document.create_date+"+00:00")).tz("America/Santiago").format("DD/MM/YYYY HH:mm")
            return document
        })}
    
        localization={localizationMaterialTable}
        onRowClick={(e,rowData) => { 
            navigate(`/admin/mis-documentos/detalle-documento/${rowData?.uuid}`)
        }}
        isLoading={loadingList}
        actions={[
            {
                icon: 'refresh',
                tooltip: `Refrescar`,
                isFreeAction: true,
                onClick:()=> {
                    dispatch(startLoadingAllDocuments(1))
                }
            },
            rowData =>({
                tooltip:'Previsualizar',
                icon:() =>(  <FontAwesomeIcon  size="sm" icon={faEye} />),
                onClick:() => {
                    setAttrs({
                        ...attrs,
                        urlFile:`${process.env.REACT_APP_URL_CONTENT}documents/${rowData.uuid}/download`,
                        uuid:rowData.uuid
                    })
                    setModal(true)
                }
            })
        ]}
        components={{
            Pagination: props => (
                        <TablePagination
                            {...props}
                            rowsPerPageOptions={[10]}
                            count={count}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}

                        />
            ),
            Container: props => <div {...props} className="my-documents__table-styles-container" />
        }}
        options={{
            search:false,
            loadingType:'overlay',
            pageSize:10,
            actionsColumnIndex:-1,
            filtering: false
        }}
    
    />
    { modal &&
            <ModalPreviewMetadata  
                open={modal}
                setModal={setModal}
                urlFile={attrs.urlFile}
                uuidDocument={attrs.uuid}
            />
        }
    </>
    )
}
