
import {  DragAndDropState } from '../../../../hooks/useDragAndDrop';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../state/reducers/index';
import {  useEffect, useState } from 'react';
import { FileCharged } from '../../../../components/files/FileCharged';
import { DragAndDropZone } from '../../../../components/files/DragAndDropZone';
import { useFormikContext} from 'formik';
import { ModalPreviewDocument } from '../../../../components/modals/modal-preview-document/ModalPreviewDocument';
import { MyFolderField } from '../../../../components/fields/custom-fields/folder-field/MyFolderField';
import { MySelectField } from '../../../../components/fields/MySelectField';
import { MenuItem } from '@mui/material';
import { cleanDocumentType, startLoadingAllDocumentTypes } from '../../../../state/actions-creators';
import { FormUnitaryLoadAuto } from '../UploadDocumentView';
import { loadMetadataFields, cleanCurrentDocument } from '../../../../state/actions-creators/document';
import { IMetadataField } from '../../../../interfaces/metadata/IMetadataField';


interface PropsUploadDocument{
    dropZoneStateProp:DragAndDropState,

}
export const UploadDocument = ({dropZoneStateProp}:PropsUploadDocument) => {
  
    const { ui:{loading} } =  useSelector((state:RootState) => state)
    const dispatch = useDispatch()
    const {administration:{typesDocuments:{list}}} = useSelector((state:RootState) => state)
    useEffect(() => {
        dispatch(startLoadingAllDocumentTypes())
        return () =>{
            dispatch(cleanDocumentType())
        }
    }, [dispatch])

    const {removeFile,myFiles} = dropZoneStateProp
    const [modal, setModal] = useState<boolean>(false)

    // const resetFile = () => {
    //     resetFiles()
    //     tempShowMetaData(false)
    // }

    const {values,setFieldValue} =useFormikContext<FormUnitaryLoadAuto>()

    return (

        <div className="upload-documents__container-dropzone">

            <div className="upload-documents__container-input">
                <div className="upload-documents__item-input">
                    <MyFolderField 
                        inputProps={
                            { readOnly: true, }
                        }
                        label='Carpeta'
                        name='parent_path'
                        setFieldValue={setFieldValue}
                    />  
                </div>
            </div>
                { values.parent_path !== "" 
                    &&
                        <>
                        <DragAndDropZone 
                            dropZoneState={dropZoneStateProp} 
                            loading={loading} />
                        
                
                        </>
                
                }
            {/* <h1>Subir archivo</h1> */}
            {!!myFiles.length && 
            <>
            <div className="upload-documents__container-title">
                    <h4>Documento cargado</h4>
                    <span>
            
                        <hr className="base__hr-right-ease"></hr>
                    </span>
            </div>
            <div className="upload-document__container-charged-document">
                <div className="upload-document__container-item-charged-document">

                    { 
                    myFiles.map((file,index:number) => (
                        <FileCharged setModal={setModal} loading={loading} key={index} file={file} removeFile={removeFile} />                 
                        ))
                    }
                </div>
        
            </div>

            <div className="upload-documents__container-document-type">
                <MySelectField
                    label="Tipo de documento"
                    name="type_file"
                    handleOnChange={(e)=>{

                        if(list.length !==0 && e.target.value !== '' && e.target.value !== "AUTO"){
                            
                            const documentType = list.find(item => item.name === e.target.value)

                            dispatch(loadMetadataFields(documentType?.metadata as IMetadataField[]))
                            setFieldValue('manual',true)
                        }else{
                            setFieldValue('manual',false)
                            dispatch(cleanCurrentDocument())
                        }
                    }}
                    >
                    
                    <MenuItem value="">Selccionar</MenuItem>
                    <MenuItem value={'AUTO'}>Automático</MenuItem>
                    {
                        list.map((type) => (
                            <MenuItem key={type.document_type_id} value={type.name}>{type.display_name}</MenuItem>
                        ))
                    }
                </MySelectField>
            </div>
            </>
            }
            {modal && 
            <ModalPreviewDocument
                file={myFiles[0]}
                setModal={setModal}
                open={modal}
            />
            } 

        </div>


    )
}
