import React from 'react'
interface PropsCard{
    children:JSX.Element | JSX.Element[],
    flexDirection?:"row" |"row-reverse" | "column" | "column-reverse",
    flexWrap?:"wrap" | "nowrap" 
    className?:string | undefined,
    styles?:React.CSSProperties,
    title?:string;
}

export const Card = ({children,title,flexDirection='row',flexWrap="wrap",className,styles}:PropsCard) => {
    return (
        <div 
            style={{
                ...styles,
                flexWrap:flexWrap,
                flexDirection:flexDirection,
            }}
            className={`components-card__card-container ${className}`}
            >  
            { !!title && 
            <div className="components-card__card-title">
                <h4>{title}</h4>
                <hr className="base__hr-right-ease" />
            </div>
            }
            {
            children
            }
        </div>
    )
}
