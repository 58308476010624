import {  useField } from 'formik'
import {  FormControlLabel, Checkbox, FormControl, FormHelperText } from '@mui/material';
interface Props  {
    label: string;
    name: string;
    type?: 'text' | 'email' | 'password';
    placeholder?: string;
    labelPlacement?: 'top' | 'bottom' | 'start' | 'end';
    [x: string]: any;

}
export const MyCheckBoxField = ({ label,labelPlacement, ...props }: Props ) => {
    const [ field,metaProps] = useField(props)
    const { error,touched} = metaProps;
    return (
        <FormControl>

            <FormControlLabel  labelPlacement={labelPlacement} label={label} control={
                <Checkbox 
                    {...field}
                    checked={field.value}
                    {...props}
                    color="primary" 
                    />}     />
                    {Boolean(error) && touched && <FormHelperText error={Boolean(error) && touched} >{error}</FormHelperText>}
        </FormControl>
    )
}
