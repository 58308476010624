
import { BrowserRouter, Routes } from 'react-router-dom';
import { AdminLayout } from '../layouts/admin/AdminLayout';
import { LoginLayout } from '../layouts/login/LoginLayout'
import { PrivateRoute } from './PrivateRoute';
import { PublicRoute } from './PublicRoute';
import { Route, Navigate } from 'react-router';
import KeyCloakService from '../keycloak';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { loadTokenUser } from '../state/actions-creators/auth';

export const AppRouter = () => {
    const access = KeyCloakService.isLoggedIn()
    const accessToken = KeyCloakService.getToken()


    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(loadTokenUser(accessToken? accessToken : ''))
    }, [dispatch,accessToken])
    
    return (
        <BrowserRouter>
        
                <Routes>
                    <Route
                        path="/login"
                        element={
                            <PublicRoute isAuthenticated={access}>
                                <LoginLayout />
                            </PublicRoute>
                        } 
                    />
                    <Route 
                        path="/admin/*"
                        element={
                            <PrivateRoute isAuthenticated={access}>
                                <AdminLayout />
                            </PrivateRoute>
                        }
                    />
                    <Route path="*" element={<Navigate to={"/admin/dashboard"} replace />} />
                </Routes>
        </BrowserRouter>
    )
}
